.super-navigation {
    background: #ebebeb;
    font: 16px/1.1 Matterhorn,Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    letter-spacing: 0;
    color: #000;
    opacity: 1;
    scroll-behavior: smooth;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);

    a {
      color: #000;
      font-weight: bold;
      text-decoration: underline;

    }

    button {
      position: relative;
      z-index: 1;
      left: -38px;
      top: 1px;
      background-color: $white;
      cursor: pointer;
      width: 18px;
      border: none;
    }

    .form-control{
    /* color: #fff; */
    /* font-size: 1.1em; */
    /* cursor: pointer; */
    /* margin: 1em; */
    background: #fff 0 0 no-repeat padding-box;
    border: 2px solid #e12490;
    border-radius: 23px;
    opacity: 1;


&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    text-align: left;
font: Regular 18px/25px Avenir Next;
letter-spacing: 0;
color: #606060;
opacity: 1;

  }
  &::-moz-placeholder { /* Firefox 19+ */
    text-align: left;
font: Regular 18px/25px Avenir Next;
letter-spacing: 0;
color: #606060;
opacity: 1;

  }
  &:-ms-input-placeholder { /* IE 10+ */
    text-align: left;
font: Regular 18px/25px Avenir Next;
letter-spacing: 0;
color: #606060;
opacity: 1;

  }
  &:-moz-placeholder { /* Firefox 18- */
    text-align: left;
font: Regular 18px/25px Avenir Next;
letter-spacing: 0;
color: #606060;
opacity: 1;

  }

    } 

    &--hidden{
      opacity: 0;
      display: none;
    }

}

#filtersubmit {
    /* height: 27px; */
    /* transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0); */
    position: relative;
    z-index: 1;
    left: -8px;
    top: -1px;
    color: #442e8b;
    cursor: pointer;
    width: 0;
    background: transparent;
    padding: inherit;
  }



/*
---------------------
Navigation
---------------------
*/

.navbar, .navbar>.container, .navbar>.container-fluid {
   display: flexbox;
}

.dropdown-menu .nav-title-border {
    font-weight: 700;
    color: $black;
    font-size: 1em;
    border-bottom: 1px solid $grey;
    margin: 4px 15px 4px;
    padding-bottom: 5px;
}


.dropdown-menu .nav-title {
  font-weight: 700;
  color: $black;
  font-size: 1em;
  margin: 4px 15px 4px;
  padding-bottom: 5px;
}



.dropdown-menu .nav-title-secondary {
  font-weight: 600;
  color: $color-primary-dark;
  font-size: 1em;
  margin: 4px 15px;
  padding-bottom: 5px;
}
.nav-bottom-border::before {
  content: "";
  position: absolute;
  width: 50vw;
  height: 1px;
  left: 0;
  display: block;
  clear: both;
  background-color: $primary-color;
  opacity: .2;
  margin: -6px 27px;
}

.nav-bottom-border {
  position: relative;
}



.navbar {
  z-index: 10 !important;
  list-style: none;


 .ml-auto {
   width: inherit;
 }

}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: stretch;
}

.navbar-light .navbar-nav .nav-link {
    color: #ed3c96;
    font-weight: normal;
    font-size: 1.06rem;

    @include md {
      font-size: 1.2rem;
    }
}


/*
#rto-nav button.navbar-toggler{
  border-color: $primary-color;
}

*/


#rto-nav .navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}


.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: $primary-color;
}

.navbar-brand {
  @include lg {
    transition: all 0.6s ease-in-out; //smoothing element resizing when site header is fixed
    animation: zoomIn 2s ease alternate;
  }


   .logo {
    width: 75px;
    @include lg {
    width: auto; 
    margin-right: 2em;   
    }
   } 

}



.navbar-dark .navbar-nav .nav-link {
    color: hsl(254, 50%, 36%);
    font-weight: 800;
}


.btn-navigation {
    display: inline-block;
    font-weight: 800;
    text-align: center;
    /* vertical-align: middle!important; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1em;
    /* line-height: 1.5; */
    border-radius: 22px 22px 22px 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #44318b;
    border-color: #44318b;
    height: 36px;
    width: 89px;
    /* vertical-align: bottom; */
    position: relative;


    &:hover {
      color: rgb(255, 255, 255);
      background-color: darken( $primary-color, 10% );
      border-color: rgb(77, 56, 158);
      text-decoration: none;
      transform: translateY(-3px);
     box-shadow: 0 1rem 2rem rgba(0,0,0,.2);
  }

}


.fixed-top-supernav {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 20;
  background: #ebebeb !important;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.fixed-bottom, .fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 20;
  background: #ffffff;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);

  @include md {
    background: #ebebeb;
  }

}

.page-header {
  transition: background 0.5s ease-in-out;
}

/*
old code before hover

.megamenu-li {
  position: static;
}

*/


/*
code from 108 to change to hover state rather than click nav
*/
.megamenu-li{position:initial}
.megamenu-li.dropdown > a{position:relative;z-index:2}
.megamenu-li.dropdown:hover .dropdown-menu-wrapper{padding-top:116px;top:0;z-index:1;background:none;position:absolute;width:100%;left:0;right:0}
.megamenu-li.dropdown:hover .dropdown-menu-wrapper,.megamenu-li.dropdown:hover .dropdown-menu{display:block;}
.dropdown-menu .container{background:#fff;padding-top:30px}

.megamenu {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  padding: 15px;
}

.mega-menu{
  position: initial;
&:hover{
  .dropdown-menu{
    display:block;
  }
}

  .dropdown-menu{
    width: 100%;
    margin-top:-15px;
    table{
      td{
        border-top: 0;
      }
    }
  }

}


@media only screen and (min-width : 768px) {
  /*Make Navigation Toggle on Desktop Hover*/
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky */
    pointer-events: none;
  }
}




.dropdown-item {
  @include dropdown-link($black, $navlink-underlined, $dropdown-link-font-weight);
    &:focus, &:hover {
      @include dropdown-link-hover($secondary-color, $black);
  }
  
    &:last-child {
      margin-bottom: 1em;
    }
  
    img {
      vertical-align: text-bottom;
      margin-right: 5px;
  }
  
  
  &__pink {
    @include dropdown-link($secondary-color, $navlink-underlined, 800);
      &:focus, &:hover {
        @include dropdown-link-hover($secondary-color, $secondary-color);
        text-decoration: none;
    }
    
    }
  
  
  }



#rto-nav .form-control {
  background: #fff 0 0 no-repeat padding-box;
  border: 2px solid $grey;
  border-radius: 23px;
  width: 261px;
  opacity: 1;
  padding: .375rem .75rem;
  margin-left: 10px;
}

.rpw-subline {
  margin-left: 16px;
  padding: 5px 0;
  color: grey;
}


.upcoming-events-nav-block {

  margin-bottom: 25px;

  &__post-title {
      color: $black;
      font-size: 14px;
      text-align: left;
      font-weight: 600;
      margin-left: 17px;
      margin-top: 16px;
  }

  &__post-meta-title {
      color: $color-primary-dark;
      font-size: 13px;
      text-align: left;
      margin: 7px 0 10px 15px;
      font-weight: 700;
  }
  

}



/* Smaller Nav on Scroll Styling
================================================== */

.smaller-nav {
	opacity: 1;
	transform: translateY(0);
	padding: 10px;
	box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
	//-webkit-transition : all 0.3s ease-out;
  //transition : all 0.3s ease-out;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  
  @include md {
    padding: 10px 0;

}

}
.smaller-nav.scroll-on {
	box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
	//-webkit-transition : all 0.3s ease-out;
  //transition : all 0.3s ease-out;
  //padding: 11px;
  //@include md {
    //padding: 5px 0;
 // }
 top: 78px;

 @include sm {
  top: 82px;
}

 @include md {
  top: 45px;
}

}
.smaller-nav.scroll-on .navbar-brand img{
	height: 28px;
  margin-right: 7em;
	//-webkit-transition : all 0.3s ease-out;
	//transition : all 0.3s ease-out;
}



/* REVAMPED CSS FOR CUSTOM NAV */
.smaller-nav {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
     padding: 10px;
    box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
    transform: none;
    -webkit-transform: none;
    //margin: 45px 0;
}
.navbar-toggler.custom-button {
    border-color: transparent!important;
}
.navbar-toggler:focus, .navbar-toggler:active {
    outline: none;
    box-shadow: none;
    border:none;
  }
.navbar-toggler, .navbar-toggler{
    outline: none;
    box-shadow: none;
   border:none;
  }

.animated-icon {
width: 34px;
height: 20px;
position: relative;
margin: 0px;
-webkit-transform: rotate(0deg);
-moz-transform: rotate(0deg);
-o-transform: rotate(0deg);
transform: rotate(0deg);
-webkit-transition: .5s ease-in-out;
-moz-transition: .5s ease-in-out;
-o-transition: .5s ease-in-out;
transition: .5s ease-in-out;
cursor: pointer;
}

.bg-circle{
    background:rgba(68, 49, 139, 1);
       padding: 16px 16px;
    border-radius: 50%;
}

.animated-icon span {
display: block;
position: absolute;
height: 3px;
width: 100%;
border-radius: 9px;
opacity: 1;
left: 10px;
-webkit-transform: rotate(0deg);
-moz-transform: rotate(0deg);
-o-transform: rotate(0deg);
transform: rotate(0deg);
-webkit-transition: .25s ease-in-out;
-moz-transition: .25s ease-in-out;
-o-transition: .25s ease-in-out;
transition: .25s ease-in-out;
}

.animated-icon span {
background: rgba(68, 49, 139, 1);
}

.animated-icon.open span {
background: rgba(255, 255, 255, 1);
}

.animated-icon span:nth-child(1) {
top: 0px;
-webkit-transform-origin: left center;
-moz-transform-origin: left center;
-o-transform-origin: left center;
transform-origin: left center;
}

.animated-icon span:nth-child(2) {
top: 10px;
-webkit-transform-origin: left center;
-moz-transform-origin: left center;
-o-transform-origin: left center;
transform-origin: left center;
}

.animated-icon span:nth-child(3) {
top: 20px;
-webkit-transform-origin: left center;
-moz-transform-origin: left center;
-o-transform-origin: left center;
transform-origin: left center;
}

.animated-icon.open span:nth-child(1) {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
   top: 5px;
    width: 21px;
    //left: 19px;
}

.animated-icon.open span:nth-child(2) {
width: 0%;
opacity: 0;
}

.animated-icon.open span:nth-child(3) {
-webkit-transform: rotate(-45deg);
-moz-transform: rotate(-45deg);
-o-transform: rotate(-45deg);
transform: rotate(-45deg);
    /* top: 2px; */
    /* width: 25px; */
    width: 20px;
    //left: 19px;
}

@media (max-width: 992px) {
  .mobileMenu {
    /*transform: translateX(-100%); */
    position: fixed;
    top: 129px;
    bottom: 0;
    margin: auto;
    min-width: 90%;
    left: 0;
    transition: all .25s ease;
    z-index: 15000;
     &.onRight {
     left: auto;
     right: 0;
     transform: translateX(100%);   
     } 
    &.open {
      transform: translateX(0%);
    }
    .navbar-nav {
      overflow-y: auto;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, .2);
    display: none;
    &.open {
      display: block;
      z-index: 0;
    }
  }
}


.nav-item .dropdown .megamenu-li {
   z-index: 1000;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0,0,0,.9);
    /* float: none; */
    display: flex !important;
    justify-content: flex-end!important;
}

@media screen and (max-width: 992px) {
   
.navbar-light .navbar-nav .nav-link {
font-size: 1.5rem;
   
}

   
.megamenu-li {
     border-bottom: solid 1px #607D8B;
      padding-bottom: 1px;
}   
   
   
.dropdown-toggle::after {
       display: flex;
    margin-left: .255em;
    vertical-align: .255em;
    content: 'v';
    font-weight: 1em;
    align-self: self-start;
    justify-items: self-end;
    justify-self: flex-end;
    justify-content: flex-end;
    align-content: space-evenly;
    margin: 0px 15px;
       /* display: inline-block; */
    margin-left: 0;
    vertical-align: unset;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
       margin-top: -35px;
   
   
}
   
   
   
   
   
   
}



