.district-events-block {
    margin: 3em 0;
    position: relative;
    bottom: 0;
    right: 0;

    &__main-heading {
        @include headline ($black, $heading-size);    
    }

        h3 {
            color: $secondary-color;
            margin-top: 15px;
            font-weight: bold;  
        }

        
        .card .card-category {
         //margin: 0 0 10px;
        }

        .card .card-body {
            padding: 10px 0px;
        }
          
        .card .card-body .card-title {
            font-size: 1em;
            margin-bottom: 7px;
            color: $black;
            font-weight: 800;
            max-width: 75%;
        }
        
        .card .card-body .card-meta {
            font-size: .75rem;
            margin-bottom: 5px;
            color: $color-red;
            font-weight: 600;
        }

        .card .card-text {
            margin-top: 2em;
            line-height: 1.5;
            font-weight: 600;
        }
        


    }




    