.healthcare-coverage-block {

    margin: 3em 0 2em;
  
    ul >li {
    list-style-type: disc;
    margin-left: 29px;
    line-height: 1.8;
    margin-bottom: 5px;
    }

    &__title {
        font-size: 2em;
        margin-bottom: .5em;
        text-align: center;
  
        @include sm {
         text-align: left;
         } 
       }

       &__content-title {
        color: $color-primary-dark;   
        font-size: 1.5em;
        margin-bottom: 1em;
        text-align: center;
  
        @include sm {
         text-align: left;
         } 
       }

}

.accordion-tabs {
    margin: 20px;
    // Hide radio buttons
    border: solid 1px #eee;
    border-radius: 6px;
    box-shadow: 3px 4px 5px #eee; 
     
     img {
        max-width: 200px;
     }
     
     h4 {
        font-weight: 400;
        font-size: 14px;
        word-break: break-word;
        //max-width: 0;

        @include sm {
        max-width: 200px;
            } 
        
    }
     
    .activate-tab {
      position: absolute;
      left: -10000px;
    }
    // Tab container
    .tabs {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // Tabs styling
      .tab {
    display: inline-block;
    padding: 35px 10px 28px;
    vertical-align: top;
    background-color: #ffffff;
    cursor: hand;
    cursor: pointer;
    border-left: 1px solid #eee;
    flex-grow: 1;
    margin-bottom: 0;
    transition: 0.3s;
    border-bottom: solid 4px $color-primary-dark;
        &:hover {
          background-color: #eee;
        }

        @include sm {
       border-bottom: none;
          } 
         
      }
      // Tab content styling
      .tab-content {
        background-color: #eee;
        padding: 20px 20px 20px 10px;
        min-height: 300px;
        display: none;
        width: 100%;
        flex-basis: auto; 
      }
    }
  }
  
  // Tab active state styling
  #tab-one:checked ~ .tabs #tab-one-label,
  #tab-two:checked ~ .tabs #tab-two-label,
  #tab-three:checked ~ .tabs #tab-three-label,
  #tab-four:checked ~ .tabs #tab-four-label,
  #tab-five:checked ~ .tabs #tab-five-label,
  #tab-six:checked ~ .tabs #tab-six-label,
  #tab-seven:checked ~ .tabs #tab-seven-label,
  #tab-eight:checked ~ .tabs #tab-eight-label,
  #tab-nine:checked ~ .tabs #tab-nine-label,
  #tab-ten:checked ~ .tabs #tab-ten-label{
    background-color: #eee;
    cursor: default;
    border-left: none;
    transition: .3s ease-in;
     border-top: 3px solid #696363;
  }
  
  // Tab content active state styling
  #tab-one:checked ~ .tabs #tab-one-tab-content,
  #tab-two:checked ~ .tabs #tab-two-tab-content,
  #tab-three:checked ~ .tabs #tab-three-tab-content,
 #tab-four:checked ~ .tabs #tab-four-tab-content,
 #tab-five:checked ~ .tabs #tab-five-tab-content,
 #tab-six:checked ~ .tabs #tab-six-tab-content,
 #tab-seven:checked ~ .tabs #tab-seven-tab-content,
 #tab-eight:checked ~ .tabs #tab-eight-tab-content,
#tab-nine:checked ~ .tabs #tab-nine-tab-content,
#tab-ten:checked ~ .tabs #tab-ten-tab-content{
    display: block;
    padding: 25px 115px;

    h4 {
        font-weight: bolder;
        font-size: 1em;  
    }
   
  }
  
  // Accordion functionality on screen <768px
  @media (max-width: 767px) {
     
     
       .accordion-tabs {
     
     img {
        max-width: 50px;
     }
     
     }

    
      
    .tabs {
      // Switch from tabs to accordion
      flex-direction: column;
      // Define item order on smaller screen (I would do this in JS instead to remove manual work)
      
      .tab {
        padding: 10px;
     }
      
      #tab-one {
        &-label {
          order: 1;
        }
        &-tab-content {
          order: 2;
        }
      }
      #tab-two {
        &-label {
          order: 3;
        }
        &-tab-content {
          order: 4;
        }
      }
      #tab-three {
        &-label {
          order: 5;
        }
        &-tab-content {
          order: 6;
        }
      }
            #tab-four {
        &-label {
          order: 7;
        }
        &-tab-content {
          order: 8;
        }
      }
       #tab-four {
        &-label {
          order: 9;
        }
        &-tab-content {
          order: 10;
        }
      }
      #tab-five {
        &-label {
          order: 11;
        }
        &-tab-content {
          order: 12;
        }
      }
      #tab-six {
        &-label {
          order: 1;
        }
        &-tab-content {
          order: 2;
        }
      }
      #tab-seven {
        &-label {
          order: 3;
        }
        &-tab-content {
          order: 4;
        }
      }
      #tab-eight {
        &-label {
          order: 5;
        }
        &-tab-content {
          order: 6;
        }
      }
      #tab-nine {
        &-label {
          order: 7;
        }
        &-tab-content {
          order: 8;
        }
      }
      #tab-ten {
        &-label {
          order: 9;
        }
        &-tab-content {
          order: 10;
        }
      }
    }
    // Accordion tab active state styling
    #tab-one:checked ~ .tabs #tab-one-label,
    #tab-two:checked ~ .tabs #tab-two-label,
    #tab-three:checked ~ .tabs #tab-three-label,
    #tab-four:checked ~ .tabs #tab-four-label,
    #tab-five:checked ~ .tabs #tab-five-label{
      background-color: #f8f8f8;
      border-bottom: 1px solid #ccc;
    }
     
       // Tab content active state styling
  #tab-one:checked ~ .tabs #tab-one-tab-content,
  #tab-two:checked ~ .tabs #tab-two-tab-content,
  #tab-three:checked ~ .tabs #tab-three-tab-content,
 #tab-four:checked ~ .tabs #tab-four-tab-content,
 #tab-five:checked ~ .tabs #tab-five-tab-content{
    display: block;
    padding: 25px 15px;
   
  }
     
     
  }




  .accordion-item-body-coverage-content {
    padding: 2rem 4em 2em;
    line-height: 1.5rem;
    min-height: 365px;
}