.error-page{
    margin: 6em 0 4em;
    min-height: 75vh;

    &__error-text{
    color:$primary-color;
    font-size: 2rem;
    margin-bottom: 1rem;
    }

    &__error-copy-text{
        color: $black;
        font-size: 1.5em;
        max-width: 75%;
    }

    &__display-text{
        color: $black;
        font-size: 4.5rem;
        font-weight: 600;
        line-height: 1.2;
    }

    &__help-header-text{
        color:$primary-color;
        font-size: 2rem;
        margin-bottom: 1rem;
        font-weight: 600;
    }

    &__help-copy-text{
        color: $black;
        font-size: 1.25em;
        font-weight: 600;
        max-width: 75%;
    }

    &__help-links {
        list-style-type: none;
        margin: 1em 0;
        text-align: left;
        text-decoration: underline;
        font-weight: 600;
        color: #e93a97;
    }


    &__help-link-title {
        font-size: .75em;
        text-align: left;
        letter-spacing: .2px;
        margin: 8px 0;
        font-weight: 800;
        margin: 1em 0 2em;
    
        @include sm {
            font-size: 1em;
        } 
    }

    .form-control {
        background: #fff 0 0 no-repeat padding-box;
        border: 1px solid #442e8b;
        opacity: 1;
        width: auto;
        border-radius: 25px;

        @include lg {
        width: 335px;
        } 


    }



}