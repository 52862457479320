
 a.resources-box-fill-link {
   display: block;
   height: auto;
   width: auto;
   text-decoration: none;
   cursor: pointer;
   color: $color-primary-dark;
   font-weight: 800;
   font-size: 1.25em;

   &:hover {
       text-decoration: none; 
       }

 }

.find-resources-block { 
    &__main-heading {
     color: $black;
     font-size: 2em;
     font-weight: 600;
     margin: 0em 0 1.25em;
     text-align: center;
 
     @include sm {
    text-align: left;
 } 

 }


 &__box {
     background: $color-mint;
     border-radius: 18px;
     /* margin-bottom: 25px; */
     padding: 20px 64px;
     height: 12em;
     overflow: hidden;
     text-align: center;
     max-width: 22em;
     /* margin: 0 auto; */


     &:hover {
     background: darken($color-mint, 20%);
     transform: scale(1.1);  
     }

     &:hover > h5  {
      color: $white;
      font-weight: 800;
      font-size: 1.25em;
      text-decoration: none;     
      }

    } 

     &__box--heading {
    margin-top: 10px;
    margin-bottom: 28px;
    max-width: 10em;

     }


     &_box--heading a {
      color: $color-primary-dark;
      font-weight: 800;
      font-size: 1.25em;

   } 


}