.history-content-block {

     hr {
         content: "";
         background: $color-primary-light;
         opacity: 0.5;
     }

     
     .main-timeline {
        position: relative;
     }
     
     .main-timeline:before {
         content: "";
         width: 3px;
         height: 100%;
         border-radius: 20px;
         margin: 0 auto;
         background: #c1c5bf;
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
     }
     
     .main-timeline .timeline {
        display: inline-block;
        margin-bottom: 50px;
        position: relative;
     }
     
     .main-timeline .timeline:before {
        content: "";
        width: 26px;
        height: 26px;
        border-radius: 0 15px 15px 15px;
        border: 4px solid #fff;
        background: $color-primary-dark;
        position: absolute;
        top: 14%;
        left: 50%;
        z-index: 1;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
     }
     
     
     .main-timeline  .timeline:nth-child(2n):before {
        border-radius: 0px 15px 15px 15px;
        background: $color-primary-light;
     
     }
     
     
     .main-timeline  .timeline:nth-child(3n):before {
        border-radius: 0px 15px 15px 15px;
        background: $color-burgundy;
     
     }
     
     
     .main-timeline  .timeline:nth-child(4n):before {
        border-radius: 0px 15px 15px 15px;
        background: $color-red;
     
     }

   /*  

     .main-timeline  .timeline:nth-child(5):before {
      border-radius: 0px 15px 15px 15px;
      background:  $color-primary-dark;
   
   }
     
   .main-timeline  .timeline:nth-child(6):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-primary-light;
   
   }
   
   
   .main-timeline  .timeline:nth-child(7):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-burgundy;
   
   }
   
   
   .main-timeline  .timeline:nth-child(8):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-red;
   
   }


   .main-timeline  .timeline:nth-child(9):before {
      border-radius: 0px 15px 15px 15px;
      background:  $color-primary-dark;
   
   }
     
   .main-timeline  .timeline:nth-child(10):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-primary-light;
   
   }
   
   
   .main-timeline  .timeline:nth-child(11):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-burgundy;
   
   }
   
   
   .main-timeline  .timeline:nth-child(12):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-red;
   
   }
     

   */
     
     
     .main-timeline .timeline-icon {
        display: inline-block;
        width: 135px;
        height: 66px;
        border-radius: 50%;
        border: solid 1px #110f90;
        padding: 13px;
        text-align: center;
        position: absolute;
         top: 8%;
         left: 36%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        /* padding: .45em .75em; */
        line-height: 1.1;
        border-radius: 44px 44px 0px 44px;
        display: inline-block;
        font-size: 1em;
        font-weight: 600;
        color: #fff;
        background: $color-primary-dark;


        @include sm {
            font-size: 1.1em;
            } 
    


     }
     
     .main-timeline .timeline-icon i {
        padding: 0.45em 0.75em;
        line-height: 1.5;
        border-radius: 22px 22px 22px 0;
        display: inline-block;
        font-size: 1em;
        font-weight: 600;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 12%;
        transform: translateY(-50%);
     }
     
     .main-timeline .timeline-icon:after,
     .main-timeline .timeline-icon:before {
        //content: "";
        width: 100px;
        height: 4px;
        background: #ec496e;
        position: absolute;
        top: 50%;
        right: -100px;
        transform: translateY(-50%);
     }
     
     .main-timeline .timeline-icon:after {
        width: 70px;
        height: 50px;
        background: #fff;
        top: 89px;
        right: -30px;
     }
     
     .main-timeline .timeline-content {
        width: 50%;
        padding: 0 50px;
        margin: 52px 0 0;
        float: right;
        position: relative;
     }
     
     .main-timeline .timeline-content:before {
        //content: "";
        width: 70%;
        height: 100%;
        border: 3px solid #ec496e;
        border-top: none;
        border-right: none;
        position: absolute;
        bottom: -13px;
        left: 35px;
     }
     
     .main-timeline .timeline-content:after {
        //content: "";
        width: 37px;
        height: 3px;
        background: #ec496e;
        position: absolute;
        top: 13px;
        left: 0;
     }
     
     .main-timeline .title {
        font-size: 20px;
        font-weight: 600;
        color: #ec496e;
        text-transform: uppercase;
        margin: 0 0 5px;
     }
     
     .main-timeline .description {
        display: inline-block;
        font-size: 16px;
        color: #404040;
        margin: 0;
        line-height: inherit;
        max-width: 100%;

        @include sm {
         max-width: 70%;
      } 

     }

     .desctiption:after {
      content: "";
      width: 37px;
      height: 3px;
      background: #ec496e;
      position: absolute;
      top: 13px;
      left: 0;
   }
     
     .main-timeline .timeline-quote {
        font-size: 15px;
        background: $color-primary-dark;
        padding: 19px 45px;
        border-radius: 0px 60px 79px 53px;
        color: #fff;
        margin-top: 20px;
        max-width: 100%;

        @include sm {
         max-width: 75%;
      } 
   }
     
     .main-timeline .timeline .timeline-quote-two  {
        font-size: 15px;
        background: $color-burgundy;
        padding: 19px 45px;
        border-radius: 0px 60px 79px 53px;
        color: #fff;
        margin-top: 20px;
        max-width: 100%;

        @include sm {
         max-width: 75%;
      } 


     }
     
     .main-timeline .timeline-image {
        float: right;
        clear: both;
        padding: 15px;
     }
     
     .main-timeline .timeline:nth-child(even) .timeline-icon {
        left: auto;
        right: 36%;
        top: -8%;
     }
     
     .main-timeline .timeline:nth-child(even) .timeline-icon:before {
        right: auto;
        left: -100px;
     }
     
     .main-timeline .timeline:nth-child(even) .timeline-icon:after {
        right: auto;
        left: -30px;
     }
     
     .main-timeline .timeline:nth-child(even) .timeline-content {
        float: left;
     }
     
     .main-timeline .timeline:nth-child(even) .timeline-content:before {
        left: auto;
        right: 35px;
        transform: rotateY(180deg);
     }
     
     .main-timeline .timeline:nth-child(even) .timeline-content:after {
        left: auto;
        right: 0;
     }
     
     .main-timeline .timeline:nth-child(2n) .timeline-content:after,
     .main-timeline .timeline:nth-child(2n) .timeline-icon i,
     .main-timeline .timeline:nth-child(2n) .timeline-icon:before,
     .main-timeline .timeline:nth-child(2n):before {
        //background: #f9850f
     }
     
     .main-timeline .timeline:nth-child(2n) .timeline-icon {
        border-color: $color-primary-light;
        border-radius: 44px 44px 0 44px;
        background: $color-primary-light;

     }
     
     .main-timeline .timeline:nth-child(3n) .timeline-icon {
        border-color: $color-burgundy;
        border-radius: 44px 44px 0 44px;
        background: $color-burgundy;
     }
     
     .main-timeline .timeline:nth-child(4n) .timeline-icon {
      border-radius: 44px 44px 0 44px;
        background: $color-red;

     }
     

     

     @media only screen and (max-width: 1200px) {
        .main-timeline .timeline-icon:before {
           width: 50px;
           right: -50px;
        }
     
        .main-timeline .timeline:nth-child(even) .timeline-icon:before {
           right: auto;
           left: -50px;
        }
     
        .main-timeline .timeline-content {
           margin-top: 75px;
        }
     }
     
     @media only screen and (max-width: 990px) {
        .main-timeline .timeline {
           margin: 0 0 10px;
        }
     
        .main-timeline .timeline-icon {
           left: 25%;
        }
     
        .main-timeline .timeline:nth-child(even) .timeline-icon {
           right: 25%;
        }
     
        .main-timeline .timeline-content {
           margin-top: 115px;
        }
     }
     

        .main-timeline {
           padding-top: 50px;
        }
     
        .main-timeline:before {
           left: 80px;
           right: 0;
           margin: 0;
        }
     
        .main-timeline .timeline {
           margin-bottom: 70px;
        }
     
        .main-timeline .timeline:before {
         top: 23px;
         left: 86px;
         right: 0;
         margin: 0;
        }
     
        .main-timeline .timeline-icon {
         width: 82px;
         height: 50px;
         line-height: 40px;
         padding: 5px;
         top: 0;
         left: 0;
         margin: -15px 0px 0px -8px;
     }
     
        .main-timeline .timeline:nth-child(even) .timeline-icon {
           left: 0;
           right: auto;
           margin: -3px 0px 0px -8px;
        }
     
        .main-timeline .timeline-icon:before,
        .main-timeline .timeline:nth-child(even) .timeline-icon:before {
           width: 25px;
           left: auto;
           right: -25px;
        }
     
        .main-timeline .timeline-icon:after,
        .main-timeline .timeline:nth-child(even) .timeline-icon:after {
           width: 25px;
           height: 30px;
           top: 44px;
           left: auto;
           right: -5px;
        }
     
        .main-timeline .timeline-icon i {
           font-size: 30px;
           line-height: 45px;
        }
     
        .main-timeline .timeline-content,
        .main-timeline .timeline:nth-child(even) .timeline-content {
           width: 100%;
           margin-top: -15px;
           padding-left: 130px;
           padding-right: 5px;
        }
     
        .main-timeline .timeline:nth-child(even) .timeline-content {
           float: right;
        }
     
        .main-timeline .timeline-content:before,
        .main-timeline .timeline:nth-child(even) .timeline-content:before {
           width: 50%;
           left: 120px;
        }
     
        .main-timeline .timeline:nth-child(even) .timeline-content:before {
           right: auto;
           transform: rotateY(0);
        }
     
        .main-timeline .timeline-content:after,
        .main-timeline .timeline:nth-child(even) .timeline-content:after {
           left: 85px;
        }

     
     @media only screen and (max-width: 479px) {
        .main-timeline .timeline-content,
        .main-timeline .timeline:nth-child(2n) .timeline-content {
           padding-left: 110px;
        }
     
        .main-timeline .timeline-content:before,
        .main-timeline .timeline:nth-child(2n) .timeline-content:before {
           left: 99px;
        }
     
        .main-timeline .timeline-content:after,
        .main-timeline .timeline:nth-child(2n) .timeline-content:after {
           left: 65px;
        }
     }
     

}