/* Foundation First Block
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.foundation-first-content-block {
    margin: 3em 0;
 
    &__heading {
        color: $black;
        font-size: 1.75em;
        margin-bottom: 1em; 
        font-weight: 600;
        max-width: 75%;
        text-align: left;

    }

    &__copy{
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 1em;
    
    }
 }
 


 .foundation-single-testimonial {

     h2 {
        @include headline ($heading-color, $heading-size);  
        color: $primary-color;
    }


    &__body {
        /* max-width: 85%; */
        font-size: 1.25em;
        line-height: 1.2;
        font-weight: 900;
        color: #000;

        span {
            display: block;
            margin-top: 1em;
            color: $primary-color;
        }

    }

    &__box {
        background: #9fddc1;
        width: 29em;
        padding: 6em 5em;
        border-radius: 140px 154px 154px 0;
        max-width: 25em;
        margin-left: 12em;

    }

}



@media only screen and (max-width: 800px) {
    .foundation-single-testimonial__box{
        background: #9fddc1;
        width: 100%;
        padding: 4em;
        border-radius: 0px;
        /* max-width: 0; */
        margin-left: 0;
   }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {

        .foundation-single-testimonial__box{
            background: #9fddc1;
            width: 100%;
            padding: 4em;
            border-radius: 0;
            margin-left: -2em;
            border-radius: 140px 154px 154px 0;
    }

}




.foundation-social-section {

    &__main-heading {
    color: $white;
    font-size: 2em;
    margin-bottom: .5em;
    font-weight: 600;
    }

    &__twitter-heading {
    color: $white;
    font-size: 1em;    
    }
     
    margin: 3em 0 0;
    padding: 5em 0;
    min-height: 40em;
    /* background-image: url(../../assets/img/news-image.png); */
    background-repeat: no-repeat;
    position: relative;
    bottom: 0;
    right: 0;
    background: url(../../assets/img/foundationtwitter-bg.svg);
    background-size: cover;
    background-position: right 7%;
    min-height: 83vh;
}
