.insurance-plans-content-block {

    margin: 2em 0;

    &__title {
        color: $black;
        font-size: 1.75em;
        text-align: center;
        margin-bottom: 1em;
        font-weight: 600; 

        @include sm {
        text-align: left;
        } 

    }


    &__sub-title {
        color: $black;
        font-size: 1.25em;
        text-align: center;
        margin-bottom: .5em;

        @include sm {
        text-align: left;
        } 

    }

    ul li {
        list-style-type: disc;
    }


}