.insurance-posts-block {


    &__title {
        color: $black;
        font-size: 1.75em;
        text-align: center;
        margin-bottom: 1em;
        font-weight: 600;

        @include sm {
        text-align: left;
        } 

    }

    .card .card-body {
        padding: 25px 18px;
    }

    .card .card-body .card-title {
        font-size: 1.25em;
        margin-bottom: 20px;
        font-weight: 600;
    }

    .card-subtitle, .card-text:last-child {
        margin-bottom: 0;
        font-size: 1em;
    }

}



