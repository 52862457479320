.call-for-proposals-block{
    background: radial-gradient(85% 326% at 10% 110%, #43318b 0%, #43318b 50%, #43318b calc(19% + 1px), #43318b 23%, #852055 calc(50% + 1px), #852055 39%, #852055 calc(50% + 1px), #852055 100%);
    height: 16em;
    margin-top: 2em;
    width: 100%;
    min-height: 325px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    background-attachment: fixed;
    align-items: center;
       height: 16em;
       margin-top: 2em;
       width: 100%;
       min-height: 325px;
       background-repeat: no-repeat;
       background-size: cover;
       display: flex;
       justify-content: center;
       align-items: center;

     &__title {
        flex-grow: 3;
        font-size: 2em;
        text-align: center;
     }

     &__contact1 {
        flex-grow: 1;
        max-width: 307px;
        margin-right: 30px;
        align-self: center;
     }

     &__contact2 {
        flex-grow: 1;
        align-self: baseline;
     }


   }
   

@media only screen and (max-width: 968px) {
  .call-for-proposals-block{
    background:  #852055;
     
         &__title {
        flex-grow: 3;
         text-align: center;    
     }

     &__contact1 {
    max-width: none;
    margin-right: 0;
    text-align: center;
     }

     &__contact2 {
        flex-grow: 1;
         text-align: center;
     }
 
     
       
   }  
   
}
