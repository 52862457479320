.district-foundation-classifieds-block {
    margin: 3em 0;
    position: relative;
    bottom: 0;
    right: 0;

    &__main-heading {
        @include headline ($black, $heading-size);    
    }


        .card-img-top {
            width: 80%;
            /* border-top-left-radius: calc(.25rem - 1px); */
            /* border-top-right-radius: calc(.25rem - 1px); */
        }

        .card .card-body {
            padding: 10px 0px;
        }
          
        .card .card-body .card-title {
            font-size: 1.5em;
            margin-bottom: 7px;
            color: $color-red; 
            font-weight: 400;
            width: 80%;
        }

        .card .card-text {
            margin-top: 1em;
            line-height: 1.5;
            font-weight: 600;
            color: $black;
            width: 80%;
        }
        
        .district-title {
            color: $color-red;  
            font-weight: bold; 
        }


        &__wrapper {
            padding: 1em 0 0;
         }
         
     
         &__post-title {
             color: $black;
             font-size: 1.25em;
             text-align: left;
             margin: 30px 0 4px;
             font-weight: 800;
             max-width: 80%;
         }

     
         &__post-meta-title {
             color: $color-red;
             font-size: 1em;
             text-align: left;
             margin: 0 0 1em;
             font-weight: 700;
             letter-spacing: 0.1px;
         }


         &__post-meta-title::after {
            height: 1px;
            display: block;
            width: 100%;
            background:lighten($black, 75%);
            border-right: 1px #fff;
            content: "";
            margin: 2em auto;
        }
    }
