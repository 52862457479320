
.compare-plans-block{
   background: $primary-color;
       height: 16em;
       margin-top: 2em;
       width: 100%;
       min-height: 200px;
       background-repeat: no-repeat;
       background-size: cover;
       display: flex;
       justify-content: center;
       align-items: center;


       @include md {
        background: radial-gradient(62% 348% at 12% 96%,#e31b93 0,#e31b93 50%,#e31b93 calc(19% + 1px),#E31B91 23%,#43318b calc(50% + 1px),#43318b 43%,#43318b calc(50% + 1px),#43318b 100%);
        height: auto;
         } 

     &__title {
      flex-grow: 3;
      font-size: 2.25em;
      text-align: center;

      @include sm {
       text-align: left;
       } 
     }

     &__copy {
      flex-grow: 1;
      /* max-width: 0; */
      /* margin-right: 30px; */
      font-size: 1em;
      text-align: center;
      margin-top: 30px;
      word-break: break-word;

        @include sm {
          text-align: left;
          max-width: 300px;
        /* margin-right: 30px; */
         } 
     
     }

     &__contact {
        flex-grow: 1;
        text-align: center;

        @include sm {
         text-align: left;
         } 
     }


   }
   

