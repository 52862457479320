.district-masthead {
    min-height: 592px;
    position: relative;
    background-image: url(../../assets/img/district-microsite-header.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: inherit;

}



.rto-social-section {

    &__main-heading {
    color: $white;
    font-size: 2em;
    margin-bottom: .5em;
    font-weight: 600;
    }

    &__twitter-heading {
    color: $white;
    font-size: 1em;    
    }
     
    margin: 3em 0 0;
    padding: 5em 0;
    min-height: 40em;
    background-repeat: no-repeat;
    position: relative;
    bottom: 0;
    right: 0;
    background: url(../../assets/img/twitter-feed-banner.png);
    background-size: cover;
    background-position: right 7%;
    min-height: 83vh;
}
