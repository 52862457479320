 /* ---------
 Return to Top JS Button
 ------------
 */
 #return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: black;
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top i {
  transition: all .3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
color: #fff;
margin: 0;
position: relative;
left: 16px;
top: 13px;
font-size: 19px;
transition: all .3s ease;
background: transparent;
padding: inherit;
}


@media only screen and (max-width: 800px) {
    #return-to-top i {
        color: #fff;
        margin: 0;
        position: relative;
        left: 0px;
        top: 13px;
        font-size: 19px;
        transition: all .3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }    
}


#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}
#return-to-top:hover i {
    color: #fff;
    top: 5px;
}