.explore-insurance-block {
    background: url(../../assets/img/Our-Team-Banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    background-position: 0% 79%;
    min-height: 30em;
    z-index: -1;
 
 


    
    &__main-heading {
     color:$white;
     font-size: 2em;
     font-weight: 600;
     margin: 0em 0 1.25em;
     text-align: center;

     @include sm {
    text-align: left;
    } 


 }

    &__wrapper {
     background-color: $white;
     position: relative;
     z-index: 5;
     border-radius: 6px;
     padding: 33px 19px 25px;
     box-shadow: -3px 3px 10px #414346;
     width: 75%;
     display: block;
     margin: 0 auto;

     .last-child-block {
        margin-top: 5em;
     }
 
     @include md {
         display: flex;
         //width: 100%;
     
     } 
    }
 
    &__wrapper-heading{
        color: $black;
        margin-top: 10px;
        font-weight: 400;
        font-size: 1.25em;
        margin-bottom: 28px;
    }
 
    .border-right {
     border-right: 0px solid #dee2e6!important;
 
     @include md {
         border-right: 1px solid #dee2e6!important;
     
     } 
 }
 
 
 
 }