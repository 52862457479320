.generic-block {
    //font-size: $font-size;
    line-height: $line-height;
    text-rendering: optimizelegibility;
    padding: $block-padding 0;
    color: $font-color;
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    background-color: $white;

}

.page-section {
  padding: 1.2rem 0;

  @include md {
    padding: 4.5rem 0;

}
}

/*
.section {
    font-size: 16px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    padding: 2rem 0rem;
    color: #444444;
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}




:root {
    --base:              #f7392b;
    --bg-blend:          multiply;
    --blur:              0px;
    --fg-blend:          lighten;
    --foreground:        #16006f;
    --opacity:           1;
    --spacing:           40px;
  }
  .img-wrapper {
    background-color:    var(--base);
    display:             flex;
    flex:                1 1 100%;
    height:              100vh;
    width: 50%;
    overflow:            hidden;
    padding:             var(--spacing);
    position:            relative;
  }
  
  .img-wrapper img {
    filter:              grayscale(100%) contrast(1) blur(var(--blur));
    flex:                1 0 100%;
    height:              100%;
    max-width:           100%;
    mix-blend-mode:      var(--bg-blend);
    object-fit:          cover;
    opacity:             var(--opacity);
    position:            relative;
    width:               100%;
  }
  
  .img-wrapper::before {
    background-color:    var(--foreground);
    bottom:              0;
    content:             '';
    height:              100%;
    left:                0;
    mix-blend-mode:      var(--fg-blend);
    position:            absolute;
    right:               0;
    top:                 0;
    width:               100%;
    z-index:             1;
  }
  
  
  
  .text-max-width {
    max-width: 35em
  } 


  */