@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(https://fonts.googleapis.com/css?family=Raleway:300,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,700i);
/* line 2, node_modules/bootstrap/scss/_root.scss */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

/* line 19, node_modules/bootstrap/scss/_reboot.scss */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* line 25, node_modules/bootstrap/scss/_reboot.scss */
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* line 35, node_modules/bootstrap/scss/_reboot.scss */
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

/* line 46, node_modules/bootstrap/scss/_reboot.scss */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

/* line 66, node_modules/bootstrap/scss/_reboot.scss */
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

/* line 76, node_modules/bootstrap/scss/_reboot.scss */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

/* line 92, node_modules/bootstrap/scss/_reboot.scss */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

/* line 101, node_modules/bootstrap/scss/_reboot.scss */
p {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 114, node_modules/bootstrap/scss/_reboot.scss */
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

/* line 123, node_modules/bootstrap/scss/_reboot.scss */
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

/* line 129, node_modules/bootstrap/scss/_reboot.scss */
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 136, node_modules/bootstrap/scss/_reboot.scss */
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

/* line 143, node_modules/bootstrap/scss/_reboot.scss */
dt {
  font-weight: 700; }

/* line 147, node_modules/bootstrap/scss/_reboot.scss */
dd {
  margin-bottom: .5rem;
  margin-left: 0; }

/* line 152, node_modules/bootstrap/scss/_reboot.scss */
blockquote {
  margin: 0 0 1rem; }

/* line 156, node_modules/bootstrap/scss/_reboot.scss */
b,
strong {
  font-weight: bolder; }

/* line 161, node_modules/bootstrap/scss/_reboot.scss */
small {
  font-size: 80%; }

/* line 170, node_modules/bootstrap/scss/_reboot.scss */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

/* line 178, node_modules/bootstrap/scss/_reboot.scss */
sub {
  bottom: -.25em; }

/* line 179, node_modules/bootstrap/scss/_reboot.scss */
sup {
  top: -.5em; }

/* line 186, node_modules/bootstrap/scss/_reboot.scss */
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

/* line 202, node_modules/bootstrap/scss/_reboot.scss */
a:not([href]) {
  color: inherit;
  text-decoration: none; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

/* line 217, node_modules/bootstrap/scss/_reboot.scss */
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

/* line 225, node_modules/bootstrap/scss/_reboot.scss */
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

/* line 239, node_modules/bootstrap/scss/_reboot.scss */
figure {
  margin: 0 0 1rem; }

/* line 249, node_modules/bootstrap/scss/_reboot.scss */
img {
  vertical-align: middle;
  border-style: none; }

/* line 254, node_modules/bootstrap/scss/_reboot.scss */
svg {
  overflow: hidden;
  vertical-align: middle; }

/* line 266, node_modules/bootstrap/scss/_reboot.scss */
table {
  border-collapse: collapse; }

/* line 270, node_modules/bootstrap/scss/_reboot.scss */
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

/* line 278, node_modules/bootstrap/scss/_reboot.scss */
th {
  text-align: inherit; }

/* line 289, node_modules/bootstrap/scss/_reboot.scss */
label {
  display: inline-block;
  margin-bottom: 0.5rem; }

/* line 298, node_modules/bootstrap/scss/_reboot.scss */
button {
  border-radius: 0; }

/* line 307, node_modules/bootstrap/scss/_reboot.scss */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

/* line 312, node_modules/bootstrap/scss/_reboot.scss */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/* line 323, node_modules/bootstrap/scss/_reboot.scss */
button,
input {
  overflow: visible; }

/* line 328, node_modules/bootstrap/scss/_reboot.scss */
button,
select {
  text-transform: none; }

/* line 336, node_modules/bootstrap/scss/_reboot.scss */
select {
  word-wrap: normal; }

/* line 344, node_modules/bootstrap/scss/_reboot.scss */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/* line 357, node_modules/bootstrap/scss/_reboot.scss */
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

/* line 364, node_modules/bootstrap/scss/_reboot.scss */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

/* line 372, node_modules/bootstrap/scss/_reboot.scss */
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

/* line 379, node_modules/bootstrap/scss/_reboot.scss */
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

/* line 391, node_modules/bootstrap/scss/_reboot.scss */
textarea {
  overflow: auto;
  resize: vertical; }

/* line 397, node_modules/bootstrap/scss/_reboot.scss */
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

/* line 412, node_modules/bootstrap/scss/_reboot.scss */
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

/* line 424, node_modules/bootstrap/scss/_reboot.scss */
progress {
  vertical-align: baseline; }

/* line 429, node_modules/bootstrap/scss/_reboot.scss */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/* line 434, node_modules/bootstrap/scss/_reboot.scss */
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

/* line 447, node_modules/bootstrap/scss/_reboot.scss */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* line 456, node_modules/bootstrap/scss/_reboot.scss */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

/* line 465, node_modules/bootstrap/scss/_reboot.scss */
output {
  display: inline-block; }

/* line 469, node_modules/bootstrap/scss/_reboot.scss */
summary {
  display: list-item;
  cursor: pointer; }

/* line 474, node_modules/bootstrap/scss/_reboot.scss */
template {
  display: none; }

/* line 480, node_modules/bootstrap/scss/_reboot.scss */
[hidden] {
  display: none !important; }

/* line 7, node_modules/bootstrap/scss/_type.scss */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

/* line 16, node_modules/bootstrap/scss/_type.scss */
h1, .h1 {
  font-size: 2.5rem; }

/* line 17, node_modules/bootstrap/scss/_type.scss */
h2, .h2 {
  font-size: 2rem; }

/* line 18, node_modules/bootstrap/scss/_type.scss */
h3, .h3 {
  font-size: 1.75rem; }

/* line 19, node_modules/bootstrap/scss/_type.scss */
h4, .h4 {
  font-size: 1.5rem; }

/* line 20, node_modules/bootstrap/scss/_type.scss */
h5, .h5 {
  font-size: 1.25rem; }

/* line 21, node_modules/bootstrap/scss/_type.scss */
h6, .h6 {
  font-size: 1rem; }

/* line 23, node_modules/bootstrap/scss/_type.scss */
.lead {
  font-size: 1.25rem;
  font-weight: 300; }

/* line 29, node_modules/bootstrap/scss/_type.scss */
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 34, node_modules/bootstrap/scss/_type.scss */
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 39, node_modules/bootstrap/scss/_type.scss */
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 44, node_modules/bootstrap/scss/_type.scss */
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

/* line 55, node_modules/bootstrap/scss/_type.scss */
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* line 67, node_modules/bootstrap/scss/_type.scss */
small,
.small {
  font-size: 80%;
  font-weight: 400; }

/* line 73, node_modules/bootstrap/scss/_type.scss */
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

/* line 84, node_modules/bootstrap/scss/_type.scss */
.list-unstyled {
  padding-left: 0;
  list-style: none; }

/* line 89, node_modules/bootstrap/scss/_type.scss */
.list-inline {
  padding-left: 0;
  list-style: none; }

/* line 92, node_modules/bootstrap/scss/_type.scss */
.list-inline-item {
  display: inline-block; }
  /* line 95, node_modules/bootstrap/scss/_type.scss */
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

/* line 106, node_modules/bootstrap/scss/_type.scss */
.initialism {
  font-size: 90%;
  text-transform: uppercase; }

/* line 112, node_modules/bootstrap/scss/_type.scss */
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

/* line 117, node_modules/bootstrap/scss/_type.scss */
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  /* line 122, node_modules/bootstrap/scss/_type.scss */
  .blockquote-footer::before {
    content: "\2014\00A0"; }

/* line 8, node_modules/bootstrap/scss/_images.scss */
.img-fluid {
  max-width: 100%;
  height: auto; }

/* line 14, node_modules/bootstrap/scss/_images.scss */
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

/* line 29, node_modules/bootstrap/scss/_images.scss */
.figure {
  display: inline-block; }

/* line 34, node_modules/bootstrap/scss/_images.scss */
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

/* line 39, node_modules/bootstrap/scss/_images.scss */
.figure-caption {
  font-size: 90%;
  color: #6c757d; }

/* line 2, node_modules/bootstrap/scss/_code.scss */
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  /* line 8, node_modules/bootstrap/scss/_code.scss */
  a > code {
    color: inherit; }

/* line 14, node_modules/bootstrap/scss/_code.scss */
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  /* line 22, node_modules/bootstrap/scss/_code.scss */
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

/* line 31, node_modules/bootstrap/scss/_code.scss */
pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  /* line 37, node_modules/bootstrap/scss/_code.scss */
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

/* line 45, node_modules/bootstrap/scss/_code.scss */
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

/* line 7, node_modules/bootstrap/scss/_grid.scss */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    /* line 7, node_modules/bootstrap/scss/_grid.scss */
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    /* line 7, node_modules/bootstrap/scss/_grid.scss */
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    /* line 7, node_modules/bootstrap/scss/_grid.scss */
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    /* line 7, node_modules/bootstrap/scss/_grid.scss */
    .container {
      max-width: 1140px; } }

/* line 13, node_modules/bootstrap/scss/_grid.scss */
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

/* line 45, node_modules/bootstrap/scss/_grid.scss */
.row, .row-dark {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

/* line 51, node_modules/bootstrap/scss/_grid.scss */
.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  /* line 55, node_modules/bootstrap/scss/_grid.scss */
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

/* line 8, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

/* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-first {
  order: -1; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-last {
  order: 13; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-0 {
  order: 0; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-1 {
  order: 1; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-2 {
  order: 2; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-3 {
  order: 3; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-4 {
  order: 4; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-5 {
  order: 5; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-6 {
  order: 6; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-7 {
  order: 7; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-8 {
  order: 8; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-9 {
  order: 9; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-10 {
  order: 10; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-11 {
  order: 11; }

/* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-12 {
  order: 12; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-1 {
  margin-left: 8.33333%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-2 {
  margin-left: 16.66667%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-3 {
  margin-left: 25%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-4 {
  margin-left: 33.33333%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-5 {
  margin-left: 41.66667%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-6 {
  margin-left: 50%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-7 {
  margin-left: 58.33333%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-8 {
  margin-left: 66.66667%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-9 {
  margin-left: 75%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-10 {
  margin-left: 83.33333%; }

/* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-first {
    order: -1; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-last {
    order: 13; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-0 {
    order: 0; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-1 {
    order: 1; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-2 {
    order: 2; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-3 {
    order: 3; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-4 {
    order: 4; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-5 {
    order: 5; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-6 {
    order: 6; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-7 {
    order: 7; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-8 {
    order: 8; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-9 {
    order: 9; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-10 {
    order: 10; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-11 {
    order: 11; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-12 {
    order: 12; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-0 {
    margin-left: 0; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-1 {
    margin-left: 8.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-2 {
    margin-left: 16.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-3 {
    margin-left: 25%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-4 {
    margin-left: 33.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-5 {
    margin-left: 41.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-6 {
    margin-left: 50%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-7 {
    margin-left: 58.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-8 {
    margin-left: 66.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-9 {
    margin-left: 75%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-10 {
    margin-left: 83.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-first {
    order: -1; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-last {
    order: 13; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-0 {
    order: 0; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-1 {
    order: 1; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-2 {
    order: 2; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-3 {
    order: 3; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-4 {
    order: 4; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-5 {
    order: 5; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-6 {
    order: 6; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-7 {
    order: 7; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-8 {
    order: 8; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-9 {
    order: 9; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-10 {
    order: 10; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-11 {
    order: 11; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-12 {
    order: 12; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-0 {
    margin-left: 0; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-1 {
    margin-left: 8.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-2 {
    margin-left: 16.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-3 {
    margin-left: 25%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-4 {
    margin-left: 33.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-5 {
    margin-left: 41.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-6 {
    margin-left: 50%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-7 {
    margin-left: 58.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-8 {
    margin-left: 66.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-9 {
    margin-left: 75%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-10 {
    margin-left: 83.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-first {
    order: -1; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-last {
    order: 13; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-0 {
    order: 0; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-1 {
    order: 1; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-2 {
    order: 2; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-3 {
    order: 3; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-4 {
    order: 4; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-5 {
    order: 5; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-6 {
    order: 6; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-7 {
    order: 7; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-8 {
    order: 8; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-9 {
    order: 9; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-10 {
    order: 10; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-11 {
    order: 11; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-12 {
    order: 12; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-0 {
    margin-left: 0; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-1 {
    margin-left: 8.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-2 {
    margin-left: 16.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-3 {
    margin-left: 25%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-4 {
    margin-left: 33.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-5 {
    margin-left: 41.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-6 {
    margin-left: 50%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-7 {
    margin-left: 58.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-8 {
    margin-left: 66.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-9 {
    margin-left: 75%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-10 {
    margin-left: 83.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-first {
    order: -1; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-last {
    order: 13; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-0 {
    order: 0; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-1 {
    order: 1; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-2 {
    order: 2; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-3 {
    order: 3; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-4 {
    order: 4; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-5 {
    order: 5; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-6 {
    order: 6; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-7 {
    order: 7; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-8 {
    order: 8; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-9 {
    order: 9; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-10 {
    order: 10; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-11 {
    order: 11; }
  /* line 58, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-12 {
    order: 12; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-0 {
    margin-left: 0; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-1 {
    margin-left: 8.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-2 {
    margin-left: 16.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-3 {
    margin-left: 25%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-4 {
    margin-left: 33.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-5 {
    margin-left: 41.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-6 {
    margin-left: 50%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-7 {
    margin-left: 58.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-8 {
    margin-left: 66.66667%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-9 {
    margin-left: 75%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-10 {
    margin-left: 83.33333%; }
  /* line 64, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-11 {
    margin-left: 91.66667%; } }

/* line 5, node_modules/bootstrap/scss/_tables.scss */
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  /* line 11, node_modules/bootstrap/scss/_tables.scss */
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  /* line 18, node_modules/bootstrap/scss/_tables.scss */
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  /* line 23, node_modules/bootstrap/scss/_tables.scss */
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

/* line 34, node_modules/bootstrap/scss/_tables.scss */
.table-sm th,
.table-sm td {
  padding: 0.3rem; }

/* line 45, node_modules/bootstrap/scss/_tables.scss */
.table-bordered {
  border: 1px solid #dee2e6; }
  /* line 48, node_modules/bootstrap/scss/_tables.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  /* line 54, node_modules/bootstrap/scss/_tables.scss */
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

/* line 62, node_modules/bootstrap/scss/_tables.scss */
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

/* line 75, node_modules/bootstrap/scss/_tables.scss */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-info:hover {
  background-color: #abdde5; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-light:hover {
  background-color: #ececf6; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

/* line 114, node_modules/bootstrap/scss/_tables.scss */
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

/* line 122, node_modules/bootstrap/scss/_tables.scss */
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

/* line 130, node_modules/bootstrap/scss/_tables.scss */
.table-dark {
  color: #fff;
  background-color: #343a40; }
  /* line 134, node_modules/bootstrap/scss/_tables.scss */
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  /* line 140, node_modules/bootstrap/scss/_tables.scss */
  .table-dark.table-bordered {
    border: 0; }
  /* line 145, node_modules/bootstrap/scss/_tables.scss */
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-xl > .table-bordered {
      border: 0; } }

/* line 171, node_modules/bootstrap/scss/_tables.scss */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive > .table-bordered {
    border: 0; }

/* line 7, node_modules/bootstrap/scss/_forms.scss */
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 7, node_modules/bootstrap/scss/_forms.scss */
    .form-control {
      transition: none; } }
  /* line 28, node_modules/bootstrap/scss/_forms.scss */
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  /* line 34, node_modules/bootstrap/scss/_forms.scss */
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  /* line 14, node_modules/bootstrap/scss/mixins/_forms.scss */
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 43, node_modules/bootstrap/scss/_forms.scss */
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  /* line 54, node_modules/bootstrap/scss/_forms.scss */
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

/* line 63, node_modules/bootstrap/scss/_forms.scss */
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

/* line 75, node_modules/bootstrap/scss/_forms.scss */
.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

/* line 88, node_modules/bootstrap/scss/_forms.scss */
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

/* line 96, node_modules/bootstrap/scss/_forms.scss */
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

/* line 103, node_modules/bootstrap/scss/_forms.scss */
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

/* line 116, node_modules/bootstrap/scss/_forms.scss */
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  /* line 128, node_modules/bootstrap/scss/_forms.scss */
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

/* line 143, node_modules/bootstrap/scss/_forms.scss */
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 151, node_modules/bootstrap/scss/_forms.scss */
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 161, node_modules/bootstrap/scss/_forms.scss */
select.form-control[size], select.form-control[multiple] {
  height: auto; }

/* line 167, node_modules/bootstrap/scss/_forms.scss */
textarea.form-control {
  height: auto; }

/* line 176, node_modules/bootstrap/scss/_forms.scss */
.form-group {
  margin-bottom: 1rem; }

/* line 180, node_modules/bootstrap/scss/_forms.scss */
.form-text {
  display: block;
  margin-top: 0.25rem; }

/* line 190, node_modules/bootstrap/scss/_forms.scss */
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  /* line 196, node_modules/bootstrap/scss/_forms.scss */
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

/* line 208, node_modules/bootstrap/scss/_forms.scss */
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

/* line 214, node_modules/bootstrap/scss/_forms.scss */
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  /* line 220, node_modules/bootstrap/scss/_forms.scss */
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

/* line 226, node_modules/bootstrap/scss/_forms.scss */
.form-check-label {
  margin-bottom: 0; }

/* line 230, node_modules/bootstrap/scss/_forms.scss */
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  /* line 237, node_modules/bootstrap/scss/_forms.scss */
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

/* line 45, node_modules/bootstrap/scss/mixins/_forms.scss */
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

/* line 53, node_modules/bootstrap/scss/mixins/_forms.scss */
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

/* line 69, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  /* line 87, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  /* line 113, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

/* line 122, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

/* line 126, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

/* line 135, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  /* line 138, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

/* line 144, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

/* line 151, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

/* line 155, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

/* line 165, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

/* line 170, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

/* line 45, node_modules/bootstrap/scss/mixins/_forms.scss */
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

/* line 53, node_modules/bootstrap/scss/mixins/_forms.scss */
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

/* line 69, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  /* line 87, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  /* line 113, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

/* line 122, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

/* line 126, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 135, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  /* line 138, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

/* line 144, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

/* line 151, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

/* line 155, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

/* line 165, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

/* line 170, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

/* line 266, node_modules/bootstrap/scss/_forms.scss */
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  /* line 274, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    /* line 280, node_modules/bootstrap/scss/_forms.scss */
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    /* line 288, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    /* line 297, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    /* line 304, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-control-plaintext {
      display: inline-block; }
    /* line 308, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    /* line 315, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    /* line 322, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    /* line 330, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    /* line 334, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* line 7, node_modules/bootstrap/scss/_buttons.scss */
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 7, node_modules/bootstrap/scss/_buttons.scss */
    .btn {
      transition: none; } }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  /* line 27, node_modules/bootstrap/scss/_buttons.scss */
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 34, node_modules/bootstrap/scss/_buttons.scss */
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

/* line 51, node_modules/bootstrap/scss/_buttons.scss */
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

/* line 62, node_modules/bootstrap/scss/_buttons.scss */
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

/* line 62, node_modules/bootstrap/scss/_buttons.scss */
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

/* line 62, node_modules/bootstrap/scss/_buttons.scss */
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

/* line 62, node_modules/bootstrap/scss/_buttons.scss */
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

/* line 62, node_modules/bootstrap/scss/_buttons.scss */
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

/* line 62, node_modules/bootstrap/scss/_buttons.scss */
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

/* line 62, node_modules/bootstrap/scss/_buttons.scss */
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

/* line 62, node_modules/bootstrap/scss/_buttons.scss */
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

/* line 68, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

/* line 68, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

/* line 68, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

/* line 68, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

/* line 68, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

/* line 68, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

/* line 68, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

/* line 68, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

/* line 79, node_modules/bootstrap/scss/_buttons.scss */
.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  /* line 89, node_modules/bootstrap/scss/_buttons.scss */
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  /* line 95, node_modules/bootstrap/scss/_buttons.scss */
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

/* line 109, node_modules/bootstrap/scss/_buttons.scss */
.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 113, node_modules/bootstrap/scss/_buttons.scss */
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 122, node_modules/bootstrap/scss/_buttons.scss */
.btn-block {
  display: block;
  width: 100%; }
  /* line 127, node_modules/bootstrap/scss/_buttons.scss */
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

/* line 136, node_modules/bootstrap/scss/_buttons.scss */
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

/* line 1, node_modules/bootstrap/scss/_transitions.scss */
.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    /* line 1, node_modules/bootstrap/scss/_transitions.scss */
    .fade {
      transition: none; } }
  /* line 4, node_modules/bootstrap/scss/_transitions.scss */
  .fade:not(.show) {
    opacity: 0; }

/* line 10, node_modules/bootstrap/scss/_transitions.scss */
.collapse:not(.show) {
  display: none; }

/* line 15, node_modules/bootstrap/scss/_transitions.scss */
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 15, node_modules/bootstrap/scss/_transitions.scss */
    .collapsing {
      transition: none; } }

/* line 2, node_modules/bootstrap/scss/_dropdown.scss */
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

/* line 9, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-toggle {
  white-space: nowrap; }
  /* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  /* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
  .dropdown-toggle:empty::after {
    margin-left: 0; }

/* line 17, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

/* line 42, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu-left {
  right: auto;
  left: 0; }

/* line 47, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

/* line 57, node_modules/bootstrap/scss/_dropdown.scss */
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 70, node_modules/bootstrap/scss/_dropdown.scss */
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 80, node_modules/bootstrap/scss/_dropdown.scss */
.dropright .dropdown-toggle::after {
  vertical-align: 0; }

/* line 87, node_modules/bootstrap/scss/_dropdown.scss */
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

/* line 45, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: none; }

/* line 49, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 97, node_modules/bootstrap/scss/_dropdown.scss */
.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

/* line 106, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

/* line 116, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

/* line 123, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  /* line 153, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  /* line 160, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

/* line 172, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu.show {
  display: block; }

/* line 177, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

/* line 187, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

/* line 4, node_modules/bootstrap/scss/_button-group.scss */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  /* line 10, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    /* line 19, node_modules/bootstrap/scss/_button-group.scss */
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

/* line 28, node_modules/bootstrap/scss/_button-group.scss */
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  /* line 33, node_modules/bootstrap/scss/_button-group.scss */
  .btn-toolbar .input-group {
    width: auto; }

/* line 40, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

/* line 46, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* line 51, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/* line 69, node_modules/bootstrap/scss/_button-group.scss */
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  /* line 73, node_modules/bootstrap/scss/_button-group.scss */
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  /* line 79, node_modules/bootstrap/scss/_button-group.scss */
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

/* line 84, node_modules/bootstrap/scss/_button-group.scss */
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

/* line 89, node_modules/bootstrap/scss/_button-group.scss */
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

/* line 111, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  /* line 116, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  /* line 121, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  /* line 127, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 132, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* line 152, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  /* line 156, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

/* line 7, node_modules/bootstrap/scss/_input-group.scss */
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  /* line 14, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    /* line 23, node_modules/bootstrap/scss/_input-group.scss */
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  /* line 31, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  /* line 38, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  /* line 44, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  /* line 45, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 50, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    /* line 54, node_modules/bootstrap/scss/_input-group.scss */
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    /* line 56, node_modules/bootstrap/scss/_input-group.scss */
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

/* line 67, node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend,
.input-group-append {
  display: flex; }
  /* line 74, node_modules/bootstrap/scss/_input-group.scss */
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    /* line 78, node_modules/bootstrap/scss/_input-group.scss */
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  /* line 83, node_modules/bootstrap/scss/_input-group.scss */
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

/* line 91, node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend {
  margin-right: -1px; }

/* line 92, node_modules/bootstrap/scss/_input-group.scss */
.input-group-append {
  margin-left: -1px; }

/* line 100, node_modules/bootstrap/scss/_input-group.scss */
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  /* line 116, node_modules/bootstrap/scss/_input-group.scss */
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

/* line 128, node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

/* line 133, node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 145, node_modules/bootstrap/scss/_input-group.scss */
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

/* line 150, node_modules/bootstrap/scss/_input-group.scss */
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 162, node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

/* line 175, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* line 184, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/* line 10, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

/* line 17, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

/* line 22, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  /* line 30, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  /* line 37, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 46, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  /* line 50, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  /* line 60, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    /* line 63, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

/* line 74, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  /* line 82, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  /* line 97, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

/* line 115, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

/* line 120, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

/* line 126, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

/* line 131, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

/* line 137, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

/* line 140, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

/* line 151, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-label::before {
  border-radius: 50%; }

/* line 157, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

/* line 163, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

/* line 174, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-switch {
  padding-left: 2.25rem; }
  /* line 178, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  /* line 186, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      /* line 186, node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-switch .custom-control-label::after {
        transition: none; } }
  /* line 199, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  /* line 206, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

/* line 219, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  /* line 236, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    /* line 245, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  /* line 256, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  /* line 263, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  /* line 269, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select::-ms-expand {
    display: none; }
  /* line 274, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

/* line 280, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

/* line 288, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

/* line 301, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

/* line 309, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  /* line 317, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 323, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  /* line 329, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  /* line 334, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

/* line 339, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  /* line 356, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

/* line 380, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  /* line 387, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:focus {
    outline: none; }
    /* line 392, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    /* line 393, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    /* line 394, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 397, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-focus-outer {
    border: 0; }
  /* line 401, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      /* line 401, node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    /* line 412, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  /* line 417, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  /* line 428, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      /* line 428, node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-range::-moz-range-thumb {
        transition: none; } }
    /* line 438, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  /* line 443, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  /* line 454, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      /* line 454, node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-range::-ms-thumb {
        transition: none; } }
    /* line 467, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  /* line 472, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  /* line 483, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  /* line 488, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  /* line 495, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  /* line 499, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  /* line 503, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  /* line 507, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  /* line 511, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

/* line 517, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 517, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

/* line 6, node_modules/bootstrap/scss/_nav.scss */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

/* line 14, node_modules/bootstrap/scss/_nav.scss */
.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  /* line 23, node_modules/bootstrap/scss/_nav.scss */
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

/* line 34, node_modules/bootstrap/scss/_nav.scss */
.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  /* line 37, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  /* line 41, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    /* line 49, node_modules/bootstrap/scss/_nav.scss */
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  /* line 56, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  /* line 63, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* line 77, node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link {
  border-radius: 0.25rem; }

/* line 81, node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

/* line 94, node_modules/bootstrap/scss/_nav.scss */
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

/* line 101, node_modules/bootstrap/scss/_nav.scss */
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

/* line 114, node_modules/bootstrap/scss/_nav.scss */
.tab-content > .tab-pane {
  display: none; }

/* line 117, node_modules/bootstrap/scss/_nav.scss */
.tab-content > .active {
  display: block; }

/* line 18, node_modules/bootstrap/scss/_navbar.scss */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  /* line 28, node_modules/bootstrap/scss/_navbar.scss */
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

/* line 52, node_modules/bootstrap/scss/_navbar.scss */
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

/* line 71, node_modules/bootstrap/scss/_navbar.scss */
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  /* line 78, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  /* line 83, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

/* line 94, node_modules/bootstrap/scss/_navbar.scss */
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

/* line 109, node_modules/bootstrap/scss/_navbar.scss */
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

/* line 118, node_modules/bootstrap/scss/_navbar.scss */
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

/* line 133, node_modules/bootstrap/scss/_navbar.scss */
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 173, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      /* line 176, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 180, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 187, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    /* line 202, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 209, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 173, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      /* line 176, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 180, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 187, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    /* line 202, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 209, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 173, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      /* line 176, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 180, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 187, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    /* line 202, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 209, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 173, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      /* line 176, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 180, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 187, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    /* line 202, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 209, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

/* line 150, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  /* line 173, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    /* line 176, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    /* line 180, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  /* line 187, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  /* line 202, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  /* line 209, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-toggler {
    display: none; }

/* line 224, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

/* line 233, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  /* line 240, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

/* line 245, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

/* line 253, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

/* line 258, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

/* line 262, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  /* line 264, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

/* line 276, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-brand {
  color: #fff; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

/* line 285, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  /* line 292, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

/* line 297, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

/* line 305, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

/* line 310, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

/* line 314, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  /* line 316, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-dark .navbar-text a {
    color: #fff; }
    /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

/* line 5, node_modules/bootstrap/scss/_card.scss */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  /* line 17, node_modules/bootstrap/scss/_card.scss */
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  /* line 23, node_modules/bootstrap/scss/_card.scss */
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  /* line 29, node_modules/bootstrap/scss/_card.scss */
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

/* line 35, node_modules/bootstrap/scss/_card.scss */
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

/* line 46, node_modules/bootstrap/scss/_card.scss */
.card-title {
  margin-bottom: 0.75rem; }

/* line 50, node_modules/bootstrap/scss/_card.scss */
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

/* line 55, node_modules/bootstrap/scss/_card.scss */
.card-text:last-child {
  margin-bottom: 0; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.card-link:hover {
  text-decoration: none; }

/* line 64, node_modules/bootstrap/scss/_card.scss */
.card-link + .card-link {
  margin-left: 1.25rem; }

/* line 73, node_modules/bootstrap/scss/_card.scss */
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 80, node_modules/bootstrap/scss/_card.scss */
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  /* line 85, node_modules/bootstrap/scss/_card.scss */
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

/* line 91, node_modules/bootstrap/scss/_card.scss */
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 96, node_modules/bootstrap/scss/_card.scss */
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

/* line 106, node_modules/bootstrap/scss/_card.scss */
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

/* line 113, node_modules/bootstrap/scss/_card.scss */
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

/* line 119, node_modules/bootstrap/scss/_card.scss */
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

/* line 128, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

/* line 135, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

/* line 140, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

/* line 149, node_modules/bootstrap/scss/_card.scss */
.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  /* line 148, node_modules/bootstrap/scss/_card.scss */
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    /* line 159, node_modules/bootstrap/scss/_card.scss */
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

/* line 177, node_modules/bootstrap/scss/_card.scss */
.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  /* line 174, node_modules/bootstrap/scss/_card.scss */
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    /* line 186, node_modules/bootstrap/scss/_card.scss */
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      /* line 191, node_modules/bootstrap/scss/_card.scss */
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      /* line 198, node_modules/bootstrap/scss/_card.scss */
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        /* line 201, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        /* line 206, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      /* line 213, node_modules/bootstrap/scss/_card.scss */
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        /* line 216, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        /* line 221, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

/* line 238, node_modules/bootstrap/scss/_card.scss */
.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  /* line 237, node_modules/bootstrap/scss/_card.scss */
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    /* line 248, node_modules/bootstrap/scss/_card.scss */
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

/* line 261, node_modules/bootstrap/scss/_card.scss */
.accordion > .card {
  overflow: hidden; }
  /* line 264, node_modules/bootstrap/scss/_card.scss */
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 269, node_modules/bootstrap/scss/_card.scss */
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  /* line 273, node_modules/bootstrap/scss/_card.scss */
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

/* line 1, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

/* line 14, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  /* line 17, node_modules/bootstrap/scss/_breadcrumb.scss */
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

/* line 31, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

/* line 35, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

/* line 39, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item.active {
  color: #6c757d; }

/* line 1, node_modules/bootstrap/scss/_pagination.scss */
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

/* line 7, node_modules/bootstrap/scss/_pagination.scss */
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  /* line 17, node_modules/bootstrap/scss/_pagination.scss */
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  /* line 25, node_modules/bootstrap/scss/_pagination.scss */
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

/* line 34, node_modules/bootstrap/scss/_pagination.scss */
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

/* line 40, node_modules/bootstrap/scss/_pagination.scss */
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

/* line 45, node_modules/bootstrap/scss/_pagination.scss */
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

/* line 52, node_modules/bootstrap/scss/_pagination.scss */
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

/* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

/* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

/* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

/* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

/* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

/* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

/* line 6, node_modules/bootstrap/scss/_badge.scss */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 6, node_modules/bootstrap/scss/_badge.scss */
    .badge {
      transition: none; } }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  /* line 25, node_modules/bootstrap/scss/_badge.scss */
  .badge:empty {
    display: none; }

/* line 31, node_modules/bootstrap/scss/_badge.scss */
.btn .badge {
  position: relative;
  top: -1px; }

/* line 40, node_modules/bootstrap/scss/_badge.scss */
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-primary {
  color: #fff;
  background-color: #007bff; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-success {
  color: #fff;
  background-color: #28a745; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-dark {
  color: #fff;
  background-color: #343a40; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

/* line 1, node_modules/bootstrap/scss/_jumbotron.scss */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    /* line 1, node_modules/bootstrap/scss/_jumbotron.scss */
    .jumbotron {
      padding: 4rem 2rem; } }

/* line 13, node_modules/bootstrap/scss/_jumbotron.scss */
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

/* line 5, node_modules/bootstrap/scss/_alert.scss */
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

/* line 14, node_modules/bootstrap/scss/_alert.scss */
.alert-heading {
  color: inherit; }

/* line 20, node_modules/bootstrap/scss/_alert.scss */
.alert-link {
  font-weight: 700; }

/* line 29, node_modules/bootstrap/scss/_alert.scss */
.alert-dismissible {
  padding-right: 4rem; }
  /* line 33, node_modules/bootstrap/scss/_alert.scss */
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-primary hr {
    border-top-color: #9fcdff; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-primary .alert-link {
    color: #002752; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-secondary .alert-link {
    color: #202326; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-success hr {
    border-top-color: #b1dfbb; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-success .alert-link {
    color: #0b2e13; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-info hr {
    border-top-color: #abdde5; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-info .alert-link {
    color: #062c33; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-warning .alert-link {
    color: #533f03; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-danger .alert-link {
    color: #491217; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-light hr {
    border-top-color: #ececf6; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-light .alert-link {
    color: #686868; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

/* line 9, node_modules/bootstrap/scss/_progress.scss */
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

/* line 19, node_modules/bootstrap/scss/_progress.scss */
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 19, node_modules/bootstrap/scss/_progress.scss */
    .progress-bar {
      transition: none; } }

/* line 31, node_modules/bootstrap/scss/_progress.scss */
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

/* line 37, node_modules/bootstrap/scss/_progress.scss */
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    /* line 37, node_modules/bootstrap/scss/_progress.scss */
    .progress-bar-animated {
      animation: none; } }

/* line 1, node_modules/bootstrap/scss/_media.scss */
.media {
  display: flex;
  align-items: flex-start; }

/* line 6, node_modules/bootstrap/scss/_media.scss */
.media-body {
  flex: 1; }

/* line 5, node_modules/bootstrap/scss/_list-group.scss */
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

/* line 20, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  /* line 33, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

/* line 44, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 52, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  /* line 56, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  /* line 60, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  /* line 68, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  /* line 75, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    /* line 78, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

/* line 94, node_modules/bootstrap/scss/_list-group.scss */
.list-group-horizontal {
  flex-direction: row; }
  /* line 98, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  /* line 103, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  /* line 108, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  /* line 112, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    /* line 116, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  /* line 94, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-sm {
    flex-direction: row; }
    /* line 98, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    /* line 103, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    /* line 108, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    /* line 112, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 116, node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  /* line 94, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-md {
    flex-direction: row; }
    /* line 98, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    /* line 103, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    /* line 108, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    /* line 112, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 116, node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  /* line 94, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-lg {
    flex-direction: row; }
    /* line 98, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    /* line 103, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    /* line 108, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    /* line 112, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 116, node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  /* line 94, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-xl {
    flex-direction: row; }
    /* line 98, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    /* line 103, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    /* line 108, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    /* line 112, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 116, node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

/* line 133, node_modules/bootstrap/scss/_list-group.scss */
.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  /* line 138, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

/* line 144, node_modules/bootstrap/scss/_list-group.scss */
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

/* line 1, node_modules/bootstrap/scss/_close.scss */
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .close:hover {
    color: #000;
    text-decoration: none; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

/* line 29, node_modules/bootstrap/scss/_close.scss */
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

/* line 39, node_modules/bootstrap/scss/_close.scss */
a.close.disabled {
  pointer-events: none; }

/* line 1, node_modules/bootstrap/scss/_toasts.scss */
.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  /* line 14, node_modules/bootstrap/scss/_toasts.scss */
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  /* line 18, node_modules/bootstrap/scss/_toasts.scss */
  .toast.showing {
    opacity: 1; }
  /* line 22, node_modules/bootstrap/scss/_toasts.scss */
  .toast.show {
    display: block;
    opacity: 1; }
  /* line 27, node_modules/bootstrap/scss/_toasts.scss */
  .toast.hide {
    display: none; }

/* line 32, node_modules/bootstrap/scss/_toasts.scss */
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

/* line 42, node_modules/bootstrap/scss/_toasts.scss */
.toast-body {
  padding: 0.75rem; }

/* line 7, node_modules/bootstrap/scss/_modal.scss */
.modal-open {
  overflow: hidden; }
  /* line 11, node_modules/bootstrap/scss/_modal.scss */
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

/* line 18, node_modules/bootstrap/scss/_modal.scss */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

/* line 36, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  /* line 44, node_modules/bootstrap/scss/_modal.scss */
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      /* line 44, node_modules/bootstrap/scss/_modal.scss */
      .modal.fade .modal-dialog {
        transition: none; } }
  /* line 48, node_modules/bootstrap/scss/_modal.scss */
  .modal.show .modal-dialog {
    transform: none; }
  /* line 53, node_modules/bootstrap/scss/_modal.scss */
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

/* line 58, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  /* line 62, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  /* line 67, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  /* line 72, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

/* line 77, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  /* line 83, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  /* line 90, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    /* line 95, node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    /* line 99, node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

/* line 106, node_modules/bootstrap/scss/_modal.scss */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

/* line 124, node_modules/bootstrap/scss/_modal.scss */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  /* line 134, node_modules/bootstrap/scss/_modal.scss */
  .modal-backdrop.fade {
    opacity: 0; }
  /* line 135, node_modules/bootstrap/scss/_modal.scss */
  .modal-backdrop.show {
    opacity: 0.5; }

/* line 140, node_modules/bootstrap/scss/_modal.scss */
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  /* line 148, node_modules/bootstrap/scss/_modal.scss */
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

/* line 156, node_modules/bootstrap/scss/_modal.scss */
.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

/* line 163, node_modules/bootstrap/scss/_modal.scss */
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

/* line 172, node_modules/bootstrap/scss/_modal.scss */
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  /* line 185, node_modules/bootstrap/scss/_modal.scss */
  .modal-footer > * {
    margin: 0.25rem; }

/* line 191, node_modules/bootstrap/scss/_modal.scss */
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  /* line 202, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  /* line 207, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    /* line 210, node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  /* line 215, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    /* line 218, node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  /* line 227, node_modules/bootstrap/scss/_modal.scss */
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  /* line 231, node_modules/bootstrap/scss/_modal.scss */
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  /* line 238, node_modules/bootstrap/scss/_modal.scss */
  .modal-xl {
    max-width: 1140px; } }

/* line 2, node_modules/bootstrap/scss/_tooltip.scss */
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  /* line 15, node_modules/bootstrap/scss/_tooltip.scss */
  .tooltip.show {
    opacity: 0.9; }
  /* line 17, node_modules/bootstrap/scss/_tooltip.scss */
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    /* line 23, node_modules/bootstrap/scss/_tooltip.scss */
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

/* line 32, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  /* line 35, node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    /* line 38, node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

/* line 46, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  /* line 49, node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 54, node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

/* line 62, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  /* line 65, node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    /* line 68, node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

/* line 76, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  /* line 79, node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 84, node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

/* line 108, node_modules/bootstrap/scss/_tooltip.scss */
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

/* line 1, node_modules/bootstrap/scss/_popover.scss */
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  /* line 20, node_modules/bootstrap/scss/_popover.scss */
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    /* line 27, node_modules/bootstrap/scss/_popover.scss */
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

/* line 38, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  /* line 41, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    /* line 44, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    /* line 50, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

/* line 58, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  /* line 61, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    /* line 67, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    /* line 73, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

/* line 81, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  /* line 84, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    /* line 87, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    /* line 93, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  /* line 101, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

/* line 113, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  /* line 116, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    /* line 122, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    /* line 128, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

/* line 153, node_modules/bootstrap/scss/_popover.scss */
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  /* line 162, node_modules/bootstrap/scss/_popover.scss */
  .popover-header:empty {
    display: none; }

/* line 167, node_modules/bootstrap/scss/_popover.scss */
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

/* line 14, node_modules/bootstrap/scss/_carousel.scss */
.carousel {
  position: relative; }

/* line 18, node_modules/bootstrap/scss/_carousel.scss */
.carousel.pointer-event {
  touch-action: pan-y; }

/* line 22, node_modules/bootstrap/scss/_carousel.scss */
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  /* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

/* line 29, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 29, node_modules/bootstrap/scss/_carousel.scss */
    .carousel-item {
      transition: none; } }

/* line 39, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* line 45, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

/* line 50, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

/* line 61, node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

/* line 67, node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

/* line 74, node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    /* line 74, node_modules/bootstrap/scss/_carousel.scss */
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

/* line 87, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 87, node_modules/bootstrap/scss/_carousel.scss */
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

/* line 111, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev {
  left: 0; }

/* line 117, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-next {
  right: 0; }

/* line 125, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

/* line 132, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

/* line 135, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

/* line 145, node_modules/bootstrap/scss/_carousel.scss */
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  /* line 159, node_modules/bootstrap/scss/_carousel.scss */
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      /* line 159, node_modules/bootstrap/scss/_carousel.scss */
      .carousel-indicators li {
        transition: none; } }
  /* line 177, node_modules/bootstrap/scss/_carousel.scss */
  .carousel-indicators .active {
    opacity: 1; }

/* line 187, node_modules/bootstrap/scss/_carousel.scss */
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

/* line 9, node_modules/bootstrap/scss/_spinners.scss */
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

/* line 21, node_modules/bootstrap/scss/_spinners.scss */
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

/* line 40, node_modules/bootstrap/scss/_spinners.scss */
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

/* line 52, node_modules/bootstrap/scss/_spinners.scss */
.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

/* line 3, node_modules/bootstrap/scss/utilities/_align.scss */
.align-baseline {
  vertical-align: baseline !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_align.scss */
.align-top {
  vertical-align: top !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_align.scss */
.align-middle {
  vertical-align: middle !important; }

/* line 6, node_modules/bootstrap/scss/utilities/_align.scss */
.align-bottom {
  vertical-align: bottom !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-bottom {
  vertical-align: text-bottom !important; }

/* line 8, node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-top {
  vertical-align: text-top !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-primary {
  background-color: #007bff !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-secondary {
  background-color: #6c757d !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-success {
  background-color: #28a745 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-info {
  background-color: #17a2b8 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-warning {
  background-color: #ffc107 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-danger {
  background-color: #dc3545 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-light {
  background-color: #f8f9fa !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-dark {
  background-color: #343a40 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_background.scss */
.bg-white {
  background-color: #fff !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_background.scss */
.bg-transparent {
  background-color: transparent !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_borders.scss */
.border {
  border: 1px solid #dee2e6 !important; }

/* line 8, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top {
  border-top: 1px solid #dee2e6 !important; }

/* line 9, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right {
  border-right: 1px solid #dee2e6 !important; }

/* line 10, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left {
  border-left: 1px solid #dee2e6 !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-0 {
  border: 0 !important; }

/* line 14, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top-0 {
  border-top: 0 !important; }

/* line 15, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right-0 {
  border-right: 0 !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom-0 {
  border-bottom: 0 !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left-0 {
  border-left: 0 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-primary {
  border-color: #007bff !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-secondary {
  border-color: #6c757d !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-success {
  border-color: #28a745 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-info {
  border-color: #17a2b8 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-warning {
  border-color: #ffc107 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-danger {
  border-color: #dc3545 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-light {
  border-color: #f8f9fa !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-dark {
  border-color: #343a40 !important; }

/* line 25, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-white {
  border-color: #fff !important; }

/* line 33, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-sm {
  border-radius: 0.2rem !important; }

/* line 37, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded {
  border-radius: 0.25rem !important; }

/* line 41, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

/* line 46, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

/* line 51, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

/* line 56, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

/* line 61, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-lg {
  border-radius: 0.3rem !important; }

/* line 65, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-circle {
  border-radius: 50% !important; }

/* line 69, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-pill {
  border-radius: 50rem !important; }

/* line 73, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-0 {
  border-radius: 0 !important; }

/* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */
.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-none {
  display: none !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline {
  display: inline !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-block {
  display: inline-block !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-block {
  display: block !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table {
  display: table !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-row {
  display: table-row !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-cell {
  display: table-cell !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-flex {
  display: flex !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-none {
    display: none !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline {
    display: inline !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-block {
    display: inline-block !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-block {
    display: block !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table {
    display: table !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-row {
    display: table-row !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-cell {
    display: table-cell !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-flex {
    display: flex !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* line 3, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  /* line 10, node_modules/bootstrap/scss/utilities/_embed.scss */
  .embed-responsive::before {
    display: block;
    content: ""; }
  /* line 15, node_modules/bootstrap/scss/utilities/_embed.scss */
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%; }

/* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row {
  flex-direction: row !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column {
  flex-direction: column !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row-reverse {
  flex-direction: row-reverse !important; }

/* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column-reverse {
  flex-direction: column-reverse !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap {
  flex-wrap: wrap !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-nowrap {
  flex-wrap: nowrap !important; }

/* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

/* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-fill {
  flex: 1 1 auto !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-0 {
  flex-grow: 0 !important; }

/* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-1 {
  flex-grow: 1 !important; }

/* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-0 {
  flex-shrink: 0 !important; }

/* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-1 {
  flex-shrink: 1 !important; }

/* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-start {
  justify-content: flex-start !important; }

/* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-end {
  justify-content: flex-end !important; }

/* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-center {
  justify-content: center !important; }

/* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-between {
  justify-content: space-between !important; }

/* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-around {
  justify-content: space-around !important; }

/* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-start {
  align-items: flex-start !important; }

/* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-end {
  align-items: flex-end !important; }

/* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-center {
  align-items: center !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-baseline {
  align-items: baseline !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-stretch {
  align-items: stretch !important; }

/* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-start {
  align-content: flex-start !important; }

/* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-end {
  align-content: flex-end !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-center {
  align-content: center !important; }

/* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-between {
  align-content: space-between !important; }

/* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-around {
  align-content: space-around !important; }

/* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-stretch {
  align-content: stretch !important; }

/* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-auto {
  align-self: auto !important; }

/* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-start {
  align-self: flex-start !important; }

/* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-end {
  align-self: flex-end !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-center {
  align-self: center !important; }

/* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-baseline {
  align-self: baseline !important; }

/* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
.float-left {
  float: left !important; }

/* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
.float-right {
  float: right !important; }

/* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
.float-none {
  float: none !important; }

@media (min-width: 576px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-none {
    float: none !important; } }

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-auto {
  overflow: auto !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-hidden {
  overflow: hidden !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-static {
  position: static !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-relative {
  position: relative !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-absolute {
  position: absolute !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-fixed {
  position: fixed !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-sticky {
  position: sticky !important; }

/* line 10, node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

/* line 18, node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  /* line 26, node_modules/bootstrap/scss/utilities/_position.scss */
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

/* line 5, node_modules/bootstrap/scss/utilities/_screenreaders.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/* line 25, node_modules/bootstrap/scss/mixins/_screen-reader.scss */
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

/* line 3, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

/* line 6, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-none {
  box-shadow: none !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-25 {
  width: 25% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-50 {
  width: 50% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-75 {
  width: 75% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-100 {
  width: 100% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-auto {
  width: auto !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-25 {
  height: 25% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-50 {
  height: 50% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-75 {
  height: 75% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-100 {
  height: 100% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-auto {
  height: auto !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_sizing.scss */
.mw-100 {
  max-width: 100% !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_sizing.scss */
.mh-100 {
  max-height: 100% !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vw-100 {
  min-width: 100vw !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vh-100 {
  min-height: 100vh !important; }

/* line 19, node_modules/bootstrap/scss/utilities/_sizing.scss */
.vw-100 {
  width: 100vw !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_sizing.scss */
.vh-100 {
  height: 100vh !important; }

/* line 6, node_modules/bootstrap/scss/utilities/_stretched-link.scss */
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-0 {
  margin: 0 !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-0,
.my-0 {
  margin-top: 0 !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-0,
.mx-0 {
  margin-right: 0 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-0,
.mx-0 {
  margin-left: 0 !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-1 {
  margin: 0.25rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-2 {
  margin: 0.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-3 {
  margin: 1rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-3,
.my-3 {
  margin-top: 1rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-4 {
  margin: 1.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-5 {
  margin: 3rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-5,
.my-5 {
  margin-top: 3rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-0 {
  padding: 0 !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-0,
.py-0 {
  padding-top: 0 !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-0,
.px-0 {
  padding-right: 0 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-0,
.px-0 {
  padding-left: 0 !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-1 {
  padding: 0.25rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-2 {
  padding: 0.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-3 {
  padding: 1rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-3,
.py-3 {
  padding-top: 1rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-3,
.px-3 {
  padding-right: 1rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-3,
.px-3 {
  padding-left: 1rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-4 {
  padding: 1.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-5 {
  padding: 3rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-5,
.py-5 {
  padding-top: 3rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-5,
.px-5 {
  padding-right: 3rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-5,
.px-5 {
  padding-left: 3rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n1 {
  margin: -0.25rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n2 {
  margin: -0.5rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n3 {
  margin: -1rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n4 {
  margin: -1.5rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n5 {
  margin: -3rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

/* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-auto {
  margin: auto !important; }

/* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-auto,
.my-auto {
  margin-top: auto !important; }

/* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-auto,
.mx-auto {
  margin-right: auto !important; }

/* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

/* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

/* line 7, node_modules/bootstrap/scss/utilities/_text.scss */
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_text.scss */
.text-justify {
  text-align: justify !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_text.scss */
.text-wrap {
  white-space: normal !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_text.scss */
.text-nowrap {
  white-space: nowrap !important; }

/* line 14, node_modules/bootstrap/scss/utilities/_text.scss */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
.text-left {
  text-align: left !important; }

/* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
.text-right {
  text-align: right !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-center {
    text-align: center !important; } }

/* line 30, node_modules/bootstrap/scss/utilities/_text.scss */
.text-lowercase {
  text-transform: lowercase !important; }

/* line 31, node_modules/bootstrap/scss/utilities/_text.scss */
.text-uppercase {
  text-transform: uppercase !important; }

/* line 32, node_modules/bootstrap/scss/utilities/_text.scss */
.text-capitalize {
  text-transform: capitalize !important; }

/* line 36, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-light {
  font-weight: 300 !important; }

/* line 37, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-lighter {
  font-weight: lighter !important; }

/* line 38, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-normal {
  font-weight: 400 !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bold {
  font-weight: 700 !important; }

/* line 40, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bolder {
  font-weight: bolder !important; }

/* line 41, node_modules/bootstrap/scss/utilities/_text.scss */
.font-italic {
  font-style: italic !important; }

/* line 45, node_modules/bootstrap/scss/utilities/_text.scss */
.text-white {
  color: #fff !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-primary {
  color: #007bff !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-secondary {
  color: #6c757d !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-success {
  color: #28a745 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-info {
  color: #17a2b8 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-warning {
  color: #ffc107 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-danger {
  color: #dc3545 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-light {
  color: #f8f9fa !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-dark {
  color: #343a40 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

/* line 51, node_modules/bootstrap/scss/utilities/_text.scss */
.text-body {
  color: #212529 !important; }

/* line 52, node_modules/bootstrap/scss/utilities/_text.scss */
.text-muted {
  color: #6c757d !important; }

/* line 54, node_modules/bootstrap/scss/utilities/_text.scss */
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

/* line 55, node_modules/bootstrap/scss/utilities/_text.scss */
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

/* line 59, node_modules/bootstrap/scss/utilities/_text.scss */
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

/* line 63, node_modules/bootstrap/scss/utilities/_text.scss */
.text-decoration-none {
  text-decoration: none !important; }

/* line 65, node_modules/bootstrap/scss/utilities/_text.scss */
.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

/* line 72, node_modules/bootstrap/scss/utilities/_text.scss */
.text-reset {
  color: inherit !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_visibility.scss */
.visible {
  visibility: visible !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_visibility.scss */
.invisible {
  visibility: hidden !important; }

@media print {
  /* line 13, node_modules/bootstrap/scss/_print.scss */
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  /* line 24, node_modules/bootstrap/scss/_print.scss */
  a:not(.btn) {
    text-decoration: underline; }
  /* line 34, node_modules/bootstrap/scss/_print.scss */
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /* line 49, node_modules/bootstrap/scss/_print.scss */
  pre {
    white-space: pre-wrap !important; }
  /* line 52, node_modules/bootstrap/scss/_print.scss */
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  /* line 63, node_modules/bootstrap/scss/_print.scss */
  thead {
    display: table-header-group; }
  /* line 67, node_modules/bootstrap/scss/_print.scss */
  tr,
  img {
    page-break-inside: avoid; }
  /* line 72, node_modules/bootstrap/scss/_print.scss */
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  /* line 79, node_modules/bootstrap/scss/_print.scss */
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  /* line 92, node_modules/bootstrap/scss/_print.scss */
  body {
    min-width: 992px !important; }
  /* line 95, node_modules/bootstrap/scss/_print.scss */
  .container {
    min-width: 992px !important; }
  /* line 100, node_modules/bootstrap/scss/_print.scss */
  .navbar {
    display: none; }
  /* line 103, node_modules/bootstrap/scss/_print.scss */
  .badge {
    border: 1px solid #000; }
  /* line 107, node_modules/bootstrap/scss/_print.scss */
  .table {
    border-collapse: collapse !important; }
    /* line 110, node_modules/bootstrap/scss/_print.scss */
    .table td,
    .table th {
      background-color: #fff !important; }
  /* line 117, node_modules/bootstrap/scss/_print.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  /* line 123, node_modules/bootstrap/scss/_print.scss */
  .table-dark {
    color: inherit; }
    /* line 126, node_modules/bootstrap/scss/_print.scss */
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  /* line 134, node_modules/bootstrap/scss/_print.scss */
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* breadcrumb styling */
/**
 * animate logo home page
 */
@keyframes zoomIn {
  from {
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0; }
  50% {
    transform: none; } }

/* line 16, src/assets/scss/abstracts/_animations.scss */
.animation-zoomIn {
  animation-name: zoomIn; }

/* line 22, src/assets/scss/abstracts/_animations.scss */
.fade-in-image {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s; }

/* line 31, src/assets/scss/abstracts/_animations.scss */
.rotate-me {
  animation: rotating 1s linear infinite; }

@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  /* line 129, src/assets/scss/abstracts/_animations.scss */
  0% {
    opacity: 0; }
  /* line 132, src/assets/scss/abstracts/_animations.scss */
  100% {
    opacity: 1; } }

/* line 3, src/assets/scss/abstracts/_classes.scss */
.center-image {
  display: block;
  margin: 0 auto; }

/* line 9, src/assets/scss/abstracts/_colors.scss */
:root {
  --theme-text: #2f404f;
  --theme-background: #fff;
  --theme-error: #d32f2f; }

/* breadcrumb styling */
/***********************************
 * Definition
 ***********************************/
/*
Breakpoints and media queries in SCSS Mixin
https://medium.com/codeartisan/breakpoints-and-media-queries-in-scss-46e8f551e2f2

example:
  @include breakpoint(sm) {
    background: tomato;
    padding: 30px;
  }

*/
/*



//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}
//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}


@mixin box-shadow ($shadow: none) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow:    $shadow;
	box-shadow:         $shadow;
}




@mixin linkColor($color) {
     color: $color;
     text-decoration: none;
     background-color: transparent;
     -webkit-text-decoration-skip: objects;

     &:hover, &:active, &:focus {
         color: lighten($color, 20%);
     }
}
 */
/* original flex version 
  @mixin breadcrumb-wrapper ($secondary-color) {
    background:$secondary-color;
    max-width: 800px;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -36px;
    left: -10px;  
  }

*/
/*
Debug Ghost CSS Elements Causing Unwanted Scrolling
http://wernull.com/2013/04/debug-ghost-css-elements-causing-unwanted-scrolling/
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
}

<style>
    * {
        outline: solid 0.25rem hsla(55, 100%, 100%, 0.5);
    }
</style>


// Styles for commonly used HTML elements
*,
*::afer,
*::before {
   margin: 0;
   padding: 0;
   box-sizing: inherit;
}
// Root elements
html,
body {
	//background: var(--theme-background);
	background: rgba(243, 248, 249, 0);
	line-height: 1.7;
	//color: #000;
	padding: 3em;
	box-sizing: border-box;
	
}

html {
	//defines what 1rem is
	font-size: 62.5%;
}


*/
/* line 45, src/assets/scss/base/_base.scss */
html {
  scroll-behavior: smooth;
  min-height: 100vh; }

/**
 * Clear inner floats
 */
/* line 8, src/assets/scss/base/_helpers.scss */
.clearfix::after {
  clear: both;
  content: '';
  display: table; }

/**
   * Hide text while making it readable for screen readers
   * 1. Needed in WebKit-based browsers because of an implementation bug;
   *    See: https://code.google.com/p/chromium/issues/detail?id=457146
   */
/* line 20, src/assets/scss/base/_helpers.scss */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap; }

/**
   * Hide element while making it readable for screen readers
   * Shamelessly borrowed from HTML5Boilerplate:
   * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
   */
/* line 32, src/assets/scss/base/_helpers.scss */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/* line 45, src/assets/scss/base/_helpers.scss */
#filtersubmit-secondary {
  position: relative;
  z-index: 1;
  left: -35px;
  top: 1px;
  color: #442e8b;
  cursor: pointer;
  width: 0;
  background: transparent;
  padding: inherit; }

/* line 58, src/assets/scss/base/_helpers.scss */
.eq-height {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  flex-direction: column; }

/* line 2, src/assets/scss/base/_reset.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* line 24, src/assets/scss/base/_reset.scss */
html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh; }

/* remove horizontal scrollbar that Ashish noticed */
/* line 33, src/assets/scss/base/_reset.scss */
body {
  line-height: 1.7;
  overflow-x: hidden; }

/*
HTML5 display-role reset for older browsers 
*/
/* line 41, src/assets/scss/base/_reset.scss */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

/*
ol, ul {
	list-style: none;
}
*/
/* line 51, src/assets/scss/base/_reset.scss */
blockquote, q {
  quotes: none; }

/* line 54, src/assets/scss/base/_reset.scss */
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

/* line 59, src/assets/scss/base/_reset.scss */
table {
  border-collapse: collapse; }

/* line 2, src/assets/scss/base/_typography.scss */
html,
body {
  font-family: usual, Helvetica, Arial, sans-serif !important; }

/* line 11, src/assets/scss/base/_typography.scss */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: usual, Helvetica, Arial, sans-serif !important;
  font-weight: bold;
  line-height: 1.2;
  color: inherit; }

/* line 21, src/assets/scss/base/_typography.scss */
p {
  font-family: usual, Helvetica, Arial, sans-serif !important;
  margin-top: 0 !important;
  margin-bottom: 1rem !important; }

/* line 28, src/assets/scss/base/_typography.scss */
.intro-body-copy {
  color: #44318B;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5;
  padding-bottom: 1em; }
  @media (min-width: 576px) {
    /* line 28, src/assets/scss/base/_typography.scss */
    .intro-body-copy {
      font-size: 1.25em; } }

/*

.u-center-text { text-align: center !important; }

.u-margin-bottom-small { margin-bottom: 1.5rem !important; }
.u-margin-bottom-medium {
    margin-bottom: 4rem !important;

    @include respond(tab-port) {
        margin-bottom: 3rem !important;
    }
}

.u-margin-bottom-big { 
    margin-bottom: 8rem !important;

    @include respond(tab-port) {
        margin-bottom: 5rem !important;
    }
}

*/
/* line 27, src/assets/scss/base/_utilities.scss */
.u-margin-top-big {
  margin-top: 8rem !important; }

/* line 28, src/assets/scss/base/_utilities.scss */
.u-margin-top-huge {
  margin-top: 10rem !important; }

/* line 31, src/assets/scss/base/_utilities.scss */
.rto-headline {
  font-weight: 300;
  font-size: 1.9rem;
  color: #410099; }
  @media (min-width: 530px) {
    /* line 31, src/assets/scss/base/_utilities.scss */
    .rto-headline {
      font-size: 2.875rem; } }
  /* line 40, src/assets/scss/base/_utilities.scss */
  .rto-headline--centered {
    text-align: center; }
  /* line 44, src/assets/scss/base/_utilities.scss */
  .rto-headline--orange {
    color: #f2a654; }
  /* line 48, src/assets/scss/base/_utilities.scss */
  .rto-headline--small {
    font-size: 1.2rem; }
    @media (min-width: 530px) {
      /* line 48, src/assets/scss/base/_utilities.scss */
      .rto-headline--small {
        font-size: 1.875rem; } }
  /* line 56, src/assets/scss/base/_utilities.scss */
  .rto-headline--narrow {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto; }
  /* line 62, src/assets/scss/base/_utilities.scss */
  .rto-headline--light {
    font-weight: 100; }
  /* line 66, src/assets/scss/base/_utilities.scss */
  .rto-headline strong {
    font-weight: 400; }
  /* line 70, src/assets/scss/base/_utilities.scss */
  .rto-headline--b-margin-small {
    margin-bottom: .5em; }
  /* line 74, src/assets/scss/base/_utilities.scss */
  .rto-headline--b-margin-large {
    margin-bottom: 1.6em; }
  /* line 78, src/assets/scss/base/_utilities.scss */
  .rto-headline--no-t-margin {
    margin-top: 0; }

/*
<hr> page divider rules
*/
/* line 87, src/assets/scss/base/_utilities.scss */
.purple-divider {
  border-top: 1px solid #891c56; }

/* line 91, src/assets/scss/base/_utilities.scss */
.grey-divider {
  border-top: 1px solid #999; }

/* line 95, src/assets/scss/base/_utilities.scss */
.red-divider {
  border-top: 1px solid #ed1c24; }

/* line 100, src/assets/scss/base/_utilities.scss */
.pink-divider {
  border-top: 1px solid #DC1E8A; }

/* line 105, src/assets/scss/base/_utilities.scss */
.generic-page-bullet-points ul {
  list-style-type: disc;
  line-height: 1.8; }
  /* line 109, src/assets/scss/base/_utilities.scss */
  .generic-page-bullet-points ul li {
    margin-left: 1em;
    font-weight: 600; }

/*
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  //border-top: 3px solid rgb(159, 221, 193);
  max-width: 100%;
}
*/
/* 
District badges for Event and news Items 
*/
/* line 130, src/assets/scss/base/_utilities.scss */
.badge-primary {
  background-color: #410099;
  color: #ffffff;
  border-radius: 14px 14px 14px 0;
  display: inline-block;
  font-weight: 200;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  padding: .75em 1.85em;
  font-weight: 400;
  margin-bottom: 10px; }

/* line 134, src/assets/scss/base/_utilities.scss */
.badge-secondary {
  background-color: #DC1E8A;
  color: #ffffff;
  border-radius: 14px 14px 14px 0;
  display: inline-block;
  font-weight: 200;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  padding: .75em 1.85em;
  font-weight: 400;
  margin-bottom: 10px; }

/* line 138, src/assets/scss/base/_utilities.scss */
.badge-tertiary {
  background-color: #ed1c24;
  color: #ffffff;
  border-radius: 14px 14px 14px 0;
  display: inline-block;
  font-weight: 200;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  padding: .75em 1.85em;
  font-weight: 400;
  margin-bottom: 10px; }

/* line 142, src/assets/scss/base/_utilities.scss */
.badge-foundation {
  background-color: #861054;
  color: #ffffff;
  border-radius: 14px 14px 14px 0;
  display: inline-block;
  font-weight: 200;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  padding: .75em 1.85em;
  font-weight: 400;
  margin-bottom: 10px; }

/* line 147, src/assets/scss/base/_utilities.scss */
a.badge-primary.focus, a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

/* line 152, src/assets/scss/base/_utilities.scss */
a.badge-primary:focus, a.badge-primary:hover {
  color: #fff;
  background-color: #2b0066; }

/* line 157, src/assets/scss/base/_utilities.scss */
a.badge-secondary.focus, a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(109, 118, 126, 0.5); }

/* line 162, src/assets/scss/base/_utilities.scss */
a.badge-secondary:focus, a.badge-secondary:hover {
  color: #fff;
  background-color: #af186e; }

/* line 167, src/assets/scss/base/_utilities.scss */
a.badge-tertiary.focus, a.badge-tertiary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(109, 118, 126, 0.5); }

/* line 172, src/assets/scss/base/_utilities.scss */
a.badge-tertiary:focus, a.badge-tertiary:hover {
  color: #fff;
  background-color: #c61017; }

/* line 178, src/assets/scss/base/_utilities.scss */
a.badge-foundation.focus, a.badge-foundation.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(109, 118, 126, 0.5); }

/* line 183, src/assets/scss/base/_utilities.scss */
a.badge-foundation:focus, a.badge-foundation:hover {
  color: #fff;
  background-color: #580b37; }

/* line 189, src/assets/scss/base/_utilities.scss */
.primary-color-blockquote {
  margin-bottom: 1rem;
  font-size: 1em;
  background: #410099;
  color: #ffffff;
  padding: 1em 3em; }
  @media (min-width: 576px) {
    /* line 189, src/assets/scss/base/_utilities.scss */
    .primary-color-blockquote {
      font-size: 1.25em; } }

/* line 204, src/assets/scss/base/_utilities.scss */
.primary-color-blockquote-caption {
  font-size: 1.25rem;
  background: #442e8b;
  color: #fff;
  padding: 1em; }

/* line 212, src/assets/scss/base/_utilities.scss */
.blue-highlight-text {
  color: #410099;
  font-weight: 600; }

/* line 217, src/assets/scss/base/_utilities.scss */
.pink-highlight-text {
  color: #DC1E8A;
  font-weight: 600; }

/* line 222, src/assets/scss/base/_utilities.scss */
.purple-highlight-text {
  color: #891c56;
  font-weight: 600; }

/* line 227, src/assets/scss/base/_utilities.scss */
.mint-highlight-text {
  color: #8fcea5;
  font-weight: 600; }

/* line 233, src/assets/scss/base/_utilities.scss */
.red-highlight-text {
  color: #ed1c24;
  font-weight: 600; }

/* line 239, src/assets/scss/base/_utilities.scss */
.bulleted-list {
  list-style: disc;
  margin-bottom: 1em; }

/* select styling */
/* line 247, src/assets/scss/base/_utilities.scss */
.selectdiv {
  position: relative;
  /*Don't really need this just for demo styling*/
  float: left;
  min-width: 300px; }

/* line 256, src/assets/scss/base/_utilities.scss */
.selectdiv:after {
  content: '\f078';
  font: normal normal normal 21px/1 FontAwesome;
  color: #fbfcfd;
  right: 50px;
  top: 6px;
  height: 48px;
  padding: 14px 11px 0px 11px;
  border-left: 1px solid #777;
  position: absolute;
  pointer-events: none;
  background: #44318B;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px; }

/* IE11 hide native button (thanks Matt!) */
/* line 276, src/assets/scss/base/_utilities.scss */
select::-ms-expand {
  display: none; }

/* line 280, src/assets/scss/base/_utilities.scss */
.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  min-width: 250px;
  height: 50px;
  float: right;
  margin: 5px 0px;
  padding: 0px 24px;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #777;
  -ms-word-break: normal;
  word-break: normal; }

/* line 5, src/assets/scss/components/_accordions.scss */
.accordion {
  /* width: 90%; */
  /* max-width: 1000px; */
  margin: 2rem auto;
  width: 90%; }
  @media (min-width: 576px) {
    /* line 5, src/assets/scss/components/_accordions.scss */
    .accordion {
      text-align: left;
      width: 100%; } }
  /* line 16, src/assets/scss/components/_accordions.scss */
  .accordion ul > li {
    margin-left: 15px;
    line-height: 1.6; }
  /* line 20, src/assets/scss/components/_accordions.scss */
  .accordion h6 {
    margin: 0;
    font-size: 1rem;
    font-weight: 400; }
    @media (min-width: 576px) {
      /* line 20, src/assets/scss/components/_accordions.scss */
      .accordion h6 {
        font-size: 1.25rem;
        margin: .5rem 0; } }

/* line 34, src/assets/scss/components/_accordions.scss */
.accordion-item {
  background-color: #efeeee;
  color: #000;
  margin: 1rem 0;
  border-radius: 25px 25px 25px 1px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  border: none;
  outline: 1px solid transparent;
  -webkit-backface-visibility: hidden; }

/* line 47, src/assets/scss/components/_accordions.scss */
.accordion-item-header.active {
  border: solid 3px #410099;
  border-radius: 25px 25px 25px 1px;
  background: #410099;
  border-radius: 25px 25px 25px 1px;
  color: #fff;
  -webkit-backface-visibility: hidden; }

/* line 57, src/assets/scss/components/_accordions.scss */
.accordion-item-header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer; }
  /* line 67, src/assets/scss/components/_accordions.scss */
  .accordion-item-header:hover {
    background: #4d004d;
    border-radius: 25px 25px 25px 1px;
    color: #fff; }

/* line 74, src/assets/scss/components/_accordions.scss */
.accordion-item-header::after {
  content: "\002B";
  font-size: 30px;
  position: absolute;
  border: 6px solid #44318B;
  color: #fff;
  background: #44318B;
  border-radius: 50%;
  right: 1rem;
  padding: 1px;
  font-weight: 200; }

/* line 88, src/assets/scss/components/_accordions.scss */
.accordion-item-header.active::after {
  content: "\2212";
  padding-top: 0px;
  font-size: 25px; }

/* line 94, src/assets/scss/components/_accordions.scss */
.accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out; }

/* line 99, src/assets/scss/components/_accordions.scss */
.accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem; }

/* line 106, src/assets/scss/components/_accordions.scss */
.accordion-item-body-content.active {
  border: solid 3px purple; }

/* line 1, src/assets/scss/components/_our-team-block.scss */
.our-team {
  background: url(../../assets/img/Our-Team-Banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  background-position: 0% 79%;
  min-height: 30em;
  z-index: -1; }
  /* line 11, src/assets/scss/components/_our-team-block.scss */
  .our-team__main-heading {
    color: #ffffff;
    font-size: 2em;
    font-weight: 600;
    margin: 0em 0 1.25em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 11, src/assets/scss/components/_our-team-block.scss */
      .our-team__main-heading {
        text-align: left; } }
  /* line 25, src/assets/scss/components/_our-team-block.scss */
  .our-team__district-heading {
    color: #fff;
    font-size: 2em;
    font-weight: 600;
    margin: 0 0 .25em; }
  /* line 32, src/assets/scss/components/_our-team-block.scss */
  .our-team__wrapper {
    background-color: #ffffff;
    position: relative;
    z-index: 5;
    border-radius: 6px;
    padding: 55px 19px 55px;
    box-shadow: -3px 3px 10px #414346;
    width: 75%;
    display: block;
    margin: 0 auto; }
    @media (min-width: 576px) {
      /* line 32, src/assets/scss/components/_our-team-block.scss */
      .our-team__wrapper {
        display: flex;
        width: 100%; } }
  /* line 49, src/assets/scss/components/_our-team-block.scss */
  .our-team__wrapper-heading {
    color: #000;
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.25em;
    margin-bottom: 28px; }
  /* line 57, src/assets/scss/components/_our-team-block.scss */
  .our-team .border-right {
    border-right: 0px solid #dee2e6 !important; }
    @media (min-width: 576px) {
      /* line 57, src/assets/scss/components/_our-team-block.scss */
      .our-team .border-right {
        border-right: 1px solid #dee2e6 !important; } }
  /* line 66, src/assets/scss/components/_our-team-block.scss */
  .our-team .form-control {
    background: #fff 0 0 no-repeat padding-box;
    border: 1px solid #442e8b;
    border-radius: 12px;
    opacity: 1;
    width: 333px; }
  /* line 74, src/assets/scss/components/_our-team-block.scss */
  .our-team button {
    position: relative;
    z-index: 1;
    left: -38px;
    top: 1px;
    background-color: #fff;
    cursor: pointer;
    width: none;
    border: none; }
    @media (min-width: 576px) {
      /* line 74, src/assets/scss/components/_our-team-block.scss */
      .our-team button {
        width: 18px; } }
  /* line 93, src/assets/scss/components/_our-team-block.scss */
  .our-team #filtersubmit-district {
    position: relative;
    z-index: 1;
    left: -6px;
    top: -1px;
    color: #442e8b;
    cursor: pointer;
    width: 0;
    background: transparent;
    padding: inherit; }

/* line 1, src/assets/scss/components/_history-block.scss */
.history-content-block {
  /*  

     .main-timeline  .timeline:nth-child(5):before {
      border-radius: 0px 15px 15px 15px;
      background:  $color-primary-dark;
   
   }
     
   .main-timeline  .timeline:nth-child(6):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-primary-light;
   
   }
   
   
   .main-timeline  .timeline:nth-child(7):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-burgundy;
   
   }
   
   
   .main-timeline  .timeline:nth-child(8):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-red;
   
   }


   .main-timeline  .timeline:nth-child(9):before {
      border-radius: 0px 15px 15px 15px;
      background:  $color-primary-dark;
   
   }
     
   .main-timeline  .timeline:nth-child(10):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-primary-light;
   
   }
   
   
   .main-timeline  .timeline:nth-child(11):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-burgundy;
   
   }
   
   
   .main-timeline  .timeline:nth-child(12):before {
      border-radius: 0px 15px 15px 15px;
      background: $color-red;
   
   }
     

   */ }
  /* line 3, src/assets/scss/components/_history-block.scss */
  .history-content-block hr {
    content: "";
    background: #DC1E8A;
    opacity: 0.5; }
  /* line 10, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline {
    position: relative; }
  /* line 14, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline:before {
    content: "";
    width: 3px;
    height: 100%;
    border-radius: 20px;
    margin: 0 auto;
    background: #c1c5bf;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }
  /* line 27, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline {
    display: inline-block;
    margin-bottom: 50px;
    position: relative; }
  /* line 33, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:before {
    content: "";
    width: 26px;
    height: 26px;
    border-radius: 0 15px 15px 15px;
    border: 4px solid #fff;
    background: #44318B;
    position: absolute;
    top: 14%;
    left: 50%;
    z-index: 1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  /* line 49, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(2n):before {
    border-radius: 0px 15px 15px 15px;
    background: #DC1E8A; }
  /* line 56, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(3n):before {
    border-radius: 0px 15px 15px 15px;
    background: #891c56; }
  /* line 63, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(4n):before {
    border-radius: 0px 15px 15px 15px;
    background: #ed1c24; }
  /* line 128, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-icon {
    display: inline-block;
    width: 135px;
    height: 66px;
    border-radius: 50%;
    border: solid 1px #110f90;
    padding: 13px;
    text-align: center;
    position: absolute;
    top: 8%;
    left: 36%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    /* padding: .45em .75em; */
    line-height: 1.1;
    border-radius: 44px 44px 0px 44px;
    display: inline-block;
    font-size: 1em;
    font-weight: 600;
    color: #fff;
    background: #44318B; }
    @media (min-width: 576px) {
      /* line 128, src/assets/scss/components/_history-block.scss */
      .history-content-block .main-timeline .timeline-icon {
        font-size: 1.1em; } }
  /* line 159, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-icon i {
    padding: 0.45em 0.75em;
    line-height: 1.5;
    border-radius: 22px 22px 22px 0;
    display: inline-block;
    font-size: 1em;
    font-weight: 600;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 12%;
    transform: translateY(-50%); }
  /* line 173, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-icon:after,
  .history-content-block .main-timeline .timeline-icon:before {
    width: 100px;
    height: 4px;
    background: #ec496e;
    position: absolute;
    top: 50%;
    right: -100px;
    transform: translateY(-50%); }
  /* line 185, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-icon:after {
    width: 70px;
    height: 50px;
    background: #fff;
    top: 89px;
    right: -30px; }
  /* line 193, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-content {
    width: 50%;
    padding: 0 50px;
    margin: 52px 0 0;
    float: right;
    position: relative; }
  /* line 201, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-content:before {
    width: 70%;
    height: 100%;
    border: 3px solid #ec496e;
    border-top: none;
    border-right: none;
    position: absolute;
    bottom: -13px;
    left: 35px; }
  /* line 213, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-content:after {
    width: 37px;
    height: 3px;
    background: #ec496e;
    position: absolute;
    top: 13px;
    left: 0; }
  /* line 223, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .title {
    font-size: 20px;
    font-weight: 600;
    color: #ec496e;
    text-transform: uppercase;
    margin: 0 0 5px; }
  /* line 231, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .description {
    display: inline-block;
    font-size: 16px;
    color: #404040;
    margin: 0;
    line-height: inherit;
    max-width: 100%; }
    @media (min-width: 576px) {
      /* line 231, src/assets/scss/components/_history-block.scss */
      .history-content-block .main-timeline .description {
        max-width: 70%; } }
  /* line 245, src/assets/scss/components/_history-block.scss */
  .history-content-block .desctiption:after {
    content: "";
    width: 37px;
    height: 3px;
    background: #ec496e;
    position: absolute;
    top: 13px;
    left: 0; }
  /* line 255, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-quote {
    font-size: 15px;
    background: #44318B;
    padding: 19px 45px;
    border-radius: 0px 60px 79px 53px;
    color: #fff;
    margin-top: 20px;
    max-width: 100%; }
    @media (min-width: 576px) {
      /* line 255, src/assets/scss/components/_history-block.scss */
      .history-content-block .main-timeline .timeline-quote {
        max-width: 75%; } }
  /* line 269, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline .timeline-quote-two {
    font-size: 15px;
    background: #891c56;
    padding: 19px 45px;
    border-radius: 0px 60px 79px 53px;
    color: #fff;
    margin-top: 20px;
    max-width: 100%; }
    @media (min-width: 576px) {
      /* line 269, src/assets/scss/components/_history-block.scss */
      .history-content-block .main-timeline .timeline .timeline-quote-two {
        max-width: 75%; } }
  /* line 285, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-image {
    float: right;
    clear: both;
    padding: 15px; }
  /* line 291, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-icon {
    left: auto;
    right: 36%;
    top: -8%; }
  /* line 297, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-icon:before {
    right: auto;
    left: -100px; }
  /* line 302, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-icon:after {
    right: auto;
    left: -30px; }
  /* line 307, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-content {
    float: left; }
  /* line 311, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-content:before {
    left: auto;
    right: 35px;
    transform: rotateY(180deg); }
  /* line 317, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-content:after {
    left: auto;
    right: 0; }
  /* line 329, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(2n) .timeline-icon {
    border-color: #DC1E8A;
    border-radius: 44px 44px 0 44px;
    background: #DC1E8A; }
  /* line 336, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(3n) .timeline-icon {
    border-color: #891c56;
    border-radius: 44px 44px 0 44px;
    background: #891c56; }
  /* line 342, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(4n) .timeline-icon {
    border-radius: 44px 44px 0 44px;
    background: #ed1c24; }
  @media only screen and (max-width: 1200px) {
    /* line 352, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline-icon:before {
      width: 50px;
      right: -50px; }
    /* line 357, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline:nth-child(even) .timeline-icon:before {
      right: auto;
      left: -50px; }
    /* line 362, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline-content {
      margin-top: 75px; } }
  @media only screen and (max-width: 990px) {
    /* line 368, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline {
      margin: 0 0 10px; }
    /* line 372, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline-icon {
      left: 25%; }
    /* line 376, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline:nth-child(even) .timeline-icon {
      right: 25%; }
    /* line 380, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline-content {
      margin-top: 115px; } }
  /* line 386, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline {
    padding-top: 50px; }
  /* line 390, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline:before {
    left: 80px;
    right: 0;
    margin: 0; }
  /* line 396, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline {
    margin-bottom: 70px; }
  /* line 400, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:before {
    top: 23px;
    left: 86px;
    right: 0;
    margin: 0; }
  /* line 407, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-icon {
    width: 82px;
    height: 50px;
    line-height: 40px;
    padding: 5px;
    top: 0;
    left: 0;
    margin: -15px 0px 0px -8px; }
  /* line 417, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-icon {
    left: 0;
    right: auto;
    margin: -3px 0px 0px -8px; }
  /* line 423, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-icon:before,
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-icon:before {
    width: 25px;
    left: auto;
    right: -25px; }
  /* line 430, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-icon:after,
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-icon:after {
    width: 25px;
    height: 30px;
    top: 44px;
    left: auto;
    right: -5px; }
  /* line 439, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-icon i {
    font-size: 30px;
    line-height: 45px; }
  /* line 444, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-content,
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-content {
    width: 100%;
    margin-top: -15px;
    padding-left: 130px;
    padding-right: 5px; }
  /* line 452, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-content {
    float: right; }
  /* line 456, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-content:before,
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-content:before {
    width: 50%;
    left: 120px; }
  /* line 462, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-content:before {
    right: auto;
    transform: rotateY(0); }
  /* line 467, src/assets/scss/components/_history-block.scss */
  .history-content-block .main-timeline .timeline-content:after,
  .history-content-block .main-timeline .timeline:nth-child(even) .timeline-content:after {
    left: 85px; }
  @media only screen and (max-width: 479px) {
    /* line 474, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline-content,
    .history-content-block .main-timeline .timeline:nth-child(2n) .timeline-content {
      padding-left: 110px; }
    /* line 479, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline-content:before,
    .history-content-block .main-timeline .timeline:nth-child(2n) .timeline-content:before {
      left: 99px; }
    /* line 484, src/assets/scss/components/_history-block.scss */
    .history-content-block .main-timeline .timeline-content:after,
    .history-content-block .main-timeline .timeline:nth-child(2n) .timeline-content:after {
      left: 65px; } }

/* line 1, src/assets/scss/components/_join-now-form-block.scss */
.join-now-form-block {
  background: #DC1E8A;
  min-height: 30em; }
  @media (min-width: 768px) {
    /* line 1, src/assets/scss/components/_join-now-form-block.scss */
    .join-now-form-block {
      min-height: none; } }

/* line 10, src/assets/scss/components/_join-now-form-block.scss */
.join-now-form-block .row, .join-now-form-block .row-dark {
  height: 24rem; }

/* line 13, src/assets/scss/components/_join-now-form-block.scss */
.join-now-form-block .content h2 {
  font-size: 2.5em;
  font-weight: 600;
  margin: 0 0 .75em; }

/* line 18, src/assets/scss/components/_join-now-form-block.scss */
.join-now-form-block .content h3 {
  font-size: 1.75rem;
  line-height: 2rem;
  max-width: 30em;
  font-weight: 500;
  margin-bottom: 2em; }

/* line 25, src/assets/scss/components/_join-now-form-block.scss */
.join-now-form-block .content p {
  font-size: 1.25rem;
  margin-bottom: 1em;
  margin-top: 1em;
  display: block;
  padding-top: 1em; }
  /* line 32, src/assets/scss/components/_join-now-form-block.scss */
  .join-now-form-block .content p a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: bold; }

/* line 2, src/assets/scss/components/_need-help-block.scss */
.need-help-block {
  background: #43318b;
  height: 30em;
  margin-top: 2em;
  width: 100%;
  min-height: 325px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  width: 100%;
  min-height: 221px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    /* line 2, src/assets/scss/components/_need-help-block.scss */
    .need-help-block {
      background: radial-gradient(65% 387% at 8% 11%, #DC1E8A 0, #DC1E8A 50%, #DC1E8A calc(19% + 1px), #DC1E8A 23%, #43318b calc(50% + 1px), #43318b 39%, #43318b calc(50% + 1px), #43318b 100%);
      height: auto; } }
  /* line 30, src/assets/scss/components/_need-help-block.scss */
  .need-help-block__title {
    flex-grow: 3;
    text-align: center; }
    /* line 34, src/assets/scss/components/_need-help-block.scss */
    .need-help-block__title h2, .need-help-block__title p {
      color: #ffffff; }
    /* line 38, src/assets/scss/components/_need-help-block.scss */
    .need-help-block__title h2 {
      font-size: 2.25em; }
    /* line 42, src/assets/scss/components/_need-help-block.scss */
    .need-help-block__title p {
      font-size: 1.25em;
      margin-top: 5px; }
    @media (min-width: 576px) {
      /* line 30, src/assets/scss/components/_need-help-block.scss */
      .need-help-block__title {
        text-align: left; } }
  /* line 54, src/assets/scss/components/_need-help-block.scss */
  .need-help-block__contact1 {
    flex-grow: 1;
    text-align: center;
    margin: 1em 0em 0em; }
    @media (min-width: 576px) {
      /* line 54, src/assets/scss/components/_need-help-block.scss */
      .need-help-block__contact1 {
        text-align: left;
        max-width: 307px;
        margin-right: 30px;
        margin: 0; } }
  /* line 68, src/assets/scss/components/_need-help-block.scss */
  .need-help-block__contact2 {
    flex-grow: 1;
    text-align: center;
    margin: 1em 0em 0em; }
    @media (min-width: 576px) {
      /* line 68, src/assets/scss/components/_need-help-block.scss */
      .need-help-block__contact2 {
        text-align: left;
        max-width: 307px;
        margin-right: 30px;
        margin: 0; } }

/* line 1, src/assets/scss/components/_join-us-content-block.scss */
.join-us-content-block {
  margin: 3em 0; }
  /* line 4, src/assets/scss/components/_join-us-content-block.scss */
  .join-us-content-block__heading {
    color: #000;
    font-size: 1.75em;
    margin-bottom: 1em;
    font-weight: 600;
    max-width: 75%;
    text-align: left; }
  /* line 14, src/assets/scss/components/_join-us-content-block.scss */
  .join-us-content-block__copy {
    /*font-weight: 600; */
    line-height: 1.5;
    margin-bottom: 1em; }
  /* line 21, src/assets/scss/components/_join-us-content-block.scss */
  .join-us-content-block ul > li {
    list-style-type: disc;
    line-height: 1.8;
    margin-left: 13px; }
  /* line 27, src/assets/scss/components/_join-us-content-block.scss */
  .join-us-content-block::after {
    height: 2px;
    display: block;
    width: 75%;
    background: #44318b;
    border-right: 1px #fff;
    content: "";
    margin: 3em auto; }

/* line 43, src/assets/scss/components/_join-us-content-block.scss */
.join-us-content-block-testimonial h2 {
  color: #410099;
  font-size: 2rem;
  line-height: 110%;
  font-weight: 700;
  position: relative;
  margin-bottom: 1rem !important;
  color: #410099; }

/* line 49, src/assets/scss/components/_join-us-content-block.scss */
.join-us-content-block-testimonial__body {
  /* max-width: 85%; */
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: 900;
  color: #000; }
  /* line 56, src/assets/scss/components/_join-us-content-block.scss */
  .join-us-content-block-testimonial__body span {
    display: block;
    margin-top: 1em;
    color: #410099; }

/* line 64, src/assets/scss/components/_join-us-content-block.scss */
.join-us-content-block-testimonial__box {
  background: #8fcea5;
  width: auto;
  padding: 1em 1em;
  border-radius: 140px 154px 154px 0;
  max-width: 33em;
  margin-left: 0; }
  @media (min-width: 768px) {
    /* line 64, src/assets/scss/components/_join-us-content-block.scss */
    .join-us-content-block-testimonial__box {
      margin-left: 5em;
      padding: 6em 3em; } }

@media only screen and (max-width: 800px) {
  /* line 82, src/assets/scss/components/_join-us-content-block.scss */
  .join-us-content-block-testimonial {
    background: #8fcea5;
    width: 100%;
    border-radius: 0px;
    /* max-width: 0; */
    margin-left: 0; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 94, src/assets/scss/components/_join-us-content-block.scss */
  .join-us-content-block-testimonial {
    background: #8fcea5;
    width: 100%;
    border-radius: 0;
    margin-left: -2em;
    border-radius: 140px 154px 154px 0; } }

/* line 1, src/assets/scss/components/_become-member-block.scss */
.become-member-block {
  background: #DC1E8A; }

/* line 4, src/assets/scss/components/_become-member-block.scss */
.become-member-block .row, .become-member-block .row-dark {
  height: 24rem; }

/* line 7, src/assets/scss/components/_become-member-block.scss */
.become-member-block .content h2 {
  font-size: 2em;
  font-weight: 600;
  margin: 0 0 .75em; }

/* line 12, src/assets/scss/components/_become-member-block.scss */
.become-member-block .content p {
  font-size: 1.2rem;
  line-height: 2rem;
  max-width: 30em;
  margin-bottom: 2em; }

/* line 4, src/assets/scss/components/_brand-resources.scss */
.brand-guidelines-card-block {
  padding: 4em 0 2em; }
  /* line 7, src/assets/scss/components/_brand-resources.scss */
  .brand-guidelines-card-block .card .card-body {
    padding: 19px 10px; }
  /* line 11, src/assets/scss/components/_brand-resources.scss */
  .brand-guidelines-card-block .card > img {
    max-width: 100%;
    height: 14em; }
    @media (min-width: 576px) {
      /* line 11, src/assets/scss/components/_brand-resources.scss */
      .brand-guidelines-card-block .card > img {
        max-width: 80%; } }

/* line 22, src/assets/scss/components/_brand-resources.scss */
#brand-guidelines {
  width: 200px;
  position: relative;
  left: 0; }

/* line 28, src/assets/scss/components/_brand-resources.scss */
.brand-guidelines-block {
  background: #44318B;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  background-position: 0% 79%;
  z-index: 1; }
  /* line 40, src/assets/scss/components/_brand-resources.scss */
  .brand-guidelines-block__main-heading {
    color: #ffffff;
    font-size: 2em;
    font-weight: 600;
    margin: 2em 0 1.25em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 40, src/assets/scss/components/_brand-resources.scss */
      .brand-guidelines-block__main-heading {
        text-align: left;
        margin: 0 0 1.25em; } }
  /* line 55, src/assets/scss/components/_brand-resources.scss */
  .brand-guidelines-block__wrapper {
    background-color: #ffffff;
    position: relative;
    z-index: 5;
    border-radius: 6px;
    padding: 55px 19px 55px;
    box-shadow: -3px 3px 10px #414346;
    width: 100%;
    display: block;
    margin: 0 auto; }
    @media (min-width: 576px) {
      /* line 55, src/assets/scss/components/_brand-resources.scss */
      .brand-guidelines-block__wrapper {
        display: flex;
        width: 90%; } }
  /* line 73, src/assets/scss/components/_brand-resources.scss */
  .brand-guidelines-block__wrapper-sub-heading {
    color: #000;
    margin-top: 10px;
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 28px; }
  /* line 81, src/assets/scss/components/_brand-resources.scss */
  .brand-guidelines-block .border-right {
    border-right: 0px solid #dee2e6 !important; }
    @media (min-width: 576px) {
      /* line 81, src/assets/scss/components/_brand-resources.scss */
      .brand-guidelines-block .border-right {
        border-right: 1px solid #dee2e6 !important; } }

/* line 94, src/assets/scss/components/_brand-resources.scss */
.brand-resources-video-block {
  background: #efeeee;
  padding: 4em 0; }
  /* line 98, src/assets/scss/components/_brand-resources.scss */
  .brand-resources-video-block__heading {
    color: #44318B;
    font-size: 2.25em;
    text-align: center;
    margin-bottom: 1em;
    font-weight: 600; }

/* line 109, src/assets/scss/components/_brand-resources.scss */
.district-logos-block {
  background: #ffffff;
  padding: 4em 0;
  /* Label styles: style as needed */
  /* Container used for styling the custom select, the buttom class adds the bg gradient, corners, etc. */
  /* This is the native select, we're making everything the text invisible so we can see the button styles in the wrapper */
  /* Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select */
  /* This hides native dropdown button arrow in IE 10/11+ so it will have the custom appearance, IE 9 and earlier get a native select */
  /* Firefox >= 2 -- Older versions of FF (v2 - 6) won't let us hide the native select arrow, so we'll just hide the custom icon and go with native styling */
  /* Show only the native arrow */
  /* reduce padding */
  /* Firefox 7+ -- Will let us hide the arrow, but inconsistently (see FF 30 comment below). We've found the simplest way to hide the native styling in FF is to make the select bigger than its container. */
  /* The specific FF selector used below successfully overrides the previous rule that turns off the custom icon; other FF hacky selectors we tried, like `*>.dropdown::after`, did not undo the previous rule */
  /* Set overflow:hidden on the wrapper to clip the native select's arrow, this clips hte outline too so focus styles are less than ideal in FF */
  /* Show only the custom icon */
  /* At first we tried the following rule to hide the native select arrow in Firefox 30+ in Windows 8, but we'd rather simplify the CSS and widen the select for all versions of FF since this is a recurring issue in that browser */
  /* @supports (-moz-appearance:meterbar) and (background-blend-mode:difference,normal) {
    .dropdown select { width:110%; }
    }   */
  /* Firefox 7+ focus style - This works around the issue that -moz-appearance: window kills the normal select focus. Using semi-opaque because outline doesn't handle rounded corners */
  /* Opera - Pre-Blink nix the custom arrow, go with a native select button */
  /* Hover style */
  /* Focus style */
  /* Firefox focus has odd artifacts around the text, this kills that */
  /* These are just demo button-y styles, style as you like */ }
  /* line 112, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block__heading {
    color: #DC1E8A;
    font-size: 2em;
    font-weight: 600;
    margin: 2em 0 1.25em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 112, src/assets/scss/components/_brand-resources.scss */
      .district-logos-block__heading {
        margin: 0 0 1.25em; } }
  /* line 124, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .logos {
    padding: 3em 1em;
    background: #ef8ac4;
    width: 100%; }
    /* line 129, src/assets/scss/components/_brand-resources.scss */
    .district-logos-block .logos a {
      color: #442E8B;
      font-weight: 600;
      font-size: 1.25rem; }
    /* line 135, src/assets/scss/components/_brand-resources.scss */
    .district-logos-block .logos ul {
      list-style-type: none;
      font-size: 1.25em;
      font-weight: bold; }
    /* line 141, src/assets/scss/components/_brand-resources.scss */
    .district-logos-block .logos li {
      margin-bottom: 1em; }
      /* line 144, src/assets/scss/components/_brand-resources.scss */
      .district-logos-block .logos li a {
        color: #442E8B; }
  /* line 153, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block #top-copy {
    list-style-type: disc;
    padding-left: 2em; }
  /* line 158, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .tab-content li {
    list-style-type: disc;
    margin: 0 2em 0; }
  /* line 163, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .tab-content a {
    color: #f9f9f9; }
  /* line 168, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .page-content ul {
    list-style: none;
    padding-left: 0; }
  /* line 175, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block label {
    display: block;
    margin: 2em 1em 0.25em 0.75em;
    font-size: 1.25em;
    color: #333; }
  /* line 183, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .dropdown {
    position: relative;
    display: block;
    margin-top: 0.5em;
    padding: 0; }
  /* line 191, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .dropdown select {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    margin: 0;
    background: none;
    border: 1px solid transparent;
    outline: none;
    box-sizing: border-box;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    font-size: 1.25em;
    /* font-weight: bold; */
    color: #e51b93;
    padding: 0.6em 1.9em 0.5em 0.8em;
    line-height: 1.3;
    font-weight: 800;
    text-transform: uppercase; }
  /* line 210, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .dropdown select,
  .district-logos-block label {
    font-family: inherit; }
  @media screen and (max-width: 868px) {
    /* line 216, src/assets/scss/components/_brand-resources.scss */
    .district-logos-block .dropdown select {
      font-size: 1em;
      font-weight: 600; } }
  /* line 224, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .dropdown::after {
    content: "";
    position: absolute;
    width: 9px;
    height: 8px;
    top: 50%;
    right: 1em;
    margin-top: -4px;
    z-index: 2;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 12'%3E%3Cpolygon fill='rgb(102,102,102)' points='8,12 0,0 16,0'/%3E%3C/svg%3E") 0 0 no-repeat;
    /* These hacks make the select behind the arrow clickable in some browsers */
    pointer-events: none; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* line 109, src/assets/scss/components/_brand-resources.scss */
    .district-logos-block {
      /* Removes the odd blue bg color behind the text in IE 10/11 and sets the text to match the focus style text */ }
      /* line 241, src/assets/scss/components/_brand-resources.scss */
      .district-logos-block .dropdown select::-ms-expand {
        display: none; }
      /* line 245, src/assets/scss/components/_brand-resources.scss */
      .district-logos-block select:focus::-ms-value {
        background: transparent;
        color: #222; } }
  /* line 253, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block body:last-child .dropdown::after,
  .district-logos-block x:-moz-any-link {
    display: none; }
  /* line 258, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block body:last-child .dropdown select,
  .district-logos-block x:-moz-any-link {
    padding-right: 0.8em; }
  /* line 267, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block _::-moz-progress-bar,
  .district-logos-block body:last-child .dropdown {
    overflow: hidden; }
  /* line 272, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block _::-moz-progress-bar,
  .district-logos-block body:last-child .dropdown:after {
    display: block; }
  /* line 276, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block _::-moz-progress-bar,
  .district-logos-block body:last-child .dropdown select {
    /* increase padding to make room for menu icon */
    padding-right: 1.9em;
    /* `window` appearance with these text-indent and text-overflow values will hide the arrow FF up to v30 */
    -moz-appearance: window;
    text-indent: 0.01px;
    text-overflow: "";
    /* for FF 30+ on Windows 8, we need to make the select a bit longer to hide the native arrow */
    width: 110%; }
  /* line 294, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block _::-moz-progress-bar,
  .district-logos-block body:last-child .dropdown select:focus {
    outline: 2px solid rgba(180, 222, 250, 0.7); }
  /* line 300, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block x:-o-prefocus,
  .district-logos-block .dropdown::after {
    display: none; }
  /* line 306, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .dropdown:hover {
    border: 1px solid #888; }
  /* line 311, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block select:focus {
    outline: none;
    box-shadow: 0 0 1px 3px #b4defa;
    background-color: transparent;
    color: #222;
    border: 1px solid #aaa; }
  /* line 320, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  /* line 325, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block option {
    font-weight: normal; }
  /* line 330, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .button {
    border: 1px solid #bbb;
    border-radius: 0.3em;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    background: #f3f3f3;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #e5e5e5));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    /* W3C */ }
  /* line 357, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .output {
    margin: 0 auto;
    /* padding: 1em; */ }
  /* line 361, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .colors {
    padding: 2em;
    color: #fff;
    display: none; }
  /* line 366, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .red {
    background: #c04; }
  /* line 369, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .yellow {
    color: #000;
    background: #f5e000; }
  /* line 373, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block .blue {
    background: #e4bde1; }
  /* line 377, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block a {
    color: #fff;
    text-decoration: none; }
  /* line 382, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block a:hover {
    color: #1f153e;
    text-decoration: underline; }
  /* line 387, src/assets/scss/components/_brand-resources.scss */
  .district-logos-block li {
    list-style-type: none;
    text-align: left;
    font-size: 2em; }
  @media screen and (max-width: 868px) {
    /* line 394, src/assets/scss/components/_brand-resources.scss */
    .district-logos-block li {
      font-size: 1.1em; } }

/* line 2, src/assets/scss/components/_workshop-search-block.scss */
.workshop-search-block {
  background: #efeeee;
  min-height: 10em;
  padding: 3em 0; }
  /* line 7, src/assets/scss/components/_workshop-search-block.scss */
  .workshop-search-block .form-control {
    background: #fff 0 0 no-repeat padding-box;
    border: 1px solid #a59f9f;
    /* border-radius: 12px; */
    opacity: 1;
    width: auto;
    min-width: 243px;
    border-radius: 25px; }
    @media (min-width: 992px) {
      /* line 7, src/assets/scss/components/_workshop-search-block.scss */
      .workshop-search-block .form-control {
        width: 400px; } }
  /* line 23, src/assets/scss/components/_workshop-search-block.scss */
  .workshop-search-block button {
    position: relative;
    z-index: 1;
    left: -38px;
    top: 1px;
    background-color: transparent;
    cursor: pointer;
    width: none;
    border: none; }
  /* line 34, src/assets/scss/components/_workshop-search-block.scss */
  .workshop-search-block #filtersubmit-rpw {
    position: relative;
    z-index: 1;
    left: -6px;
    top: -1px;
    color: #442e8b;
    cursor: pointer;
    width: 0; }

/* line 1, src/assets/scss/components/_rpw-search-results-block.scss */
.rpw-search-results-block {
  min-height: 75vh;
  padding: 2em 0em;
  margin-top: 2em; }
  /* line 7, src/assets/scss/components/_rpw-search-results-block.scss */
  .rpw-search-results-block__wrapper {
    padding: 1em 0 0; }
  /* line 12, src/assets/scss/components/_rpw-search-results-block.scss */
  .rpw-search-results-block__main-title {
    color: #000;
    font-size: 1.75em;
    text-align: center;
    margin-bottom: 1em;
    font-weight: 600;
    margin-left: 14px; }
    @media (min-width: 576px) {
      /* line 12, src/assets/scss/components/_rpw-search-results-block.scss */
      .rpw-search-results-block__main-title {
        text-align: left; } }
  /* line 26, src/assets/scss/components/_rpw-search-results-block.scss */
  .rpw-search-results-block__post-title {
    color: #000;
    font-size: 1.25em;
    text-align: left;
    letter-spacing: .2px;
    margin: 8px 0;
    font-weight: 800; }
  /* line 35, src/assets/scss/components/_rpw-search-results-block.scss */
  .rpw-search-results-block__post-meta-title {
    color: #44318B;
    font-size: 1em;
    text-align: left;
    margin: 0 0 1em;
    font-weight: 700;
    letter-spacing: 0.1px; }
  /* line 44, src/assets/scss/components/_rpw-search-results-block.scss */
  .rpw-search-results-block__post-content {
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 2em; }
  /* line 51, src/assets/scss/components/_rpw-search-results-block.scss */
  .rpw-search-results-block__post-link {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    color: #DC1E8A; }
  /* line 61, src/assets/scss/components/_rpw-search-results-block.scss */
  .rpw-search-results-block__post-link::after {
    height: 1px;
    display: block;
    width: 100%;
    background: #000;
    opacity: .5;
    border-right: 1px white;
    content: '';
    margin: 2em 0 1em; }

/* line 1, src/assets/scss/components/_rpw-single-page-block.scss */
.rpw-single-page-block {
  min-height: 75vh;
  padding: 2em;
  margin: 2em 0 7em; }
  /* line 6, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__wrapper {
    padding: 1em 0 0; }
  /* line 10, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__main-title {
    color: #000;
    font-size: 1.75em;
    text-align: center;
    margin-bottom: 1em;
    font-weight: 600; }
    @media (min-width: 576px) {
      /* line 10, src/assets/scss/components/_rpw-single-page-block.scss */
      .rpw-single-page-block__main-title {
        text-align: left; } }
  /* line 23, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__post-meta-title {
    color: #44318B;
    font-size: 1em;
    text-align: left;
    margin: 0 0 1em;
    font-weight: 700;
    letter-spacing: 0.1px; }
  /* line 32, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__post-content {
    font-weight: normal;
    line-height: 1.5; }
  /* line 37, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__event-information {
    color: #000;
    font-size: 1em;
    text-align: left;
    letter-spacing: .2px;
    margin: 12px 0 23px;
    font-weight: normal;
    word-spacing: 2px; }
    /* line 46, src/assets/scss/components/_rpw-single-page-block.scss */
    .rpw-single-page-block__event-information span {
      color: #44318B; }
  /* line 52, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__post-link {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    color: #DC1E8A; }
  /* line 62, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__post-link::after {
    height: 1px;
    display: block;
    width: 100%;
    background: #000;
    opacity: .5;
    border-right: 1px white;
    content: '';
    margin: 2em 0 1em; }
  /* line 74, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block button {
    margin: 1.5em 0 1em; }
  /* line 79, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__related-rpw-title {
    color: #000;
    font-size: 1.25em;
    text-align: left;
    margin: 0 0 2em;
    font-weight: 600;
    letter-spacing: 0.1px; }
  /* line 89, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__related-rpw-name {
    color: #000;
    font-size: 1em;
    text-align: left;
    margin: 0 0 0.5em;
    font-weight: 200;
    letter-spacing: 0.1px; }
  /* line 98, src/assets/scss/components/_rpw-single-page-block.scss */
  .rpw-single-page-block__related-rpw-description {
    font-weight: normal;
    color: #44318B;
    margin-bottom: 2em; }

/* line 1, src/assets/scss/components/_district-whats-new-block.scss */
.district-whats-new-block {
  margin: 3em 0;
  position: relative;
  bottom: 0;
  right: 0; }
  /* line 7, src/assets/scss/components/_district-whats-new-block.scss */
  .district-whats-new-block__main-heading {
    color: #000;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important; }
  /* line 11, src/assets/scss/components/_district-whats-new-block.scss */
  .district-whats-new-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 18, src/assets/scss/components/_district-whats-new-block.scss */
  .district-whats-new-block hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #9fddc1;
    max-width: 75%; }
  /* line 27, src/assets/scss/components/_district-whats-new-block.scss */
  .district-whats-new-block .card .card-category {
    margin: 0 0 10px; }
  /* line 31, src/assets/scss/components/_district-whats-new-block.scss */
  .district-whats-new-block .card .card-body {
    padding: 18px 8px; }
  /* line 35, src/assets/scss/components/_district-whats-new-block.scss */
  .district-whats-new-block .card .card-body .card-title {
    font-size: 1em;
    margin-bottom: 7px;
    color: #000;
    font-weight: 800; }
  /* line 42, src/assets/scss/components/_district-whats-new-block.scss */
  .district-whats-new-block .card .card-body .card-meta {
    font-size: .75rem;
    margin-bottom: 5px;
    color: #ed1c24;
    font-weight: 600; }
  /* line 49, src/assets/scss/components/_district-whats-new-block.scss */
  .district-whats-new-block .card .card-text {
    margin-top: 2em;
    line-height: 1.5;
    font-weight: 600; }

/* line 1, src/assets/scss/components/_district-events-block.scss */
.district-events-block {
  margin: 3em 0;
  position: relative;
  bottom: 0;
  right: 0; }
  /* line 7, src/assets/scss/components/_district-events-block.scss */
  .district-events-block__main-heading {
    color: #000;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important; }
  /* line 11, src/assets/scss/components/_district-events-block.scss */
  .district-events-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 22, src/assets/scss/components/_district-events-block.scss */
  .district-events-block .card .card-body {
    padding: 10px 0px; }
  /* line 26, src/assets/scss/components/_district-events-block.scss */
  .district-events-block .card .card-body .card-title {
    font-size: 1em;
    margin-bottom: 7px;
    color: #000;
    font-weight: 800;
    max-width: 75%; }
  /* line 34, src/assets/scss/components/_district-events-block.scss */
  .district-events-block .card .card-body .card-meta {
    font-size: .75rem;
    margin-bottom: 5px;
    color: #ed1c24;
    font-weight: 600; }
  /* line 41, src/assets/scss/components/_district-events-block.scss */
  .district-events-block .card .card-text {
    margin-top: 2em;
    line-height: 1.5;
    font-weight: 600; }

/* line 1, src/assets/scss/components/_events-calendar-list.scss */
.events-calendar-list {
  padding: 2em 0; }
  /* line 4, src/assets/scss/components/_events-calendar-list.scss */
  .events-calendar-list__main-heading {
    color: #000;
    font-size: 1.75em;
    text-align: center;
    margin-bottom: .5em;
    font-weight: 600; }
  /* line 14, src/assets/scss/components/_events-calendar-list.scss */
  .events-calendar-list__event-listing-text {
    color: #000;
    font-size: 1em;
    margin-bottom: .5em;
    font-weight: 600; }
  /* line 22, src/assets/scss/components/_events-calendar-list.scss */
  .events-calendar-list__image-icon {
    margin-right: 10px; }
  /* line 26, src/assets/scss/components/_events-calendar-list.scss */
  .events-calendar-list__wrapper {
    padding: 2em 0 0; }
  /* line 31, src/assets/scss/components/_events-calendar-list.scss */
  .events-calendar-list__post-meta-title {
    color: #000;
    font-size: 1em;
    text-align: left;
    margin: 0 0 1em;
    font-weight: 700;
    letter-spacing: .1px; }
    @media (min-width: 576px) {
      /* line 31, src/assets/scss/components/_events-calendar-list.scss */
      .events-calendar-list__post-meta-title {
        font-size: 1.25em; } }
  /* line 46, src/assets/scss/components/_events-calendar-list.scss */
  .events-calendar-list__post-title {
    font-size: .75em;
    text-align: left;
    letter-spacing: .2px;
    margin: 8px 0;
    font-weight: 800; }
    @media (min-width: 576px) {
      /* line 46, src/assets/scss/components/_events-calendar-list.scss */
      .events-calendar-list__post-title {
        font-size: 1em; } }
  /* line 60, src/assets/scss/components/_events-calendar-list.scss */
  .events-calendar-list__post-link {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    color: #e93a97; }
  /* line 70, src/assets/scss/components/_events-calendar-list.scss */
  .events-calendar-list__post-link:after {
    height: 1px;
    display: block;
    width: 100%;
    background: #000;
    opacity: .5;
    border-right: 1px #fff;
    content: "";
    margin: 2em 0 1em; }

/* line 1, src/assets/scss/components/_district-foundation-classifieds-block.scss */
.district-foundation-classifieds-block {
  margin: 3em 0;
  position: relative;
  bottom: 0;
  right: 0; }
  /* line 7, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block__main-heading {
    color: #000;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important; }
  /* line 12, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block .card-img-top {
    width: 80%;
    /* border-top-left-radius: calc(.25rem - 1px); */
    /* border-top-right-radius: calc(.25rem - 1px); */ }
  /* line 18, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block .card .card-body {
    padding: 10px 0px; }
  /* line 22, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block .card .card-body .card-title {
    font-size: 1.5em;
    margin-bottom: 7px;
    color: #ed1c24;
    font-weight: 400;
    width: 80%; }
  /* line 30, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block .card .card-text {
    margin-top: 1em;
    line-height: 1.5;
    font-weight: 600;
    color: #000;
    width: 80%; }
  /* line 38, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block .district-title {
    color: #ed1c24;
    font-weight: bold; }
  /* line 44, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block__wrapper {
    padding: 1em 0 0; }
  /* line 49, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block__post-title {
    color: #000;
    font-size: 1.25em;
    text-align: left;
    margin: 30px 0 4px;
    font-weight: 800;
    max-width: 80%; }
  /* line 59, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block__post-meta-title {
    color: #ed1c24;
    font-size: 1em;
    text-align: left;
    margin: 0 0 1em;
    font-weight: 700;
    letter-spacing: 0.1px; }
  /* line 69, src/assets/scss/components/_district-foundation-classifieds-block.scss */
  .district-foundation-classifieds-block__post-meta-title::after {
    height: 1px;
    display: block;
    width: 100%;
    background: #bfbfbf;
    border-right: 1px #fff;
    content: "";
    margin: 2em auto; }

/* line 1, src/assets/scss/components/_blog-posts.scss */
.blog-posts-block {
  margin: 3em 0;
  position: relative;
  bottom: 0;
  right: 0; }
  /* line 7, src/assets/scss/components/_blog-posts.scss */
  .blog-posts-block__main-heading {
    color: #410099;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important; }
  /* line 11, src/assets/scss/components/_blog-posts.scss */
  .blog-posts-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 18, src/assets/scss/components/_blog-posts.scss */
  .blog-posts-block hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #9fddc1;
    max-width: 75%; }
  /* line 27, src/assets/scss/components/_blog-posts.scss */
  .blog-posts-block .card .card-body {
    padding: 18px 8px; }
  /* line 31, src/assets/scss/components/_blog-posts.scss */
  .blog-posts-block .card .card-body .card-title {
    font-size: 1.25em;
    margin-bottom: 10px;
    color: #000;
    font-weight: 800; }
  /* line 38, src/assets/scss/components/_blog-posts.scss */
  .blog-posts-block .card .card-body .card-meta {
    font-size: .75rem;
    margin: 5px 0 15px;
    color: #410099;
    font-weight: 600; }
  /* line 45, src/assets/scss/components/_blog-posts.scss */
  .blog-posts-block .card .card-text {
    margin-top: 1em;
    line-height: 1.5;
    font-weight: 400; }

/* line 1, src/assets/scss/components/_post-ad-form-block.scss */
.post-ad-category-block {
  /* background: #efeeee; */
  /* min-height: 10em; */
  padding: 1em 0; }
  /* line 6, src/assets/scss/components/_post-ad-form-block.scss */
  .post-ad-category-block .search-links a {
    color: #e93a97;
    font-weight: 800;
    text-decoration: underline; }

/* line 17, src/assets/scss/components/_post-ad-form-block.scss */
.post-ad-form-block {
  background: #ffffff; }
  /* line 20, src/assets/scss/components/_post-ad-form-block.scss */
  .post-ad-form-block__main-heading {
    color: #000;
    font-size: 2em;
    font-weight: 600;
    margin: 0 0 10px;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 20, src/assets/scss/components/_post-ad-form-block.scss */
      .post-ad-form-block__main-heading {
        text-align: left; } }
  /* line 34, src/assets/scss/components/_post-ad-form-block.scss */
  .post-ad-form-block__form {
    background-color: #efeeee;
    padding: 3rem 3.25rem;
    box-shadow: 1px 3px 3px #cdbcbc;
    margin: 2em 0 5em; }
    /* line 40, src/assets/scss/components/_post-ad-form-block.scss */
    .post-ad-form-block__form .form-control, .post-ad-form-block__form #End-Date {
      display: block;
      width: 100%;
      padding: .35rem .95rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-image: none;
      background-clip: padding-box;
      border-radius: 0;
      margin-bottom: 25px;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

/* line 1, src/assets/scss/components/_grant-posts.scss */
.grant-posts-block {
  margin: 3em 0;
  position: relative;
  bottom: 0;
  right: 0; }
  /* line 7, src/assets/scss/components/_grant-posts.scss */
  .grant-posts-block__main-heading {
    color: #891c56;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important;
    display: block; }
  /* line 12, src/assets/scss/components/_grant-posts.scss */
  .grant-posts-block__sub-heading {
    color: #000;
    font-size: 1.5em;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important; }
  /* line 16, src/assets/scss/components/_grant-posts.scss */
  .grant-posts-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 23, src/assets/scss/components/_grant-posts.scss */
  .grant-posts-block hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #9fddc1;
    max-width: 75%; }
  /* line 32, src/assets/scss/components/_grant-posts.scss */
  .grant-posts-block .card .card-body {
    padding: 18px 8px; }
  /* line 36, src/assets/scss/components/_grant-posts.scss */
  .grant-posts-block .card .card-body .card-title {
    font-size: 1.2em;
    margin-bottom: 7px;
    color: #000;
    font-weight: 800; }
  /* line 43, src/assets/scss/components/_grant-posts.scss */
  .grant-posts-block .card .card-body .card-meta {
    font-size: .75rem;
    margin-bottom: 20px;
    color: #410099;
    font-weight: 600; }
  /* line 50, src/assets/scss/components/_grant-posts.scss */
  .grant-posts-block .card .card-text {
    margin-top: 1em;
    line-height: 1.5;
    font-size: 1em; }

/* line 2, src/assets/scss/components/_post-advertisement-block.scss */
.post-advertisement-block {
  background: #891c56; }

/* line 5, src/assets/scss/components/_post-advertisement-block.scss */
.post-advertisement-block .row, .post-advertisement-block .row-dark {
  height: 28rem; }

/* line 8, src/assets/scss/components/_post-advertisement-block.scss */
.post-advertisement-block .content h2 {
  font-size: 2em;
  font-weight: 600;
  margin: 0 0 .75em; }

/* line 13, src/assets/scss/components/_post-advertisement-block.scss */
.post-advertisement-block .content p {
  font-size: 1em;
  line-height: 2rem;
  /* max-width: 30em; */
  margin-bottom: 2em; }

/* line 21, src/assets/scss/components/_post-advertisement-block.scss */
.classified-search-block {
  background: #efeeee;
  min-height: 10em;
  padding: 1em 0; }
  /* line 26, src/assets/scss/components/_post-advertisement-block.scss */
  .classified-search-block .form-control {
    background: #fff 0 0 no-repeat padding-box;
    border: 1px solid #442e8b;
    /* border-radius: 12px; */
    opacity: 1;
    width: auto;
    border-radius: 25px; }
    @media (min-width: 992px) {
      /* line 26, src/assets/scss/components/_post-advertisement-block.scss */
      .classified-search-block .form-control {
        width: 335px; } }
  /* line 41, src/assets/scss/components/_post-advertisement-block.scss */
  .classified-search-block .search-links > div {
    margin-bottom: 0; }
    @media (min-width: 576px) {
      /* line 41, src/assets/scss/components/_post-advertisement-block.scss */
      .classified-search-block .search-links > div {
        margin-bottom: 1em; } }
  /* line 51, src/assets/scss/components/_post-advertisement-block.scss */
  .classified-search-block .search-links a {
    color: #DC1E8A;
    font-weight: 800;
    text-decoration: underline; }
  /* line 58, src/assets/scss/components/_post-advertisement-block.scss */
  .classified-search-block__category-dropdown {
    margin-top: 1em;
    margin-left: 0; }
    @media (min-width: 768px) {
      /* line 58, src/assets/scss/components/_post-advertisement-block.scss */
      .classified-search-block__category-dropdown {
        margin-left: -1em; } }
    /* line 68, src/assets/scss/components/_post-advertisement-block.scss */
    .classified-search-block__category-dropdown .btn-category {
      background: #fff 0 0 no-repeat padding-box;
      border: 1px solid #442e8b;
      opacity: 1;
      border-radius: 25px;
      padding: .25em .75em;
      line-height: 1.5;
      margin-top: 0 !important; }
      /* line 77, src/assets/scss/components/_post-advertisement-block.scss */
      .classified-search-block__category-dropdown .btn-category:hover {
        text-decoration: none;
        transform: translateY(0px); }

/* line 1, src/assets/scss/components/_classifieds-main-content-block.scss */
.classified-content-block {
  min-height: 75vh;
  padding: 1em 2em; }
  /* line 6, src/assets/scss/components/_classifieds-main-content-block.scss */
  .classified-content-block__wrapper {
    padding: 1em 0 0; }
  /* line 12, src/assets/scss/components/_classifieds-main-content-block.scss */
  .classified-content-block__main-title {
    color: #000;
    font-size: 1.75em;
    text-align: center;
    margin-bottom: 1em;
    font-weight: 600; }
    @media (min-width: 576px) {
      /* line 12, src/assets/scss/components/_classifieds-main-content-block.scss */
      .classified-content-block__main-title {
        text-align: left; } }
  /* line 25, src/assets/scss/components/_classifieds-main-content-block.scss */
  .classified-content-block__post-title {
    color: #000;
    font-size: 1.25em;
    text-align: left;
    letter-spacing: .2px;
    margin: 8px 0;
    font-weight: 800; }
  /* line 34, src/assets/scss/components/_classifieds-main-content-block.scss */
  .classified-content-block__post-meta-title {
    color: #44318B;
    font-size: 1em;
    text-align: left;
    margin: 0 0 1em;
    font-weight: 700;
    letter-spacing: 0.1px; }
  /* line 43, src/assets/scss/components/_classifieds-main-content-block.scss */
  .classified-content-block__post-content {
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 1em; }
  /* line 50, src/assets/scss/components/_classifieds-main-content-block.scss */
  .classified-content-block__post-link {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    color: #DC1E8A; }
  /* line 60, src/assets/scss/components/_classifieds-main-content-block.scss */
  .classified-content-block__post-link::after {
    height: 1px;
    display: block;
    width: 100%;
    background: #000;
    opacity: .5;
    border-right: 1px white;
    content: '';
    margin: 2em 0 1em; }

/* line 1, src/assets/scss/components/_classifieds-single-page-block.scss */
.classified-single-page-block {
  min-height: 75vh;
  padding: 1em 2em; }
  /* line 6, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__wrapper {
    padding: 1em 0 0; }
  /* line 12, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__main-title {
    color: #000;
    font-size: 1.75em;
    text-align: center;
    margin-bottom: .5em;
    font-weight: 600; }
    @media (min-width: 576px) {
      /* line 12, src/assets/scss/components/_classifieds-single-page-block.scss */
      .classified-single-page-block__main-title {
        text-align: left;
        max-width: 65%; } }
  /* line 26, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__post-title {
    color: #000;
    font-size: 1.25em;
    text-align: left;
    letter-spacing: .2px;
    margin: 8px 0;
    font-weight: 800; }
  /* line 35, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__post-meta-title {
    color: #44318B;
    font-size: 1em;
    text-align: left;
    margin: 0 0 1em;
    font-weight: 700;
    letter-spacing: 0.1px;
    margin-top: 2em; }
  /* line 46, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__post-category {
    color: #44318B;
    font-size: 1em;
    text-align: left;
    margin: 0 0 1em;
    font-weight: 700;
    letter-spacing: 0.1px; }
  /* line 55, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__post-meta-title::after {
    height: 1px;
    display: block;
    width: 100%;
    background: #000;
    opacity: .5;
    border-right: 1px white;
    content: '';
    margin: 2em 0 1em; }
  /* line 66, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__post-content {
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 1em; }
  /* line 73, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__post-link {
    margin: 1em 0 2em; }
    /* line 78, src/assets/scss/components/_classifieds-single-page-block.scss */
    .classified-single-page-block__post-link a {
      text-align: left;
      text-decoration: underline;
      font-weight: 600;
      color: #DC1E8A;
      list-style-type: none;
      font-size: 1.25em; }
  /* line 90, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__contact-heading {
    color: #000;
    font-size: 1.25em;
    text-align: left;
    margin-bottom: .5em;
    font-weight: 600; }
  /* line 100, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__phone-title, .classified-single-page-block__email-title, .classified-single-page-block__ad-title {
    color: #44318B;
    font-size: 1em;
    text-align: left;
    font-weight: 700;
    letter-spacing: 0.1px;
    margin-top: 1em; }
  /* line 109, src/assets/scss/components/_classifieds-single-page-block.scss */
  .classified-single-page-block__phone-title-copy, .classified-single-page-block__email-title-copy, .classified-single-page-block__ad-title-copy {
    color: #000;
    font-size: 1em;
    text-align: left; }

/* line 1, src/assets/scss/components/_call-for-proposals-block.scss */
.call-for-proposals-block {
  background: radial-gradient(85% 326% at 10% 110%, #43318b 0%, #43318b 50%, #43318b calc(19% + 1px), #43318b 23%, #852055 calc(50% + 1px), #852055 39%, #852055 calc(50% + 1px), #852055 100%);
  height: 16em;
  margin-top: 2em;
  width: 100%;
  min-height: 325px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  background-attachment: fixed;
  align-items: center;
  height: 16em;
  margin-top: 2em;
  width: 100%;
  min-height: 325px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center; }
  /* line 23, src/assets/scss/components/_call-for-proposals-block.scss */
  .call-for-proposals-block__title {
    flex-grow: 3;
    font-size: 2em;
    text-align: center; }
  /* line 29, src/assets/scss/components/_call-for-proposals-block.scss */
  .call-for-proposals-block__contact1 {
    flex-grow: 1;
    max-width: 307px;
    margin-right: 30px;
    align-self: center; }
  /* line 36, src/assets/scss/components/_call-for-proposals-block.scss */
  .call-for-proposals-block__contact2 {
    flex-grow: 1;
    align-self: baseline; }

@media only screen and (max-width: 968px) {
  /* line 46, src/assets/scss/components/_call-for-proposals-block.scss */
  .call-for-proposals-block {
    background: #852055; }
    /* line 49, src/assets/scss/components/_call-for-proposals-block.scss */
    .call-for-proposals-block__title {
      flex-grow: 3;
      text-align: center; }
    /* line 54, src/assets/scss/components/_call-for-proposals-block.scss */
    .call-for-proposals-block__contact1 {
      max-width: none;
      margin-right: 0;
      text-align: center; }
    /* line 60, src/assets/scss/components/_call-for-proposals-block.scss */
    .call-for-proposals-block__contact2 {
      flex-grow: 1;
      text-align: center; } }

/* line 1, src/assets/scss/components/_more-resources-block.scss */
.more-resources-block {
  background: url(../../img/resources-block-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  background-position: 0% 79%;
  z-index: 1; }
  /* line 13, src/assets/scss/components/_more-resources-block.scss */
  .more-resources-block__main-heading {
    color: #ffffff;
    font-size: 2em;
    font-weight: 600;
    margin: 2em 0 1.25em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 13, src/assets/scss/components/_more-resources-block.scss */
      .more-resources-block__main-heading {
        text-align: left;
        margin: 0 0 1.25em; } }
  /* line 28, src/assets/scss/components/_more-resources-block.scss */
  .more-resources-block__wrapper {
    background-color: #ffffff;
    position: relative;
    z-index: 5;
    border-radius: 6px;
    padding: 55px 19px 55px;
    box-shadow: -3px 3px 10px #414346;
    width: 75%;
    display: block;
    margin: 0 auto; }
    @media (min-width: 576px) {
      /* line 28, src/assets/scss/components/_more-resources-block.scss */
      .more-resources-block__wrapper {
        display: flex;
        width: 100%; } }
  /* line 46, src/assets/scss/components/_more-resources-block.scss */
  .more-resources-block__wrapper-sub-heading {
    color: #000;
    margin-top: 10px;
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 28px; }
  /* line 54, src/assets/scss/components/_more-resources-block.scss */
  .more-resources-block .border-right {
    border-right: 0px solid #dee2e6 !important; }
    @media (min-width: 576px) {
      /* line 54, src/assets/scss/components/_more-resources-block.scss */
      .more-resources-block .border-right {
        border-right: 1px solid #dee2e6 !important; } }

/* line 1, src/assets/scss/components/_latest-video-block.scss */
.latest-video-block {
  background: #efeeee; }
  /* line 4, src/assets/scss/components/_latest-video-block.scss */
  .latest-video-block__main-heading {
    color: #000;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important;
    margin-bottom: 0em !important; }
    @media (min-width: 576px) {
      /* line 4, src/assets/scss/components/_latest-video-block.scss */
      .latest-video-block__main-heading {
        margin-bottom: 2em !important; } }
  /* line 16, src/assets/scss/components/_latest-video-block.scss */
  .latest-video-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 23, src/assets/scss/components/_latest-video-block.scss */
  .latest-video-block hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #9fddc1;
    max-width: 75%; }
  /* line 32, src/assets/scss/components/_latest-video-block.scss */
  .latest-video-block .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent; }
  /* line 47, src/assets/scss/components/_latest-video-block.scss */
  .latest-video-block .card .card-body {
    padding: 18px 8px; }
  /* line 52, src/assets/scss/components/_latest-video-block.scss */
  .latest-video-block .card .card-body .featured-card-title {
    font-size: 1.5em;
    margin-bottom: 7px;
    color: #000;
    font-weight: 800;
    max-width: 60vh; }
  /* line 60, src/assets/scss/components/_latest-video-block.scss */
  .latest-video-block .card .card-body .card-title {
    font-size: 1em;
    margin-bottom: 7px;
    color: #000;
    font-weight: 600; }
  /* line 68, src/assets/scss/components/_latest-video-block.scss */
  .latest-video-block .card .card-body .card-meta {
    font-size: .75rem;
    margin-bottom: 5px;
    color: #410099;
    font-weight: 600; }
  /* line 75, src/assets/scss/components/_latest-video-block.scss */
  .latest-video-block .card .card-text {
    margin-top: 1em;
    line-height: 1.5;
    font-weight: 600; }

/* line 1, src/assets/scss/components/_latest-news-block.scss */
.latest-news-block {
  background: #efeeee; }
  /* line 4, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block__main-heading {
    color: #000;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important;
    margin-bottom: 0em !important; }
    @media (min-width: 576px) {
      /* line 4, src/assets/scss/components/_latest-news-block.scss */
      .latest-news-block__main-heading {
        margin-bottom: 2em !important; } }
  /* line 16, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 23, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #9fddc1;
    max-width: 75%; }
  /* line 32, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    margin-bottom: 50px;
    /* background-clip: border-box; */
    /* border: 1px solid rgba(0,0,0,.125); */
    /* border-radius: .25rem; */ }
  /* line 48, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block .card .card-body {
    padding: 18px 8px; }
  /* line 53, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block .card .card-body .featured-card-title {
    font-size: 1.5em;
    margin-bottom: 7px;
    color: #000;
    font-weight: 800;
    max-width: 60vh; }
  /* line 61, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block .card .card-body .card-title {
    font-size: 1em;
    margin-bottom: 17px;
    color: #000;
    font-weight: 600; }
  /* line 69, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block .card .card-body .card-meta {
    font-size: .75rem;
    margin-bottom: 5px;
    color: #410099;
    font-weight: 600; }
  /* line 76, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block .card .card-text {
    margin-top: 1em;
    line-height: 1.5;
    font-weight: 600; }
  /* line 84, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block__links {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    color: #e93a97; }
  /* line 94, src/assets/scss/components/_latest-news-block.scss */
  .latest-news-block__link-title {
    font-size: .75em;
    text-align: left;
    letter-spacing: .2px;
    margin: 8px 0;
    font-weight: 800;
    margin: 1em 0 2em; }
    @media (min-width: 576px) {
      /* line 94, src/assets/scss/components/_latest-news-block.scss */
      .latest-news-block__link-title {
        font-size: 1em; } }

/* line 1, src/assets/scss/components/_latest-retired-posts-block.scss */
.latest-retired-posts-block {
  margin: 3em 0 2em;
  position: relative;
  padding: 23px 35px;
  bottom: 0;
  right: 0; }
  /* line 8, src/assets/scss/components/_latest-retired-posts-block.scss */
  .latest-retired-posts-block__main-heading {
    color: #000;
    font-size: 2.5rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important; }
  /* line 12, src/assets/scss/components/_latest-retired-posts-block.scss */
  .latest-retired-posts-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 18, src/assets/scss/components/_latest-retired-posts-block.scss */
  .latest-retired-posts-block hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #9fddc1;
    max-width: 75%; }
  /* line 26, src/assets/scss/components/_latest-retired-posts-block.scss */
  .latest-retired-posts-block .card .card-body {
    padding: 18px 8px; }
  /* line 30, src/assets/scss/components/_latest-retired-posts-block.scss */
  .latest-retired-posts-block .card .card-body .card-title {
    font-size: 1.25em;
    margin-bottom: 10px;
    color: #000;
    font-weight: 800; }
  /* line 37, src/assets/scss/components/_latest-retired-posts-block.scss */
  .latest-retired-posts-block .card .card-body .card-meta {
    font-size: .75rem;
    margin: 5px 0 15px;
    color: #410099;
    font-weight: 600; }
  /* line 44, src/assets/scss/components/_latest-retired-posts-block.scss */
  .latest-retired-posts-block .card .card-text {
    margin-top: 1em;
    line-height: 1.5;
    font-weight: 400; }

/* line 1, src/assets/scss/components/_related-posts-block.scss */
.related-posts-block {
  margin: 1em 0 2em;
  position: relative;
  bottom: 0;
  right: 0; }
  /* line 7, src/assets/scss/components/_related-posts-block.scss */
  .related-posts-block__main-heading {
    color: #000;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important; }
  /* line 11, src/assets/scss/components/_related-posts-block.scss */
  .related-posts-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 17, src/assets/scss/components/_related-posts-block.scss */
  .related-posts-block hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #9fddc1;
    max-width: 75%; }
  /* line 25, src/assets/scss/components/_related-posts-block.scss */
  .related-posts-block .card .card-body {
    padding: 18px 8px; }
  /* line 29, src/assets/scss/components/_related-posts-block.scss */
  .related-posts-block .card .card-body .card-title {
    font-size: 1.25em;
    margin-bottom: 10px;
    color: #000;
    font-weight: 800; }
  /* line 36, src/assets/scss/components/_related-posts-block.scss */
  .related-posts-block .card .card-body .card-meta {
    font-size: .75rem;
    margin: 5px 0 15px;
    color: #410099;
    font-weight: 600; }
  /* line 43, src/assets/scss/components/_related-posts-block.scss */
  .related-posts-block .card .card-text {
    margin-top: 1em;
    line-height: 1.5;
    font-weight: 400; }

/* line 3, src/assets/scss/components/_sub-page-content-block.scss */
.sub-page-content-block__title {
  color: #000;
  font-size: 1.75em;
  text-align: center;
  margin-bottom: 1em; }
  @media (min-width: 576px) {
    /* line 3, src/assets/scss/components/_sub-page-content-block.scss */
    .sub-page-content-block__title {
      text-align: left; } }

/* line 16, src/assets/scss/components/_sub-page-content-block.scss */
.sub-page-content-block__sub-title {
  color: #000;
  font-size: 1.25em;
  text-align: center;
  margin: 1em 0;
  padding: 0em 1em; }
  @media (min-width: 576px) {
    /* line 16, src/assets/scss/components/_sub-page-content-block.scss */
    .sub-page-content-block__sub-title {
      text-align: left;
      padding: 0; } }

/* line 31, src/assets/scss/components/_sub-page-content-block.scss */
.sub-page-content-block ul {
  padding: 1em 3em; }
  @media (min-width: 576px) {
    /* line 31, src/assets/scss/components/_sub-page-content-block.scss */
    .sub-page-content-block ul {
      padding: 0; } }

/* line 41, src/assets/scss/components/_sub-page-content-block.scss */
.sub-page-content-block ul li {
  list-style-type: disc; }

/* line 2, src/assets/scss/components/_find-resources-block.scss */
a.resources-box-fill-link {
  display: block;
  height: auto;
  width: auto;
  text-decoration: none;
  cursor: pointer;
  color: #44318B;
  font-weight: 800;
  font-size: 1.25em; }
  /* line 12, src/assets/scss/components/_find-resources-block.scss */
  a.resources-box-fill-link:hover {
    text-decoration: none; }

/* line 19, src/assets/scss/components/_find-resources-block.scss */
.find-resources-block__main-heading {
  color: #000;
  font-size: 2em;
  font-weight: 600;
  margin: 0em 0 1.25em;
  text-align: center; }
  @media (min-width: 576px) {
    /* line 19, src/assets/scss/components/_find-resources-block.scss */
    .find-resources-block__main-heading {
      text-align: left; } }

/* line 33, src/assets/scss/components/_find-resources-block.scss */
.find-resources-block__box {
  background: #8fcea5;
  border-radius: 18px;
  /* margin-bottom: 25px; */
  padding: 20px 64px;
  height: 12em;
  overflow: hidden;
  text-align: center;
  max-width: 22em;
  /* margin: 0 auto; */ }
  /* line 45, src/assets/scss/components/_find-resources-block.scss */
  .find-resources-block__box:hover {
    background: #4bac6d;
    transform: scale(1.1); }
  /* line 50, src/assets/scss/components/_find-resources-block.scss */
  .find-resources-block__box:hover > h5 {
    color: #ffffff;
    font-weight: 800;
    font-size: 1.25em;
    text-decoration: none; }

/* line 59, src/assets/scss/components/_find-resources-block.scss */
.find-resources-block__box--heading {
  margin-top: 10px;
  margin-bottom: 28px;
  max-width: 10em; }

/* line 67, src/assets/scss/components/_find-resources-block.scss */
.find-resources-block_box--heading a {
  color: #44318B;
  font-weight: 800;
  font-size: 1.25em; }

/* line 1, src/assets/scss/components/_tools-resources-block.scss */
.tools-resources-block {
  background: #efeeee;
  padding: 100px; }
  /* line 6, src/assets/scss/components/_tools-resources-block.scss */
  .tools-resources-block__main-heading {
    color: #000;
    font-size: 2.5em;
    font-weight: 600;
    margin: 0em 0 1.25em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 6, src/assets/scss/components/_tools-resources-block.scss */
      .tools-resources-block__main-heading {
        text-align: left; } }
  /* line 20, src/assets/scss/components/_tools-resources-block.scss */
  .tools-resources-block__box {
    background: #8fcea5;
    border-radius: 18px;
    margin-bottom: 25px;
    padding: 25px 53px;
    height: 12em;
    width: auto;
    overflow: hidden;
    text-align: center; }
    /* line 30, src/assets/scss/components/_tools-resources-block.scss */
    .tools-resources-block__box:hover {
      background: #4bac6d;
      transform: scale(1.1); }
    /* line 35, src/assets/scss/components/_tools-resources-block.scss */
    .tools-resources-block__box:hover > h5 > a {
      color: #ffffff;
      font-weight: 800;
      font-size: 1.25em;
      text-decoration: none; }
    @media (min-width: 768px) {
      /* line 20, src/assets/scss/components/_tools-resources-block.scss */
      .tools-resources-block__box {
        width: 12em; } }
    @media (min-width: 992px) {
      /* line 20, src/assets/scss/components/_tools-resources-block.scss */
      .tools-resources-block__box {
        width: 19em; } }
  /* line 52, src/assets/scss/components/_tools-resources-block.scss */
  .tools-resources-block__box--heading {
    margin-top: 10px;
    margin-bottom: 28px;
    max-width: 10em; }
  /* line 60, src/assets/scss/components/_tools-resources-block.scss */
  .tools-resources-block_box--heading a {
    color: #44318B;
    font-weight: 800;
    font-size: 1.5em; }

/* line 1, src/assets/scss/components/_downloadable-resources-block.scss */
.downloadable-resources-block {
  background: #ffffff;
  padding: 23px 35px;
  margin: 2em 0; }
  /* line 7, src/assets/scss/components/_downloadable-resources-block.scss */
  .downloadable-resources-block__main-heading {
    color: #000;
    font-size: 2.5em;
    font-weight: 600;
    margin: 0em 0 2em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 7, src/assets/scss/components/_downloadable-resources-block.scss */
      .downloadable-resources-block__main-heading {
        text-align: left; } }

/* line 4, src/assets/scss/components/_insurance-posts-block.scss */
.insurance-posts-block__title {
  color: #000;
  font-size: 1.75em;
  text-align: center;
  margin-bottom: 1em;
  font-weight: 600; }
  @media (min-width: 576px) {
    /* line 4, src/assets/scss/components/_insurance-posts-block.scss */
    .insurance-posts-block__title {
      text-align: left; } }

/* line 17, src/assets/scss/components/_insurance-posts-block.scss */
.insurance-posts-block .card .card-body {
  padding: 25px 18px; }

/* line 21, src/assets/scss/components/_insurance-posts-block.scss */
.insurance-posts-block .card .card-body .card-title {
  font-size: 1.25em;
  margin-bottom: 20px;
  font-weight: 600; }

/* line 27, src/assets/scss/components/_insurance-posts-block.scss */
.insurance-posts-block .card-subtitle, .insurance-posts-block .card-text:last-child {
  margin-bottom: 0;
  font-size: 1em; }

/* line 1, src/assets/scss/components/_insurance-plans-content-block.scss */
.insurance-plans-content-block {
  margin: 2em 0; }
  /* line 5, src/assets/scss/components/_insurance-plans-content-block.scss */
  .insurance-plans-content-block__title {
    color: #000;
    font-size: 1.75em;
    text-align: center;
    margin-bottom: 1em;
    font-weight: 600; }
    @media (min-width: 576px) {
      /* line 5, src/assets/scss/components/_insurance-plans-content-block.scss */
      .insurance-plans-content-block__title {
        text-align: left; } }
  /* line 19, src/assets/scss/components/_insurance-plans-content-block.scss */
  .insurance-plans-content-block__sub-title {
    color: #000;
    font-size: 1.25em;
    text-align: center;
    margin-bottom: .5em; }
    @media (min-width: 576px) {
      /* line 19, src/assets/scss/components/_insurance-plans-content-block.scss */
      .insurance-plans-content-block__sub-title {
        text-align: left; } }
  /* line 31, src/assets/scss/components/_insurance-plans-content-block.scss */
  .insurance-plans-content-block ul li {
    list-style-type: disc; }

/* line 1, src/assets/scss/components/_coverage-comparison-block.scss */
.healthcare-coverage-block {
  margin: 3em 0 2em; }
  /* line 5, src/assets/scss/components/_coverage-comparison-block.scss */
  .healthcare-coverage-block ul > li {
    list-style-type: disc;
    margin-left: 29px;
    line-height: 1.8;
    margin-bottom: 5px; }
  /* line 12, src/assets/scss/components/_coverage-comparison-block.scss */
  .healthcare-coverage-block__title {
    font-size: 2em;
    margin-bottom: .5em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 12, src/assets/scss/components/_coverage-comparison-block.scss */
      .healthcare-coverage-block__title {
        text-align: left; } }
  /* line 22, src/assets/scss/components/_coverage-comparison-block.scss */
  .healthcare-coverage-block__content-title {
    color: #44318B;
    font-size: 1.5em;
    margin-bottom: 1em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 22, src/assets/scss/components/_coverage-comparison-block.scss */
      .healthcare-coverage-block__content-title {
        text-align: left; } }

/* line 35, src/assets/scss/components/_coverage-comparison-block.scss */
.accordion-tabs {
  margin: 20px;
  border: solid 1px #eee;
  border-radius: 6px;
  box-shadow: 3px 4px 5px #eee; }
  /* line 42, src/assets/scss/components/_coverage-comparison-block.scss */
  .accordion-tabs img {
    max-width: 200px; }
  /* line 46, src/assets/scss/components/_coverage-comparison-block.scss */
  .accordion-tabs h4 {
    font-weight: 400;
    font-size: 14px;
    word-break: break-word; }
    @media (min-width: 576px) {
      /* line 46, src/assets/scss/components/_coverage-comparison-block.scss */
      .accordion-tabs h4 {
        max-width: 200px; } }
  /* line 58, src/assets/scss/components/_coverage-comparison-block.scss */
  .accordion-tabs .activate-tab {
    position: absolute;
    left: -10000px; }
  /* line 63, src/assets/scss/components/_coverage-comparison-block.scss */
  .accordion-tabs .tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    /* line 68, src/assets/scss/components/_coverage-comparison-block.scss */
    .accordion-tabs .tabs .tab {
      display: inline-block;
      padding: 35px 10px 28px;
      vertical-align: top;
      background-color: #ffffff;
      cursor: hand;
      cursor: pointer;
      border-left: 1px solid #eee;
      flex-grow: 1;
      margin-bottom: 0;
      transition: 0.3s;
      border-bottom: solid 4px #44318B; }
      /* line 80, src/assets/scss/components/_coverage-comparison-block.scss */
      .accordion-tabs .tabs .tab:hover {
        background-color: #eee; }
      @media (min-width: 576px) {
        /* line 68, src/assets/scss/components/_coverage-comparison-block.scss */
        .accordion-tabs .tabs .tab {
          border-bottom: none; } }
    /* line 90, src/assets/scss/components/_coverage-comparison-block.scss */
    .accordion-tabs .tabs .tab-content {
      background-color: #eee;
      padding: 20px 20px 20px 10px;
      min-height: 300px;
      display: none;
      width: 100%;
      flex-basis: auto; }

/* line 102, src/assets/scss/components/_coverage-comparison-block.scss */
#tab-one:checked ~ .tabs #tab-one-label,
#tab-two:checked ~ .tabs #tab-two-label,
#tab-three:checked ~ .tabs #tab-three-label,
#tab-four:checked ~ .tabs #tab-four-label,
#tab-five:checked ~ .tabs #tab-five-label,
#tab-six:checked ~ .tabs #tab-six-label,
#tab-seven:checked ~ .tabs #tab-seven-label,
#tab-eight:checked ~ .tabs #tab-eight-label,
#tab-nine:checked ~ .tabs #tab-nine-label,
#tab-ten:checked ~ .tabs #tab-ten-label {
  background-color: #eee;
  cursor: default;
  border-left: none;
  transition: .3s ease-in;
  border-top: 3px solid #696363; }

/* line 120, src/assets/scss/components/_coverage-comparison-block.scss */
#tab-one:checked ~ .tabs #tab-one-tab-content,
#tab-two:checked ~ .tabs #tab-two-tab-content,
#tab-three:checked ~ .tabs #tab-three-tab-content,
#tab-four:checked ~ .tabs #tab-four-tab-content,
#tab-five:checked ~ .tabs #tab-five-tab-content,
#tab-six:checked ~ .tabs #tab-six-tab-content,
#tab-seven:checked ~ .tabs #tab-seven-tab-content,
#tab-eight:checked ~ .tabs #tab-eight-tab-content,
#tab-nine:checked ~ .tabs #tab-nine-tab-content,
#tab-ten:checked ~ .tabs #tab-ten-tab-content {
  display: block;
  padding: 25px 115px; }
  /* line 133, src/assets/scss/components/_coverage-comparison-block.scss */
  #tab-one:checked ~ .tabs #tab-one-tab-content h4,
  #tab-two:checked ~ .tabs #tab-two-tab-content h4,
  #tab-three:checked ~ .tabs #tab-three-tab-content h4,
  #tab-four:checked ~ .tabs #tab-four-tab-content h4,
  #tab-five:checked ~ .tabs #tab-five-tab-content h4,
  #tab-six:checked ~ .tabs #tab-six-tab-content h4,
  #tab-seven:checked ~ .tabs #tab-seven-tab-content h4,
  #tab-eight:checked ~ .tabs #tab-eight-tab-content h4,
  #tab-nine:checked ~ .tabs #tab-nine-tab-content h4,
  #tab-ten:checked ~ .tabs #tab-ten-tab-content h4 {
    font-weight: bolder;
    font-size: 1em; }

@media (max-width: 767px) {
  /* line 146, src/assets/scss/components/_coverage-comparison-block.scss */
  .accordion-tabs img {
    max-width: 50px; }
  /* line 154, src/assets/scss/components/_coverage-comparison-block.scss */
  .tabs {
    flex-direction: column; }
    /* line 159, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs .tab {
      padding: 10px; }
    /* line 164, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-one-label {
      order: 1; }
    /* line 167, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-one-tab-content {
      order: 2; }
    /* line 172, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-two-label {
      order: 3; }
    /* line 175, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-two-tab-content {
      order: 4; }
    /* line 180, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-three-label {
      order: 5; }
    /* line 183, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-three-tab-content {
      order: 6; }
    /* line 188, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-four-label {
      order: 7; }
    /* line 191, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-four-tab-content {
      order: 8; }
    /* line 196, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-four-label {
      order: 9; }
    /* line 199, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-four-tab-content {
      order: 10; }
    /* line 204, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-five-label {
      order: 11; }
    /* line 207, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-five-tab-content {
      order: 12; }
    /* line 212, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-six-label {
      order: 1; }
    /* line 215, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-six-tab-content {
      order: 2; }
    /* line 220, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-seven-label {
      order: 3; }
    /* line 223, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-seven-tab-content {
      order: 4; }
    /* line 228, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-eight-label {
      order: 5; }
    /* line 231, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-eight-tab-content {
      order: 6; }
    /* line 236, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-nine-label {
      order: 7; }
    /* line 239, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-nine-tab-content {
      order: 8; }
    /* line 244, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-ten-label {
      order: 9; }
    /* line 247, src/assets/scss/components/_coverage-comparison-block.scss */
    .tabs #tab-ten-tab-content {
      order: 10; }
  /* line 253, src/assets/scss/components/_coverage-comparison-block.scss */
  #tab-one:checked ~ .tabs #tab-one-label,
  #tab-two:checked ~ .tabs #tab-two-label,
  #tab-three:checked ~ .tabs #tab-three-label,
  #tab-four:checked ~ .tabs #tab-four-label,
  #tab-five:checked ~ .tabs #tab-five-label {
    background-color: #f8f8f8;
    border-bottom: 1px solid #ccc; }
  /* line 263, src/assets/scss/components/_coverage-comparison-block.scss */
  #tab-one:checked ~ .tabs #tab-one-tab-content,
  #tab-two:checked ~ .tabs #tab-two-tab-content,
  #tab-three:checked ~ .tabs #tab-three-tab-content,
  #tab-four:checked ~ .tabs #tab-four-tab-content,
  #tab-five:checked ~ .tabs #tab-five-tab-content {
    display: block;
    padding: 25px 15px; } }

/* line 279, src/assets/scss/components/_coverage-comparison-block.scss */
.accordion-item-body-coverage-content {
  padding: 2rem 4em 2em;
  line-height: 1.5rem;
  min-height: 365px; }

/* line 1, src/assets/scss/components/_news-post-block.scss */
.news-post-block {
  background: #ffffff; }
  /* line 4, src/assets/scss/components/_news-post-block.scss */
  .news-post-block__main-heading {
    color: #000;
    font-size: 2.5em;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important;
    margin-bottom: 0em !important; }
    @media (min-width: 576px) {
      /* line 4, src/assets/scss/components/_news-post-block.scss */
      .news-post-block__main-heading {
        margin: .5em 0 1em; } }
  /* line 14, src/assets/scss/components/_news-post-block.scss */
  .news-post-block__image-card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    margin-bottom: 0;
    /* background-clip: border-box; */
    /* border: 1px solid rgba(0,0,0,.125); */
    /* border-radius: .25rem; */ }
  /* line 31, src/assets/scss/components/_news-post-block.scss */
  .news-post-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 37, src/assets/scss/components/_news-post-block.scss */
  .news-post-block ul li {
    max-width: 80%;
    line-height: 1.9;
    margin-left: 15px;
    padding-bottom: 5px; }
  /* line 45, src/assets/scss/components/_news-post-block.scss */
  .news-post-block hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #9fddc1;
    max-width: 75%; }
  /* line 54, src/assets/scss/components/_news-post-block.scss */
  .news-post-block .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    margin-bottom: 50px;
    /* background-clip: border-box; */
    /* border: 1px solid rgba(0,0,0,.125); */
    /* border-radius: .25rem; */ }
  /* line 70, src/assets/scss/components/_news-post-block.scss */
  .news-post-block .card .card-body {
    padding: 18px 8px; }
  /* line 75, src/assets/scss/components/_news-post-block.scss */
  .news-post-block .card .card-body .featured-card-title {
    font-size: 1.5em;
    margin-bottom: 7px;
    color: #000;
    font-weight: 800;
    max-width: 60vh; }
  /* line 83, src/assets/scss/components/_news-post-block.scss */
  .news-post-block .card .card-body .card-title {
    font-size: 1em;
    margin-bottom: 17px;
    color: #000;
    font-weight: 600; }
  /* line 91, src/assets/scss/components/_news-post-block.scss */
  .news-post-block .card .card-body .card-meta {
    font-size: .75rem;
    margin-bottom: 5px;
    color: #410099;
    font-weight: 600; }
  /* line 98, src/assets/scss/components/_news-post-block.scss */
  .news-post-block .card .card-text {
    margin-top: 1em;
    line-height: 1.5;
    font-weight: 600; }
  /* line 104, src/assets/scss/components/_news-post-block.scss */
  .news-post-block__news-date {
    font-size: 1.5em;
    margin: 1em 0;
    color: #410099;
    font-weight: 600; }
  /* line 111, src/assets/scss/components/_news-post-block.scss */
  .news-post-block__links {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    color: #e93a97; }
  /* line 121, src/assets/scss/components/_news-post-block.scss */
  .news-post-block__link-title {
    font-size: .75em;
    text-align: left;
    letter-spacing: .2px;
    margin: 8px 0;
    font-weight: 800;
    margin: 1em 0 2em; }
    @media (min-width: 576px) {
      /* line 121, src/assets/scss/components/_news-post-block.scss */
      .news-post-block__link-title {
        font-size: 1em; } }

/* line 1, src/assets/scss/components/_sidebar-categories-block.scss */
.sidebar-categories-block {
  margin: 0;
  width: auto;
  background: #efeeee;
  color: #000;
  padding: 4em 3em;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  margin-left: 0; }
  @media (min-width: 576px) {
    /* line 1, src/assets/scss/components/_sidebar-categories-block.scss */
    .sidebar-categories-block {
      margin-left: 2em;
      min-height: 224px;
      width: 19em;
      padding: 2em 1em; } }
  /* line 22, src/assets/scss/components/_sidebar-categories-block.scss */
  .sidebar-categories-block h2 {
    font-size: 1.25em;
    color: #000;
    font-weight: 600;
    margin-left: -4em; }
  /* line 29, src/assets/scss/components/_sidebar-categories-block.scss */
  .sidebar-categories-block ul li {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600; }
    /* line 36, src/assets/scss/components/_sidebar-categories-block.scss */
    .sidebar-categories-block ul li a {
      color: #DC1E8A; }
      /* line 39, src/assets/scss/components/_sidebar-categories-block.scss */
      .sidebar-categories-block ul li a:hover {
        color: #280619; }

/* line 1, src/assets/scss/components/_sidebar-download-block.scss */
.sidebar-download-block {
  margin: 0;
  width: auto;
  background: #efeeee;
  color: #000;
  padding: 4em 3em;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  margin-left: 0;
  text-align: center; }
  @media (min-width: 576px) {
    /* line 1, src/assets/scss/components/_sidebar-download-block.scss */
    .sidebar-download-block {
      margin-left: 2em;
      min-height: 224px;
      width: 19em;
      padding: 2em 1em; } }

/* line 1, src/assets/scss/components/_explore-insurance-block.scss */
.explore-insurance-block {
  background: url(../../assets/img/Our-Team-Banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  background-position: 0% 79%;
  min-height: 30em;
  z-index: -1; }
  /* line 14, src/assets/scss/components/_explore-insurance-block.scss */
  .explore-insurance-block__main-heading {
    color: #ffffff;
    font-size: 2em;
    font-weight: 600;
    margin: 0em 0 1.25em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 14, src/assets/scss/components/_explore-insurance-block.scss */
      .explore-insurance-block__main-heading {
        text-align: left; } }
  /* line 28, src/assets/scss/components/_explore-insurance-block.scss */
  .explore-insurance-block__wrapper {
    background-color: #ffffff;
    position: relative;
    z-index: 5;
    border-radius: 6px;
    padding: 33px 19px 25px;
    box-shadow: -3px 3px 10px #414346;
    width: 75%;
    display: block;
    margin: 0 auto; }
    /* line 39, src/assets/scss/components/_explore-insurance-block.scss */
    .explore-insurance-block__wrapper .last-child-block {
      margin-top: 5em; }
    @media (min-width: 768px) {
      /* line 28, src/assets/scss/components/_explore-insurance-block.scss */
      .explore-insurance-block__wrapper {
        display: flex; } }
  /* line 50, src/assets/scss/components/_explore-insurance-block.scss */
  .explore-insurance-block__wrapper-heading {
    color: #000;
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.25em;
    margin-bottom: 28px; }
  /* line 58, src/assets/scss/components/_explore-insurance-block.scss */
  .explore-insurance-block .border-right {
    border-right: 0px solid #dee2e6 !important; }
    @media (min-width: 768px) {
      /* line 58, src/assets/scss/components/_explore-insurance-block.scss */
      .explore-insurance-block .border-right {
        border-right: 1px solid #dee2e6 !important; } }

/* line 2, src/assets/scss/components/_explore-our-programs-block.scss */
a.box-fill-link {
  display: block;
  height: auto;
  width: auto;
  text-decoration: none;
  cursor: pointer; }
  /* line 9, src/assets/scss/components/_explore-our-programs-block.scss */
  a.box-fill-link:hover div > p > a {
    text-decoration: none; }

/* line 17, src/assets/scss/components/_explore-our-programs-block.scss */
.our-programs__main-heading {
  color: #000;
  font-size: 2em;
  font-weight: 600;
  margin: 0em 0 1.25em;
  text-align: center; }
  @media (min-width: 576px) {
    /* line 17, src/assets/scss/components/_explore-our-programs-block.scss */
    .our-programs__main-heading {
      text-align: left; } }

/* line 30, src/assets/scss/components/_explore-our-programs-block.scss */
.our-programs .program-block {
  background: #efeeee;
  border-radius: 18px;
  min-height: 12em;
  margin-bottom: 25px;
  padding: 20px 37px;
  height: 17em;
  overflow: hidden;
  text-align: left;
  position: relative; }
  /* line 41, src/assets/scss/components/_explore-our-programs-block.scss */
  .our-programs .program-block div > h5 > a {
    color: #000; }
  /* line 45, src/assets/scss/components/_explore-our-programs-block.scss */
  .our-programs .program-block div > p > a {
    color: #efeeee; }
  /* line 49, src/assets/scss/components/_explore-our-programs-block.scss */
  .our-programs .program-block:hover {
    background: #44318B; }
  /* line 54, src/assets/scss/components/_explore-our-programs-block.scss */
  .our-programs .program-block:hover div > h5 > a {
    color: #ffffff;
    font-weight: 800;
    font-size: 1.5em;
    text-decoration: none; }
  /* line 61, src/assets/scss/components/_explore-our-programs-block.scss */
  .our-programs .program-block:hover div > p > a {
    color: #ffffff;
    text-decoration: none; }
  /* line 67, src/assets/scss/components/_explore-our-programs-block.scss */
  .our-programs .program-block__heading {
    color: #000;
    margin-top: 10px;
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 28px;
    position: relative;
    max-width: 80%; }
    /* line 76, src/assets/scss/components/_explore-our-programs-block.scss */
    .our-programs .program-block__heading:hover {
      color: #ffffff;
      font-weight: 800;
      font-size: 1.5em;
      text-decoration: none; }
  /* line 85, src/assets/scss/components/_explore-our-programs-block.scss */
  .our-programs .program-block__copy {
    color: #efeeee;
    font-weight: 200;
    font-size: .95em;
    /* padding-bottom: 25px; */
    max-width: 20em;
    line-height: 1.4;
    margin-top: 1em;
    max-width: 80%; }
  /* line 98, src/assets/scss/components/_explore-our-programs-block.scss */
  .our-programs .program-block__link:after {
    color: #efeeee;
    content: "";
    height: 25px;
    width: 25px;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translate(-50%, -50%); }
  /* line 109, src/assets/scss/components/_explore-our-programs-block.scss */
  .our-programs .program-block__link:hover:after {
    color: #ffffff;
    content: ">"; }

/* Foundation First Block
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* line 4, src/assets/scss/components/_foundation.scss */
.foundation-first-content-block {
  margin: 3em 0; }
  /* line 7, src/assets/scss/components/_foundation.scss */
  .foundation-first-content-block__heading {
    color: #000;
    font-size: 1.75em;
    margin-bottom: 1em;
    font-weight: 600;
    max-width: 75%;
    text-align: left; }
  /* line 17, src/assets/scss/components/_foundation.scss */
  .foundation-first-content-block__copy {
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 1em; }

/* line 29, src/assets/scss/components/_foundation.scss */
.foundation-single-testimonial h2 {
  color: #410099;
  font-size: 2rem;
  line-height: 110%;
  font-weight: 700;
  position: relative;
  margin-bottom: 1rem !important;
  color: #410099; }

/* line 35, src/assets/scss/components/_foundation.scss */
.foundation-single-testimonial__body {
  /* max-width: 85%; */
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: 900;
  color: #000; }
  /* line 42, src/assets/scss/components/_foundation.scss */
  .foundation-single-testimonial__body span {
    display: block;
    margin-top: 1em;
    color: #410099; }

/* line 50, src/assets/scss/components/_foundation.scss */
.foundation-single-testimonial__box {
  background: #9fddc1;
  width: 29em;
  padding: 6em 5em;
  border-radius: 140px 154px 154px 0;
  max-width: 25em;
  margin-left: 12em; }

@media only screen and (max-width: 800px) {
  /* line 65, src/assets/scss/components/_foundation.scss */
  .foundation-single-testimonial__box {
    background: #9fddc1;
    width: 100%;
    padding: 4em;
    border-radius: 0px;
    /* max-width: 0; */
    margin-left: 0; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 78, src/assets/scss/components/_foundation.scss */
  .foundation-single-testimonial__box {
    background: #9fddc1;
    width: 100%;
    padding: 4em;
    border-radius: 0;
    margin-left: -2em;
    border-radius: 140px 154px 154px 0; } }

/* line 92, src/assets/scss/components/_foundation.scss */
.foundation-social-section {
  margin: 3em 0 0;
  padding: 5em 0;
  min-height: 40em;
  /* background-image: url(../../assets/img/news-image.png); */
  background-repeat: no-repeat;
  position: relative;
  bottom: 0;
  right: 0;
  background: url(../../assets/img/foundationtwitter-bg.svg);
  background-size: cover;
  background-position: right 7%;
  min-height: 83vh; }
  /* line 94, src/assets/scss/components/_foundation.scss */
  .foundation-social-section__main-heading {
    color: #ffffff;
    font-size: 2em;
    margin-bottom: .5em;
    font-weight: 600; }
  /* line 101, src/assets/scss/components/_foundation.scss */
  .foundation-social-section__twitter-heading {
    color: #ffffff;
    font-size: 1em; }

/* line 1, src/assets/scss/components/_resources-block.scss */
.resources-block {
  background: url(../../assets/img/Our-Team-Banner-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  background-position: 0% 79%;
  z-index: -1; }
  /* line 11, src/assets/scss/components/_resources-block.scss */
  .resources-block__main-heading {
    color: #ffffff;
    font-size: 2em;
    font-weight: 600;
    margin: 0em 0 1.25em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 11, src/assets/scss/components/_resources-block.scss */
      .resources-block__main-heading {
        text-align: left; } }
  /* line 25, src/assets/scss/components/_resources-block.scss */
  .resources-block__wrapper {
    background-color: #ffffff;
    position: relative;
    z-index: 5;
    border-radius: 6px;
    padding: 55px 19px 55px;
    box-shadow: -3px 3px 10px #414346;
    width: 75%;
    display: block;
    margin: 0 auto; }
    @media (min-width: 576px) {
      /* line 25, src/assets/scss/components/_resources-block.scss */
      .resources-block__wrapper {
        display: flex;
        width: 100%; } }
  /* line 43, src/assets/scss/components/_resources-block.scss */
  .resources-block__wrapper-heading {
    color: #000;
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.25em;
    margin-bottom: 28px; }
  /* line 51, src/assets/scss/components/_resources-block.scss */
  .resources-block .border-right {
    border-right: 0px solid #dee2e6 !important; }
    @media (min-width: 576px) {
      /* line 51, src/assets/scss/components/_resources-block.scss */
      .resources-block .border-right {
        border-right: 1px solid #dee2e6 !important; } }

/* line 1, src/assets/scss/components/_departments-block.scss */
.departments-block {
  margin: 4em 0 2em; }
  /* line 4, src/assets/scss/components/_departments-block.scss */
  .departments-block__department-main-heading {
    color: #000;
    font-size: 2.25em;
    margin-bottom: 1em;
    font-weight: 600;
    max-width: 75%;
    text-align: left; }
  /* line 14, src/assets/scss/components/_departments-block.scss */
  .departments-block__department-title-heading {
    color: #000;
    font-size: 1.5em;
    margin: 1em 0;
    font-weight: 600;
    max-width: 75%;
    text-align: left; }
  /* line 23, src/assets/scss/components/_departments-block.scss */
  .departments-block__staff-name {
    font-size: 1em;
    text-align: left;
    letter-spacing: .2px;
    margin: 8px 0;
    font-weight: 800; }
    @media (min-width: 576px) {
      /* line 23, src/assets/scss/components/_departments-block.scss */
      .departments-block__staff-name {
        font-size: 1.4em; } }
  /* line 35, src/assets/scss/components/_departments-block.scss */
  .departments-block__staff-link {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    color: #e93a97; }
  /* line 44, src/assets/scss/components/_departments-block.scss */
  .departments-block__staff-title {
    color: #410099;
    font-size: .75em;
    text-align: left;
    margin: .5em 0 1em;
    font-weight: 600; }
    @media (min-width: 576px) {
      /* line 44, src/assets/scss/components/_departments-block.scss */
      .departments-block__staff-title {
        font-size: 1em; } }
  /* line 57, src/assets/scss/components/_departments-block.scss */
  .departments-block__staff-image {
    max-height: 400px;
    width: 260px;
    height: 350px;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1em; }

/* create new custom classes from existing classes */
/* line 2, src/assets/scss/components/_backgrounds.scss */
.row-dark {
  background-color: #410099; }

/* line 10, src/assets/scss/components/_backgrounds.scss */
.bg-primary {
  background-color: #410099 !important; }

/* line 15, src/assets/scss/components/_backgrounds.scss */
.bg-dark {
  background-color: #e0dee6 !important; }

/* line 1, src/assets/scss/components/_bg-video.scss */
.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .15;
  overflow: hidden; }
  /* line 11, src/assets/scss/components/_bg-video.scss */
  .bg-video__content {
    height: 100%;
    width: 100%;
    object-fit: cover; }

/* line 1, src/assets/scss/components/_buttons.scss */
.btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.75em 1.85em;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 16px 16px 16px 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 1, src/assets/scss/components/_buttons.scss */
    .btn {
      transition: none; } }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn:hover {
    text-decoration: none;
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
    /* line 20, src/assets/scss/components/_buttons.scss */
    .btn:hover::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0; }
  /* line 26, src/assets/scss/components/_buttons.scss */
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  /* line 33, src/assets/scss/components/_buttons.scss */
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  /* line 49, src/assets/scss/components/_buttons.scss */
  .btn--white {
    display: inline-block;
    cursor: pointer;
    background-color: #ffffff;
    color: #fff;
    border: #000 #fff;
    border-radius: 16px 16px 16px 0;
    font-family: inherit;
    text-transform: uppercase;
    margin: 1rem 0;
    padding: 11px 25px;
    font-weight: 700;
    text-decoration: none;
    transition: all .2s;
    position: relative;
    font-size: 18px; }
    /* line 153, src/assets/scss/abstracts/_mixins.scss */
    .btn--white:hover, .btn--white:active, .btn--white:focus {
      background: #ebebeb;
      transition: all 0.3s ease; }
  /* line 53, src/assets/scss/components/_buttons.scss */
  .btn--green {
    display: inline-block;
    cursor: pointer;
    background-color: #46c35f;
    color: #fff;
    border: #000 #fff;
    border-radius: 16px 16px 16px 0;
    font-family: inherit;
    text-transform: uppercase;
    margin: 1rem 0;
    padding: 11px 25px;
    font-weight: 700;
    text-decoration: none;
    transition: all .2s;
    position: relative;
    font-size: 18px; }
    /* line 153, src/assets/scss/abstracts/_mixins.scss */
    .btn--green:hover, .btn--green:active, .btn--green:focus {
      background: #37a94e;
      transition: all 0.3s ease; }
  /* line 57, src/assets/scss/components/_buttons.scss */
  .btn--red {
    display: inline-block;
    cursor: pointer;
    background-color: #ed1c24;
    color: #fff;
    border: #000 #fff;
    border-radius: 16px 16px 16px 0;
    font-family: inherit;
    text-transform: uppercase;
    margin: 1rem 0;
    padding: 11px 25px;
    font-weight: 700;
    text-decoration: none;
    transition: all .2s;
    position: relative;
    font-size: 18px; }
    /* line 153, src/assets/scss/abstracts/_mixins.scss */
    .btn--red:hover, .btn--red:active, .btn--red:focus {
      background: #d01018;
      transition: all 0.3s ease; }

/* line 68, src/assets/scss/components/_buttons.scss */
.rto-btn, .rto-btn:link, .rto-btn:visited {
  display: inline-block;
  cursor: pointer;
  background-color: #442E8B;
  color: #fff;
  border: #fff;
  border-radius: 16px 16px 16px 0;
  font-family: inherit;
  text-transform: uppercase;
  margin: 1rem 0;
  padding: 11px 25px;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  position: relative;
  font-size: 18px;
  background-color: #442E8B;
  border-radius: 30px 22px 30px 0; }
  /* line 153, src/assets/scss/abstracts/_mixins.scss */
  .rto-btn:hover, .rto-btn:active, .rto-btn:focus, .rto-btn:link:hover, .rto-btn:link:active, .rto-btn:link:focus, .rto-btn:visited:hover, .rto-btn:visited:active, .rto-btn:visited:focus {
    background: #35246c;
    transition: all 0.3s ease; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.rto-btn:hover {
  text-decoration: none;
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  /* line 82, src/assets/scss/components/_buttons.scss */
  .rto-btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }

/* line 88, src/assets/scss/components/_buttons.scss */
.rto-btn:active, .rto-btn:focus {
  outline: none;
  transform: translateY(-3px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transform: scaleX(1.4) scaleY(1.6); }

/* line 96, src/assets/scss/components/_buttons.scss */
.rto-btn--white {
  display: inline-block;
  cursor: pointer;
  background-color: #442E8B;
  color: #fff;
  border: #000 #fff;
  border-radius: 16px 16px 16px 0;
  font-family: inherit;
  text-transform: uppercase;
  margin: 1rem 0;
  padding: 11px 25px;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  position: relative;
  font-size: 18px; }
  /* line 153, src/assets/scss/abstracts/_mixins.scss */
  .rto-btn--white:hover, .rto-btn--white:active, .rto-btn--white:focus {
    background: #35246c;
    transition: all 0.3s ease; }

/* line 100, src/assets/scss/components/_buttons.scss */
.rto-btn--green {
  display: inline-block;
  cursor: pointer;
  background-color: #442E8B;
  color: #fff;
  border: #000 #fff;
  border-radius: 16px 16px 16px 0;
  font-family: inherit;
  text-transform: uppercase;
  margin: 1rem 0;
  padding: 11px 25px;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  position: relative;
  font-size: 18px; }
  /* line 153, src/assets/scss/abstracts/_mixins.scss */
  .rto-btn--green:hover, .rto-btn--green:active, .rto-btn--green:focus {
    background: #35246c;
    transition: all 0.3s ease; }

/* line 104, src/assets/scss/components/_buttons.scss */
.rto-btn--red {
  display: inline-block;
  cursor: pointer;
  background-color: #ed1c24;
  color: #fff;
  border: #000 #fff;
  border-radius: 16px 16px 16px 0;
  font-family: inherit;
  text-transform: uppercase;
  margin: 1rem 0;
  padding: 11px 25px;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  position: relative;
  font-size: 18px; }
  /* line 153, src/assets/scss/abstracts/_mixins.scss */
  .rto-btn--red:hover, .rto-btn--red:active, .rto-btn--red:focus {
    background: #d01018;
    transition: all 0.3s ease; }

/* line 110, src/assets/scss/components/_buttons.scss */
.rto-btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s; }

/* line 123, src/assets/scss/components/_buttons.scss */
.rto-btn--animated {
  animation: moveInBottom .5s ease-out .75s;
  animation-fill-mode: backwards; }

/* line 129, src/assets/scss/components/_buttons.scss */
.btn-modal {
  display: inline-block;
  cursor: pointer;
  background-color: #442E8B;
  color: #fff;
  border: #fff;
  border-radius: 16px 16px 16px 0;
  font-family: inherit;
  text-transform: uppercase;
  margin: 1rem 0;
  padding: 11px 25px;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  position: relative;
  font-size: 18px; }
  /* line 153, src/assets/scss/abstracts/_mixins.scss */
  .btn-modal:hover, .btn-modal:active, .btn-modal:focus {
    background: #35246c;
    transition: all 0.3s ease; }

/*
.btn-text {
    &:link,
    &:visited {
        font-size: $default-font-size;
        color: $color-primary;
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px solid $color-primary;
        padding: 3px;
        transition: all .2s;
    }

    &:hover {
        background-color: $color-primary;
        color: $color-white;
        box-shadow: 0 1rem 2rem rgba($color-black, .15);
        transform: translateY(-2px);
    }

    &:active {
        box-shadow: 0 .5rem 1rem rgba($color-black, .15);
        transform: translateY(0);
    }
}




.btn-primary {
    color: #fff;
    background-color: $color-primary-dark;
    border-color: $color-primary-dark;

    &:hover {
        background: darken($color-primary-dark, 10%)
    }
}



.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: $color-primary-dark !important;
    border-color: $color-primary-dark !important; 
}


.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
    background-color: $color-primary-light !important; 
    border-color: $color-primary-light !important; 
}

.btn-outline-secondary {
    color:$color-primary-light;
    border-color:$color-primary-light;

    &:hover {
        background: darken($color-primary-light, 10%)

    }

}


*/
/* ------- customize primary buttons -------- */
/* line 205, src/assets/scss/components/_buttons.scss */
.btn-primary {
  color: #fff;
  background-color: #44318B;
  border-color: #36276f; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-primary:hover {
    color: #fff;
    background-color: #322465;
    border-color: #4d389e; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #322465;
    border-color: #4d389e;
    box-shadow: 0 0 0 0.2rem rgba(84, 71, 132, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #44318B;
    border-color: #36276f; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #563eb1;
    border-color: #0d091a; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(84, 71, 132, 0.5); }

/* line 209, src/assets/scss/components/_buttons.scss */
.btn-outline-primary {
  color: #44318B;
  border-color: #44318B; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-primary:hover {
    color: #222222;
    background-color: #4d389e;
    border-color: #44318B; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(68, 49, 139, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #44318B;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #4d389e;
    border-color: #44318B; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 49, 139, 0.5); }

/* line 213, src/assets/scss/components/_buttons.scss */
.btn-secondary {
  color: #fff;
  background-color: #DC1E8A;
  border-color: #ba1975; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-secondary:hover {
    color: #fff;
    background-color: #af186e;
    border-color: #e33196; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #af186e;
    border-color: #e33196;
    box-shadow: 0 0 0 0.2rem rgba(197, 60, 138, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #DC1E8A;
    border-color: #ba1975; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #e647a1;
    border-color: #550c36; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(197, 60, 138, 0.5); }

/* line 217, src/assets/scss/components/_buttons.scss */
.btn-outline-secondary {
  color: #DC1E8A;
  border-color: #DC1E8A; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-secondary:hover {
    color: #222222;
    background-color: #e33196;
    border-color: #DC1E8A; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 30, 138, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #DC1E8A;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #e33196;
    border-color: #DC1E8A; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 30, 138, 0.5); }

/* use @mixins */
/* line 226, src/assets/scss/components/_buttons.scss */
.btn-custom {
  color: #fff;
  background-color: red;
  border-color: white; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-custom:hover {
    color: #fff;
    background-color: red;
    border-color: red; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-custom:focus, .btn-custom.focus {
    color: #fff;
    background-color: red;
    border-color: red;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-custom.disabled, .btn-custom:disabled {
    color: #fff;
    background-color: red;
    border-color: white; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-custom:not(:disabled):not(.disabled):active, .btn-custom:not(:disabled):not(.disabled).active,
  .show > .btn-custom.dropdown-toggle {
    color: #fff;
    background-color: #cc0000;
    border-color: #dfdfdf; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-custom:not(:disabled):not(.disabled):active:focus, .btn-custom:not(:disabled):not(.disabled).active:focus,
    .show > .btn-custom.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* sample file */
/* line 1, src/assets/scss/components/_cards.scss */
.card {
  border: none;
  border-radius: 6px; }
  /* line 4, src/assets/scss/components/_cards.scss */
  .card .card-body {
    padding: 43px 36px; }
    /* line 6, src/assets/scss/components/_cards.scss */
    .card .card-body .card-title {
      font-size: 18px;
      margin-bottom: 7px; }

/* line 1, src/assets/scss/components/_forms.scss */
.form-control {
  background-color: #f6f7fb;
  border: none;
  font-size: 14px; }
  /* line 5, src/assets/scss/components/_forms.scss */
  .form-control:focus {
    background-color: #f6f7fb;
    border: 1px solid #ced4da;
    box-shadow: none;
    border: none; }

/* line 15, src/assets/scss/components/_forms.scss */
input[type=email].input-active, input[type=email]:focus, input[type=text].input-active, input[type=tel].input-active, input[type=text]:focus, textarea.input-active, textarea:focus, input[type=password].input-active, input[type=password]:focus, input[type=number].input-active, input[type=number]:focus {
  background-position: 0 0;
  box-shadow: none;
  outline: 0;
  border: 3px solid #092f55; }

/* line 2, src/assets/scss/components/_help-block.scss */
.help-block {
  background: url(../../assets/img/contact-block-background-trimmed.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: -ms-flexbox;
  display: flex;
  min-height: 33vh;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center; }
  /* line 13, src/assets/scss/components/_help-block.scss */
  .help-block__box1 {
    color: #fff; }
    /* line 15, src/assets/scss/components/_help-block.scss */
    .help-block__box1 h2 {
      font-size: 2.5rem;
      margin-bottom: 10px; }
  /* line 22, src/assets/scss/components/_help-block.scss */
  .help-block__box2 {
    color: #ffffff; }
    /* line 25, src/assets/scss/components/_help-block.scss */
    .help-block__box2 p {
      color: #fff;
      font-size: 1.25em; }

/* line 2, src/assets/scss/components/_compare-plans-block.scss */
.compare-plans-block {
  background: #410099;
  height: 16em;
  margin-top: 2em;
  width: 100%;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    /* line 2, src/assets/scss/components/_compare-plans-block.scss */
    .compare-plans-block {
      background: radial-gradient(62% 348% at 12% 96%, #e31b93 0, #e31b93 50%, #e31b93 calc(19% + 1px), #E31B91 23%, #43318b calc(50% + 1px), #43318b 43%, #43318b calc(50% + 1px), #43318b 100%);
      height: auto; } }
  /* line 20, src/assets/scss/components/_compare-plans-block.scss */
  .compare-plans-block__title {
    flex-grow: 3;
    font-size: 2.25em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 20, src/assets/scss/components/_compare-plans-block.scss */
      .compare-plans-block__title {
        text-align: left; } }
  /* line 30, src/assets/scss/components/_compare-plans-block.scss */
  .compare-plans-block__copy {
    flex-grow: 1;
    /* max-width: 0; */
    /* margin-right: 30px; */
    font-size: 1em;
    text-align: center;
    margin-top: 30px;
    word-break: break-word; }
    @media (min-width: 576px) {
      /* line 30, src/assets/scss/components/_compare-plans-block.scss */
      .compare-plans-block__copy {
        text-align: left;
        max-width: 300px;
        /* margin-right: 30px; */ } }
  /* line 47, src/assets/scss/components/_compare-plans-block.scss */
  .compare-plans-block__contact {
    flex-grow: 1;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 47, src/assets/scss/components/_compare-plans-block.scss */
      .compare-plans-block__contact {
        text-align: left; } }

/* line 1, src/assets/scss/components/_modals.scss */
.modal-content {
  width: 500px; }
  @media (max-width: 575px) {
    /* line 1, src/assets/scss/components/_modals.scss */
    .modal-content {
      width: 300px; } }
  /* line 6, src/assets/scss/components/_modals.scss */
  .modal-content .modal-header {
    justify-content: center;
    border: none; }
  /* line 10, src/assets/scss/components/_modals.scss */
  .modal-content .modal-body {
    padding: 0px 40px; }
  /* line 13, src/assets/scss/components/_modals.scss */
  .modal-content .modal-footer {
    border: none;
    justify-content: flex-start;
    padding: 5px 0px 20px 39px; }

/* line 1, src/assets/scss/components/_masthead.scss */
.main-heading {
  color: #ffffff;
  font-size: 2.25em;
  font-weight: 600;
  margin-bottom: 10px; }

/* line 5, src/assets/scss/components/_masthead.scss */
.sub-heading {
  color: #ffffff;
  font-size: 1.25em;
  font-weight: 400;
  margin-bottom: 20px; }

/* line 9, src/assets/scss/components/_masthead.scss */
.copy {
  color: #ffffff;
  font-size: 1em;
  font-weight: 400;
  margin: 34px 0px; }

/* line 13, src/assets/scss/components/_masthead.scss */
.active a {
  color: #ffffff;
  text-decoration: none; }

/* line 20, src/assets/scss/components/_masthead.scss */
.masthead {
  /*background-image: url(../../assets/img/Hero-section-576px.jpg); */
  background: #410099;
  /* min-height: 100vh; */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: inherit;
  min-height: 34em;
  background-position: 50% 0; }
  @media (min-width: 768px) {
    /* line 20, src/assets/scss/components/_masthead.scss */
    .masthead {
      background-image: url(../../assets/img/Hero-section-867px.jpg);
      background-size: cover;
      background-position: 55% 0;
      background-repeat: no-repeat;
      min-height: 38em; } }
  @media (min-width: 1200px) {
    /* line 20, src/assets/scss/components/_masthead.scss */
    .masthead {
      background-image: url(../../assets/img/header-home-page.png);
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 auto;
      /* background-attachment: fixed; */
      background-position: center center;
      min-height: 41em;
      /* z-index: -1; */ } }
  /* line 52, src/assets/scss/components/_masthead.scss */
  .masthead .banner-heading {
    color: #410099;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important;
    color: #ffffff;
    margin-top: 2em;
    max-width: 80%;
    font-weight: 700;
    font-size: 1.45rem; }
    @media (min-width: 768px) {
      /* line 52, src/assets/scss/components/_masthead.scss */
      .masthead .banner-heading {
        width: auto;
        margin-right: 1.5em;
        font-size: 1.65em;
        text-shadow: none; } }
    @media (min-width: 992px) {
      /* line 52, src/assets/scss/components/_masthead.scss */
      .masthead .banner-heading {
        font-size: 1.75rem;
        max-width: 65%;
        text-shadow: none; } }
  /* line 75, src/assets/scss/components/_masthead.scss */
  .masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 79, src/assets/scss/components/_masthead.scss */
  .masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }

/* line 87, src/assets/scss/components/_masthead.scss */
.banner-text {
  margin-top: 42px; }

/* line 91, src/assets/scss/components/_masthead.scss */
.banner-text h1 {
  color: #003366;
  max-width: 80%;
  font-size: 1.75rem; }
  @media (min-width: 768px) {
    /* line 91, src/assets/scss/components/_masthead.scss */
    .banner-text h1 {
      color: #036;
      max-width: 95%;
      font-size: 2.25rem;
      margin-bottom: 1rem; } }

/* line 105, src/assets/scss/components/_masthead.scss */
.banner-text {
  max-width: none; }
  @media (min-width: 768px) {
    /* line 105, src/assets/scss/components/_masthead.scss */
    .banner-text {
      max-width: 80%; } }

/* line 116, src/assets/scss/components/_masthead.scss */
.banner-lead {
  font-size: 1.25rem;
  max-width: none;
  margin: 10px 0;
  font-weight: 700;
  color: #ffffff; }
  @media (min-width: 768px) {
    /* line 116, src/assets/scss/components/_masthead.scss */
    .banner-lead {
      max-width: 90%; } }

/* line 131, src/assets/scss/components/_masthead.scss */
.banner-btn {
  font-size: 1rem;
  /* border-radius: 0.3rem; */
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  color: #fff;
  background-color: #339999;
  border-color: #17a2b8;
  /* padding: .5rem 1rem; */
  /* font-size: 1.25rem; */
  line-height: 1.5;
  cursor: pointer; }

/* line 156, src/assets/scss/components/_masthead.scss */
.banner-btn:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  text-decoration: none; }

/* 
*****************
About Page masthead 
*****************
*/
/* line 171, src/assets/scss/components/_masthead.scss */
.about-masthead {
  background: #410099;
  padding: 2em 0;
  min-height: inherit;
  position: relative;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-position: 90% top; }
  @media (min-width: 768px) {
    /* line 171, src/assets/scss/components/_masthead.scss */
    .about-masthead {
      background-image: url(../../assets/img/Header.png); } }
  /* line 184, src/assets/scss/components/_masthead.scss */
  .about-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none;
    list-style: none; }
  /* line 189, src/assets/scss/components/_masthead.scss */
  .about-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 48px;
    width: 70%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 194, src/assets/scss/components/_masthead.scss */
  .about-masthead .main-heading {
    color: #ffffff;
    font-size: 2.5em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 198, src/assets/scss/components/_masthead.scss */
  .about-masthead .sub-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 20px;
    max-width: 80%; }
  /* line 203, src/assets/scss/components/_masthead.scss */
  .about-masthead .copy {
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin: 4em 0 20px 0;
    max-width: 85%;
    padding-top: 0; }
    @media (min-width: 576px) {
      /* line 203, src/assets/scss/components/_masthead.scss */
      .about-masthead .copy {
        padding-top: 8em; } }
  /* line 216, src/assets/scss/components/_masthead.scss */
  .about-masthead .active a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none; }

/* 
*****************
Foundation Home Page masthead 
*****************
*/
/* line 233, src/assets/scss/components/_masthead.scss */
.foundation-masthead {
  background: #43318b;
  padding: 2em 0;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: inherit;
  /*
     &__main-heading {
        color: $white;
        font-size: 2.25em;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__sub-heading {
        color: $white;
        font-size: 1.25em;
        font-weight: 600;
        margin-bottom: 10px;
        max-width: 65%;
    }

    &__copy {
    color: $white;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 20px;
    max-width: 65%;
    margin-top: 4em;
}


    */ }
  @media (min-width: 768px) {
    /* line 233, src/assets/scss/components/_masthead.scss */
    .foundation-masthead {
      background-image: url(../../assets/img/Insurance-Header.png); } }
  /* line 275, src/assets/scss/components/_masthead.scss */
  .foundation-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 279, src/assets/scss/components/_masthead.scss */
  .foundation-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 285, src/assets/scss/components/_masthead.scss */
  .foundation-masthead .breadcrumb-items {
    background: #DC1E8A;
    font-weight: 600;
    border-bottom-right-radius: 30px;
    list-style: none; }
  /* line 289, src/assets/scss/components/_masthead.scss */
  .foundation-masthead .active a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none; }

/* 
*****************
Foundation Index Page masthead 
*****************
*/
/* line 305, src/assets/scss/components/_masthead.scss */
.foundation-index-masthead {
  background: #891c56;
  padding: 2em 0;
  min-height: inherit;
  position: relative;
  color: #fff; }
  /* line 309, src/assets/scss/components/_masthead.scss */
  .foundation-index-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 313, src/assets/scss/components/_masthead.scss */
  .foundation-index-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 317, src/assets/scss/components/_masthead.scss */
  .foundation-index-masthead .main-heading {
    color: #ffffff;
    font-size: 2.25em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 321, src/assets/scss/components/_masthead.scss */
  .foundation-index-masthead .sub-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 20px; }
  /* line 325, src/assets/scss/components/_masthead.scss */
  .foundation-index-masthead .active a {
    color: #ffffff;
    text-decoration: none; }

/* 
*****************
RTO Classifieds Page masthead 
*****************
*/
/* line 340, src/assets/scss/components/_masthead.scss */
.classifieds-masthead {
  background: #410099;
  padding: 2em 0;
  min-height: inherit;
  position: relative;
  color: #fff; }
  /* line 344, src/assets/scss/components/_masthead.scss */
  .classifieds-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 348, src/assets/scss/components/_masthead.scss */
  .classifieds-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 353, src/assets/scss/components/_masthead.scss */
  .classifieds-masthead .main-heading {
    color: #ffffff;
    font-size: 2.25em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 357, src/assets/scss/components/_masthead.scss */
  .classifieds-masthead .sub-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 20px; }
  /* line 361, src/assets/scss/components/_masthead.scss */
  .classifieds-masthead .copy {
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin: 34px 0px; }
  /* line 365, src/assets/scss/components/_masthead.scss */
  .classifieds-masthead .active a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none; }

/* 
*****************
Programs & Services Page masthead 
*****************
*/
/* line 380, src/assets/scss/components/_masthead.scss */
.programs-services-masthead {
  background: #43318b;
  padding: 2em 0;
  position: relative;
  background-image: url(../../assets/img/Header.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: inherit; }
  /* line 392, src/assets/scss/components/_masthead.scss */
  .programs-services-masthead__main-heading {
    color: #ffffff;
    font-size: 2.25em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 399, src/assets/scss/components/_masthead.scss */
  .programs-services-masthead__sub-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 10px;
    max-width: 65%; }
  /* line 407, src/assets/scss/components/_masthead.scss */
  .programs-services-masthead__copy {
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 20px;
    max-width: 65%;
    padding-top: 0; }
    @media (min-width: 576px) {
      /* line 407, src/assets/scss/components/_masthead.scss */
      .programs-services-masthead__copy {
        padding-top: 8em; } }
  /* line 421, src/assets/scss/components/_masthead.scss */
  .programs-services-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 425, src/assets/scss/components/_masthead.scss */
  .programs-services-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 430, src/assets/scss/components/_masthead.scss */
  .programs-services-masthead .breadcrumb-items {
    /*-ms-flex-line-pack: center;
    /* align-content: center; */
    /*-ms-flex-pack: center;
    /* justify-content: center; */
    background: #DC1E8A;
    font-weight: 600;
    border-bottom-right-radius: 30px;
    z-index: 0;
    list-style: none; }
    /* line 442, src/assets/scss/components/_masthead.scss */
    .programs-services-masthead .breadcrumb-items .active a {
      color: #ffffff;
      font-weight: bold;
      text-decoration: none; }

/* 
*****************
Resources Page masthead 
*****************
*/
/* line 463, src/assets/scss/components/_masthead.scss */
.resources-masthead {
  background: #43318b;
  padding: 2em 0;
  position: relative;
  background-image: url(../../assets/img/resources-page-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-position: bottom; }
  /* line 476, src/assets/scss/components/_masthead.scss */
  .resources-masthead__main-heading {
    color: #ffffff;
    font-size: 2.25em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 483, src/assets/scss/components/_masthead.scss */
  .resources-masthead__sub-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 10px;
    max-width: 65%; }
  /* line 491, src/assets/scss/components/_masthead.scss */
  .resources-masthead__copy {
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 20px;
    max-width: 65%;
    margin-top: 4em;
    padding-top: 0; }
    @media (min-width: 576px) {
      /* line 491, src/assets/scss/components/_masthead.scss */
      .resources-masthead__copy {
        padding-top: 8em; } }

/* line 508, src/assets/scss/components/_masthead.scss */
.breadcrumb-wrapper {
  background: transparent;
  position: relative;
  border-bottom-right-radius: 30px;
  margin: 0;
  top: -32px;
  z-index: 1;
  list-style: none; }

/* line 512, src/assets/scss/components/_masthead.scss */
.breadcrumb-wrapper:before {
  content: "";
  background-color: #DC1E8A;
  position: absolute;
  height: 100%;
  width: 45%;
  left: auto;
  z-index: -1;
  border-bottom-right-radius: 30px; }

/* line 516, src/assets/scss/components/_masthead.scss */
.breadcrumb-items {
  /*-ms-flex-line-pack: center;
    /* align-content: center; */
  /*-ms-flex-pack: center;
    /* justify-content: center; */
  background: #DC1E8A;
  font-weight: 600;
  border-bottom-right-radius: 30px;
  z-index: 0;
  list-style: none; }

/* line 530, src/assets/scss/components/_masthead.scss */
.active a {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none; }

/* 
*****************
Insurance Main Page masthead 
*****************
*/
/* line 545, src/assets/scss/components/_masthead.scss */
.insurance-masthead {
  background: #43318b;
  padding: 2em 0;
  position: relative;
  background-image: url(../../assets/img/Insurance-Header.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: inherit; }
  /* line 557, src/assets/scss/components/_masthead.scss */
  .insurance-masthead__main-heading {
    color: #ffffff;
    font-size: 2.25em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 564, src/assets/scss/components/_masthead.scss */
  .insurance-masthead__sub-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 10px;
    max-width: none; }
    @media (min-width: 768px) {
      /* line 564, src/assets/scss/components/_masthead.scss */
      .insurance-masthead__sub-heading {
        max-width: 65%; } }
  /* line 578, src/assets/scss/components/_masthead.scss */
  .insurance-masthead__copy {
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 20px;
    max-width: none;
    margin-top: 4em; }
    @media (min-width: 768px) {
      /* line 578, src/assets/scss/components/_masthead.scss */
      .insurance-masthead__copy {
        max-width: 65%; } }
  /* line 594, src/assets/scss/components/_masthead.scss */
  .insurance-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 598, src/assets/scss/components/_masthead.scss */
  .insurance-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 602, src/assets/scss/components/_masthead.scss */
  .insurance-masthead .breadcrumb-items {
    background: #DC1E8A;
    font-weight: 600;
    border-bottom-right-radius: 30px;
    list-style: none; }
    /* line 605, src/assets/scss/components/_masthead.scss */
    .insurance-masthead .breadcrumb-items .active a {
      color: #ffffff;
      font-weight: bold;
      text-decoration: none; }

/* 
*****************
Insurance sub-section Masthead 
*****************
*/
/* line 621, src/assets/scss/components/_masthead.scss */
.primary-brand-color-masthead {
  background: #410099;
  padding: 2em 0;
  position: relative; }
  /* line 628, src/assets/scss/components/_masthead.scss */
  .primary-brand-color-masthead__main-heading {
    color: #ffffff;
    font-size: 2.25em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 635, src/assets/scss/components/_masthead.scss */
  .primary-brand-color-masthead__secondary-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 600;
    max-width: 73%; }
  /* line 643, src/assets/scss/components/_masthead.scss */
  .primary-brand-color-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 647, src/assets/scss/components/_masthead.scss */
  .primary-brand-color-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 651, src/assets/scss/components/_masthead.scss */
  .primary-brand-color-masthead .active {
    color: #ffffff;
    text-decoration: none;
    font-weight: bolder; }

/*
.breadcrumb-items {

    background: #e93a97;
    font-weight: 600;
    border-bottom-right-radius: 30px;
   
   &>div {
      color: #fff;
   }
  
   ul > li{
  list-style-type: none;
}
   a {
    color: #43318b; 
      
   }
   }

    */
/* 
*****************
Insurance Sub Page Red masthead 
*****************
*/
/* line 689, src/assets/scss/components/_masthead.scss */
.red-brand-color-masthead {
  background: #e50c19;
  padding: 2em 0;
  position: relative; }
  /* line 695, src/assets/scss/components/_masthead.scss */
  .red-brand-color-masthead .active a {
    color: #ffffff;
    text-decoration: none; }

/* 
*****************
Search List  masthead 
*****************
*/
/* line 710, src/assets/scss/components/_masthead.scss */
.searchlist-masthead {
  background: #410099;
  padding: 2em 0;
  min-height: 300px;
  position: relative;
  color: #fff; }
  /* line 713, src/assets/scss/components/_masthead.scss */
  .searchlist-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 717, src/assets/scss/components/_masthead.scss */
  .searchlist-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 721, src/assets/scss/components/_masthead.scss */
  .searchlist-masthead .main-heading {
    color: #ffffff;
    font-size: 2.25em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 725, src/assets/scss/components/_masthead.scss */
  .searchlist-masthead .sub-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 20px; }
  /* line 729, src/assets/scss/components/_masthead.scss */
  .searchlist-masthead .copy {
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin: 34px 0px; }
  /* line 733, src/assets/scss/components/_masthead.scss */
  .searchlist-masthead .active a {
    color: #ffffff;
    text-decoration: none; }

/* 
*****************
Join Us Page  masthead 
*****************
*/
/* line 747, src/assets/scss/components/_masthead.scss */
.join-us-masthead {
  background: #410099;
  padding: 2em 0;
  min-height: inherit;
  position: relative;
  color: #fff; }
  /* line 751, src/assets/scss/components/_masthead.scss */
  .join-us-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 755, src/assets/scss/components/_masthead.scss */
  .join-us-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 759, src/assets/scss/components/_masthead.scss */
  .join-us-masthead .main-heading {
    color: #ffffff;
    font-size: 2.25em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 763, src/assets/scss/components/_masthead.scss */
  .join-us-masthead .sub-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 20px; }
  /* line 767, src/assets/scss/components/_masthead.scss */
  .join-us-masthead .copy {
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin: 34px 0px; }
  /* line 771, src/assets/scss/components/_masthead.scss */
  .join-us-masthead .active a {
    color: #ffffff;
    text-decoration: none; }

/* 
*****************
Events Calendar Listing  masthead 
*****************
*/
/* line 786, src/assets/scss/components/_masthead.scss */
.events-calendar-masthead {
  background: #410099;
  padding: 2em 0;
  min-height: inherit;
  position: relative;
  color: #fff; }
  /* line 790, src/assets/scss/components/_masthead.scss */
  .events-calendar-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 794, src/assets/scss/components/_masthead.scss */
  .events-calendar-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 798, src/assets/scss/components/_masthead.scss */
  .events-calendar-masthead .main-heading {
    color: #ffffff;
    font-size: 2.25em;
    font-weight: 600;
    margin-bottom: 10px; }
  /* line 802, src/assets/scss/components/_masthead.scss */
  .events-calendar-masthead .sub-heading {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 20px; }
  /* line 806, src/assets/scss/components/_masthead.scss */
  .events-calendar-masthead .copy {
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin: 34px 0px; }
  /* line 810, src/assets/scss/components/_masthead.scss */
  .events-calendar-masthead .active a {
    color: #ffffff;
    text-decoration: none; }

/* 
*****************
Blank Listing  masthead 
*****************
*/
/* line 823, src/assets/scss/components/_masthead.scss */
.blank-masthead {
  background: transparent;
  padding: 2em 0;
  min-height: 0;
  position: relative;
  color: #fff; }
  /* line 827, src/assets/scss/components/_masthead.scss */
  .blank-masthead .breadcrumb-wrapper {
    background: transparent;
    position: relative;
    border-bottom-right-radius: 30px;
    margin: 0;
    top: -32px;
    z-index: 1;
    list-style: none; }
  /* line 831, src/assets/scss/components/_masthead.scss */
  .blank-masthead .breadcrumb-wrapper:before {
    content: "";
    background-color: #DC1E8A;
    position: absolute;
    height: 100%;
    width: 45%;
    left: auto;
    z-index: -1;
    border-bottom-right-radius: 30px; }
  /* line 835, src/assets/scss/components/_masthead.scss */
  .blank-masthead .breadcrumb-items {
    background: #DC1E8A;
    font-weight: 600;
    border-bottom-right-radius: 30px;
    list-style: none; }

/* breadcrumb items */
/* line 844, src/assets/scss/components/_masthead.scss */
.breadcrumb-items {
  background: #DC1E8A;
  font-weight: 600;
  border-bottom-right-radius: 30px;
  list-style: none; }

@media only screen and (max-width: 868px) {
  /* line 850, src/assets/scss/components/_masthead.scss */
  .breadcrumb-wrapper:before {
    display: none; } }

/* line 856, src/assets/scss/components/_masthead.scss */
.breadcrumb-items a {
  color: #fff; }

/* line 1, src/assets/scss/components/_testimonial.scss */
.bg-testimonial {
  background-color: #f1430ffc !important; }

/* ---------
 Return to Top JS Button
 ------------
 */
/* line 5, src/assets/scss/components/_return-to-top.scss */
#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: black;
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

/* line 25, src/assets/scss/components/_return-to-top.scss */
#return-to-top i {
  transition: all .3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
  margin: 0;
  position: relative;
  left: 16px;
  top: 13px;
  font-size: 19px;
  transition: all .3s ease;
  background: transparent;
  padding: inherit; }

@media only screen and (max-width: 800px) {
  /* line 45, src/assets/scss/components/_return-to-top.scss */
  #return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 0px;
    top: 13px;
    font-size: 19px;
    transition: all .3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; } }

/* line 62, src/assets/scss/components/_return-to-top.scss */
#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9); }

/* line 65, src/assets/scss/components/_return-to-top.scss */
#return-to-top:hover i {
  color: #fff;
  top: 5px; }

/* line 1, src/assets/scss/components/_section-block.scss */
.generic-block {
  line-height: 1.5;
  text-rendering: optimizelegibility;
  padding: 100px 0;
  color: #444444;
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-color: #ffffff; }

/* line 15, src/assets/scss/components/_section-block.scss */
.page-section {
  padding: 1.2rem 0; }
  @media (min-width: 768px) {
    /* line 15, src/assets/scss/components/_section-block.scss */
    .page-section {
      padding: 4.5rem 0; } }

/*
.section {
    font-size: 16px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    padding: 2rem 0rem;
    color: #444444;
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}




:root {
    --base:              #f7392b;
    --bg-blend:          multiply;
    --blur:              0px;
    --fg-blend:          lighten;
    --foreground:        #16006f;
    --opacity:           1;
    --spacing:           40px;
  }
  .img-wrapper {
    background-color:    var(--base);
    display:             flex;
    flex:                1 1 100%;
    height:              100vh;
    width: 50%;
    overflow:            hidden;
    padding:             var(--spacing);
    position:            relative;
  }
  
  .img-wrapper img {
    filter:              grayscale(100%) contrast(1) blur(var(--blur));
    flex:                1 0 100%;
    height:              100%;
    max-width:           100%;
    mix-blend-mode:      var(--bg-blend);
    object-fit:          cover;
    opacity:             var(--opacity);
    position:            relative;
    width:               100%;
  }
  
  .img-wrapper::before {
    background-color:    var(--foreground);
    bottom:              0;
    content:             '';
    height:              100%;
    left:                0;
    mix-blend-mode:      var(--fg-blend);
    position:            absolute;
    right:               0;
    top:                 0;
    width:               100%;
    z-index:             1;
  }
  
  
  
  .text-max-width {
    max-width: 35em
  } 


  */
/* line 1, src/assets/scss/components/_subscribe.scss */
.subscribe {
  background: #442E8B;
  padding: 2em 0; }
  /* line 5, src/assets/scss/components/_subscribe.scss */
  .subscribe .form-control {
    background-color: #ffffff;
    border: none;
    font-size: 14px;
    border: solid 1px #E12490;
    border-radius: 2px; }
  /* line 14, src/assets/scss/components/_subscribe.scss */
  .subscribe h2 {
    color: #410099;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important;
    color: #8fcea5; }
  /* line 19, src/assets/scss/components/_subscribe.scss */
  .subscribe p {
    color: #ffffff;
    margin-bottom: 1em; }
  /* line 24, src/assets/scss/components/_subscribe.scss */
  .subscribe button, .subscribe input, .subscribe optgroup, .subscribe select, .subscribe textarea {
    margin: 0;
    font-family: inherit;
    font-size: medium;
    line-height: inherit; }
  /* line 32, src/assets/scss/components/_subscribe.scss */
  .subscribe .agree-terms-text {
    color: #ffffff;
    font-size: 14px; }

/* line 40, src/assets/scss/components/_subscribe.scss */
.foundation-subscribe {
  background: #891c56;
  padding: 3em 0; }
  /* line 44, src/assets/scss/components/_subscribe.scss */
  .foundation-subscribe .form-control {
    background-color: #ffffff;
    border: none;
    font-size: 14px;
    border: solid 1px #E12490;
    border-radius: 2px; }
  /* line 53, src/assets/scss/components/_subscribe.scss */
  .foundation-subscribe h2 {
    color: #410099;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important;
    color: #8fcea5; }
  /* line 58, src/assets/scss/components/_subscribe.scss */
  .foundation-subscribe p {
    color: #ffffff;
    margin-bottom: 1em; }
  /* line 63, src/assets/scss/components/_subscribe.scss */
  .foundation-subscribe button, .foundation-subscribe input, .foundation-subscribe optgroup, .foundation-subscribe select, .foundation-subscribe textarea {
    margin: 0;
    font-family: inherit;
    font-size: medium;
    line-height: inherit; }
  /* line 71, src/assets/scss/components/_subscribe.scss */
  .foundation-subscribe .agree-terms-text {
    color: #ffffff;
    font-size: 14px; }

/* line 2, src/assets/scss/layout/_footer.scss */
footer {
  position: relative;
  background: #ebebeb;
  padding: 1em 0em; }
  /* line 6, src/assets/scss/layout/_footer.scss */
  footer footer a {
    color: #212529;
    font-weight: 800; }
  /* line 11, src/assets/scss/layout/_footer.scss */
  footer ul {
    list-style: none; }
    /* line 14, src/assets/scss/layout/_footer.scss */
    footer ul li {
      margin-bottom: 5px; }
  /* line 19, src/assets/scss/layout/_footer.scss */
  footer h3 {
    font-size: 1.75em;
    margin-bottom: 1em;
    font-weight: 700; }
  /* line 25, src/assets/scss/layout/_footer.scss */
  footer .small, footer small {
    color: black;
    font-size: .75rem;
    font-weight: 800;
    font-family: inherit; }

/* line 33, src/assets/scss/layout/_footer.scss */
.social-media-footer {
  display: -ms-flexbox;
  /* display: flex; */
  -ms-flex-flow: row;
  /* flex-flow: row; */
  -ms-flex-pack: center;
  /* justify-content: center; 
    border-bottom: 1px solid #717375; */
  margin: 1em 0;
  margin-left: 0px; }
  @media (min-width: 576px) {
    /* line 33, src/assets/scss/layout/_footer.scss */
    .social-media-footer {
      margin-left: -15px; } }

/* line 50, src/assets/scss/layout/_footer.scss */
.social-media-footer a {
  color: #717375; }

/* line 55, src/assets/scss/layout/_footer.scss */
.footer-copyright {
  overflow: hidden;
  background: #002b3b;
  font-size: .9rem;
  color: rgba(255, 255, 255, 0.6);
  position: relative;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  z-index: 3; }

/* line 68, src/assets/scss/layout/_footer.scss */
.footer-copyright a {
  color: #ffffff; }

/* line 73, src/assets/scss/layout/_footer.scss */
.no-margin {
  margin: 0 !important; }

/* line 77, src/assets/scss/layout/_footer.scss */
.footer .social-buttons a {
  margin-right: 1rem;
  color: inherit; }

/* line 82, src/assets/scss/layout/_footer.scss */
.border-bottom-line {
  border-bottom: 1px solid #999898; }

/* line 86, src/assets/scss/layout/_footer.scss */
.under-footer ul li {
  float: left;
  list-style: none;
  padding: 5px; }

/* line 93, src/assets/scss/layout/_footer.scss */
.fa, .fab, .fal, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #ffffff;
  background: #891c56;
  border-radius: 18px 18px 18px 0;
  padding: 10px 16px;
  min-width: 35px; }

/* line 109, src/assets/scss/layout/_footer.scss */
.fa-facebook-f:hover {
  color: #f9f6f7;
  background: #490f2e; }

/* line 114, src/assets/scss/layout/_footer.scss */
.fa-twitter:hover {
  color: #f9f6f7;
  background: #490f2e; }

/* line 119, src/assets/scss/layout/_footer.scss */
.fa-youtube:hover {
  color: #f9f6f7;
  background: #490f2e; }

/* line 124, src/assets/scss/layout/_footer.scss */
.fa-linkedin-in:hover {
  color: #f9f6f7;
  background: #490f2e; }

/*
  .section a, a:hover {
      color: #11c7e5;
       font-weight: bolder;
      margin-left: 5px;
      text-decoration: none;
      background-color: transparent;
      -webkit-text-decoration-skip: objects;
  }
  */
/* line 139, src/assets/scss/layout/_footer.scss */
.copyright {
  color: #b9b9ba;
  float: right; }

@media only screen and (max-width: 767px) {
  /* line 145, src/assets/scss/layout/_footer.scss */
  .copyright {
    color: #b9b9ba;
    float: left; } }

/* line 152, src/assets/scss/layout/_footer.scss */
.sub-footer {
  background-color: #ffffff !important;
  color: #000; }
  /* line 156, src/assets/scss/layout/_footer.scss */
  .sub-footer p {
    font-size: 1.1em; }

/*.header {
    height: 85vh;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.8),
        rgba($color-primary-dark, 0.8)),
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/902426/hero--large.jpg);
    background-size: cover;
    background-position: top;
    position: relative;
    z-index: -1;
    

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
        height: 95vh;
    }

    @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
            only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
            only screen and (min-width: 125em) {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-primary-light, 0.8),
            rgba($color-primary-dark, 0.8)),
        url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/902426/hero--large.jpg);
    }

    &__logo-box {
        position: absolute;
        top: 4rem;
        left: 4rem;
    }

    &__logo {
        height: 3.5rem;
    }
    
    &__text-box {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}
*/
@keyframes fadeInAlert {
  from {
    margin-top: -75px;
    opacity: 0; }
  to {
    margin-top: 0px;
    opacity: 1; } }

/* line 62, src/assets/scss/layout/_header.scss */
#homepage-popup-alert-warning {
  animation: fadeInAlert 1s ease-out .2s forwards;
  background-color: #f96868;
  height: 75px;
  margin-top: -75px;
  margin-bottom: 0;
  opacity: 0; }
  /* line 70, src/assets/scss/layout/_header.scss */
  #homepage-popup-alert-warning p {
    color: #ffffff; }
    @media (min-width: 768px) {
      /* line 70, src/assets/scss/layout/_header.scss */
      #homepage-popup-alert-warning p {
        font-size: 1.2rem; } }

/* line 1, src/assets/scss/layout/_navigation.scss */
.super-navigation {
  background: #ebebeb;
  font: 16px/1.1 Matterhorn,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  letter-spacing: 0;
  color: #000;
  opacity: 1;
  scroll-behavior: smooth;
  transform: translateZ(0);
  -webkit-transform: translateZ(0); }
  /* line 13, src/assets/scss/layout/_navigation.scss */
  .super-navigation a {
    color: #000;
    font-weight: bold;
    text-decoration: underline; }
  /* line 20, src/assets/scss/layout/_navigation.scss */
  .super-navigation button {
    position: relative;
    z-index: 1;
    left: -38px;
    top: 1px;
    background-color: #ffffff;
    cursor: pointer;
    width: 18px;
    border: none; }
  /* line 31, src/assets/scss/layout/_navigation.scss */
  .super-navigation .form-control {
    /* color: #fff; */
    /* font-size: 1.1em; */
    /* cursor: pointer; */
    /* margin: 1em; */
    background: #fff 0 0 no-repeat padding-box;
    border: 2px solid #e12490;
    border-radius: 23px;
    opacity: 1; }
    /* line 42, src/assets/scss/layout/_navigation.scss */
    .super-navigation .form-control::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      text-align: left;
      font: Regular 18px/25px Avenir Next;
      letter-spacing: 0;
      color: #606060;
      opacity: 1; }
    /* line 50, src/assets/scss/layout/_navigation.scss */
    .super-navigation .form-control::-moz-placeholder {
      /* Firefox 19+ */
      text-align: left;
      font: Regular 18px/25px Avenir Next;
      letter-spacing: 0;
      color: #606060;
      opacity: 1; }
    /* line 58, src/assets/scss/layout/_navigation.scss */
    .super-navigation .form-control:-ms-input-placeholder {
      /* IE 10+ */
      text-align: left;
      font: Regular 18px/25px Avenir Next;
      letter-spacing: 0;
      color: #606060;
      opacity: 1; }
    /* line 66, src/assets/scss/layout/_navigation.scss */
    .super-navigation .form-control:-moz-placeholder {
      /* Firefox 18- */
      text-align: left;
      font: Regular 18px/25px Avenir Next;
      letter-spacing: 0;
      color: #606060;
      opacity: 1; }
  /* line 77, src/assets/scss/layout/_navigation.scss */
  .super-navigation--hidden {
    opacity: 0;
    display: none; }

/* line 84, src/assets/scss/layout/_navigation.scss */
#filtersubmit {
  /* height: 27px; */
  /* transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0); */
  position: relative;
  z-index: 1;
  left: -8px;
  top: -1px;
  color: #442e8b;
  cursor: pointer;
  width: 0;
  background: transparent;
  padding: inherit; }

/*
---------------------
Navigation
---------------------
*/
/* line 106, src/assets/scss/layout/_navigation.scss */
.navbar, .navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
  display: flexbox; }

/* line 110, src/assets/scss/layout/_navigation.scss */
.dropdown-menu .nav-title-border {
  font-weight: 700;
  color: #000;
  font-size: 1em;
  border-bottom: 1px solid #efeeee;
  margin: 4px 15px 4px;
  padding-bottom: 5px; }

/* line 120, src/assets/scss/layout/_navigation.scss */
.dropdown-menu .nav-title {
  font-weight: 700;
  color: #000;
  font-size: 1em;
  margin: 4px 15px 4px;
  padding-bottom: 5px; }

/* line 130, src/assets/scss/layout/_navigation.scss */
.dropdown-menu .nav-title-secondary {
  font-weight: 600;
  color: #44318B;
  font-size: 1em;
  margin: 4px 15px;
  padding-bottom: 5px; }

/* line 137, src/assets/scss/layout/_navigation.scss */
.nav-bottom-border::before {
  content: "";
  position: absolute;
  width: 50vw;
  height: 1px;
  left: 0;
  display: block;
  clear: both;
  background-color: #410099;
  opacity: .2;
  margin: -6px 27px; }

/* line 150, src/assets/scss/layout/_navigation.scss */
.nav-bottom-border {
  position: relative; }

/* line 156, src/assets/scss/layout/_navigation.scss */
.navbar {
  z-index: 10 !important;
  list-style: none; }
  /* line 161, src/assets/scss/layout/_navigation.scss */
  .navbar .ml-auto {
    width: inherit; }

/* line 167, src/assets/scss/layout/_navigation.scss */
.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: stretch; }

/* line 178, src/assets/scss/layout/_navigation.scss */
.navbar-light .navbar-nav .nav-link {
  color: #ed3c96;
  font-weight: normal;
  font-size: 1.06rem; }
  @media (min-width: 768px) {
    /* line 178, src/assets/scss/layout/_navigation.scss */
    .navbar-light .navbar-nav .nav-link {
      font-size: 1.2rem; } }

/*
#rto-nav button.navbar-toggler{
  border-color: $primary-color;
}

*/
/* line 197, src/assets/scss/layout/_navigation.scss */
#rto-nav .navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }

/* line 207, src/assets/scss/layout/_navigation.scss */
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #410099; }

@media (min-width: 992px) {
  /* line 211, src/assets/scss/layout/_navigation.scss */
  .navbar-brand {
    transition: all 0.6s ease-in-out;
    animation: zoomIn 2s ease alternate; } }

/* line 218, src/assets/scss/layout/_navigation.scss */
.navbar-brand .logo {
  width: 75px; }
  @media (min-width: 992px) {
    /* line 218, src/assets/scss/layout/_navigation.scss */
    .navbar-brand .logo {
      width: auto;
      margin-right: 2em; } }

/* line 230, src/assets/scss/layout/_navigation.scss */
.navbar-dark .navbar-nav .nav-link {
  color: #432e8a;
  font-weight: 800; }

/* line 236, src/assets/scss/layout/_navigation.scss */
.btn-navigation {
  display: inline-block;
  font-weight: 800;
  text-align: center;
  /* vertical-align: middle!important; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1em;
  /* line-height: 1.5; */
  border-radius: 22px 22px 22px 0;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #fff;
  background-color: #44318b;
  border-color: #44318b;
  height: 36px;
  width: 89px;
  /* vertical-align: bottom; */
  position: relative; }
  /* line 258, src/assets/scss/layout/_navigation.scss */
  .btn-navigation:hover {
    color: white;
    background-color: #2b0066;
    border-color: #4d389e;
    text-decoration: none;
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }

/* line 270, src/assets/scss/layout/_navigation.scss */
.fixed-top-supernav {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 20;
  background: #ebebeb !important;
  transform: translateZ(0);
  -webkit-transform: translateZ(0); }

/* line 281, src/assets/scss/layout/_navigation.scss */
.fixed-bottom, .fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 20;
  background: #ffffff;
  transform: translateZ(0);
  -webkit-transform: translateZ(0); }
  @media (min-width: 768px) {
    /* line 281, src/assets/scss/layout/_navigation.scss */
    .fixed-bottom, .fixed-top {
      background: #ebebeb; } }

/* line 296, src/assets/scss/layout/_navigation.scss */
.page-header {
  transition: background 0.5s ease-in-out; }

/*
old code before hover

.megamenu-li {
  position: static;
}

*/
/*
code from 108 to change to hover state rather than click nav
*/
/* line 313, src/assets/scss/layout/_navigation.scss */
.megamenu-li {
  position: initial; }

/* line 314, src/assets/scss/layout/_navigation.scss */
.megamenu-li.dropdown > a {
  position: relative;
  z-index: 2; }

/* line 315, src/assets/scss/layout/_navigation.scss */
.megamenu-li.dropdown:hover .dropdown-menu-wrapper {
  padding-top: 116px;
  top: 0;
  z-index: 1;
  background: none;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0; }

/* line 316, src/assets/scss/layout/_navigation.scss */
.megamenu-li.dropdown:hover .dropdown-menu-wrapper, .megamenu-li.dropdown:hover .dropdown-menu {
  display: block; }

/* line 317, src/assets/scss/layout/_navigation.scss */
.dropdown-menu .container {
  background: #fff;
  padding-top: 30px; }

/* line 319, src/assets/scss/layout/_navigation.scss */
.megamenu {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  padding: 15px; }

/* line 327, src/assets/scss/layout/_navigation.scss */
.mega-menu {
  position: initial; }
  /* line 330, src/assets/scss/layout/_navigation.scss */
  .mega-menu:hover .dropdown-menu {
    display: block; }
  /* line 335, src/assets/scss/layout/_navigation.scss */
  .mega-menu .dropdown-menu {
    width: 100%;
    margin-top: -15px; }
    /* line 339, src/assets/scss/layout/_navigation.scss */
    .mega-menu .dropdown-menu table td {
      border-top: 0; }

@media only screen and (min-width: 768px) {
  /*Make Navigation Toggle on Desktop Hover*/
  /* line 350, src/assets/scss/layout/_navigation.scss */
  .dropdown:hover > .dropdown-menu {
    display: block; }
  /* line 353, src/assets/scss/layout/_navigation.scss */
  .dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky */
    pointer-events: none; } }

/* line 362, src/assets/scss/layout/_navigation.scss */
.dropdown-item {
  display: block;
  width: 100%;
  padding: .2rem 1rem;
  clear: both;
  line-height: 1.6;
  font-weight: 400;
  text-decoration: underline;
  color: #000;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  /* line 364, src/assets/scss/layout/_navigation.scss */
  .dropdown-item:focus, .dropdown-item:hover {
    text-decoration: none;
    color: black;
    background: none; }
  /* line 368, src/assets/scss/layout/_navigation.scss */
  .dropdown-item:last-child {
    margin-bottom: 1em; }
  /* line 372, src/assets/scss/layout/_navigation.scss */
  .dropdown-item img {
    vertical-align: text-bottom;
    margin-right: 5px; }
  /* line 378, src/assets/scss/layout/_navigation.scss */
  .dropdown-item__pink {
    display: block;
    width: 100%;
    padding: .2rem 1rem;
    clear: both;
    line-height: 1.6;
    font-weight: 800;
    text-decoration: underline;
    color: #DC1E8A;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
    /* line 380, src/assets/scss/layout/_navigation.scss */
    .dropdown-item__pink:focus, .dropdown-item__pink:hover {
      text-decoration: none;
      color: #af186e;
      background: none;
      text-decoration: none; }

/* line 392, src/assets/scss/layout/_navigation.scss */
#rto-nav .form-control {
  background: #fff 0 0 no-repeat padding-box;
  border: 2px solid #efeeee;
  border-radius: 23px;
  width: 261px;
  opacity: 1;
  padding: .375rem .75rem;
  margin-left: 10px; }

/* line 402, src/assets/scss/layout/_navigation.scss */
.rpw-subline {
  margin-left: 16px;
  padding: 5px 0;
  color: grey; }

/* line 409, src/assets/scss/layout/_navigation.scss */
.upcoming-events-nav-block {
  margin-bottom: 25px; }
  /* line 413, src/assets/scss/layout/_navigation.scss */
  .upcoming-events-nav-block__post-title {
    color: #000;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    margin-left: 17px;
    margin-top: 16px; }
  /* line 422, src/assets/scss/layout/_navigation.scss */
  .upcoming-events-nav-block__post-meta-title {
    color: #44318B;
    font-size: 13px;
    text-align: left;
    margin: 7px 0 10px 15px;
    font-weight: 700; }

/* Smaller Nav on Scroll Styling
================================================== */
/* line 438, src/assets/scss/layout/_navigation.scss */
.smaller-nav {
  opacity: 1;
  transform: translateY(0);
  padding: 10px;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  transform: translateZ(0);
  -webkit-transform: translateZ(0); }
  @media (min-width: 768px) {
    /* line 438, src/assets/scss/layout/_navigation.scss */
    .smaller-nav {
      padding: 10px 0; } }

/* line 454, src/assets/scss/layout/_navigation.scss */
.smaller-nav.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  top: 78px; }
  @media (min-width: 576px) {
    /* line 454, src/assets/scss/layout/_navigation.scss */
    .smaller-nav.scroll-on {
      top: 82px; } }
  @media (min-width: 768px) {
    /* line 454, src/assets/scss/layout/_navigation.scss */
    .smaller-nav.scroll-on {
      top: 45px; } }

/* line 473, src/assets/scss/layout/_navigation.scss */
.smaller-nav.scroll-on .navbar-brand img {
  height: 28px;
  margin-right: 7em; }

/* REVAMPED CSS FOR CUSTOM NAV */
/* line 483, src/assets/scss/layout/_navigation.scss */
.smaller-nav {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  padding: 10px;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  transform: none;
  -webkit-transform: none; }

/* line 493, src/assets/scss/layout/_navigation.scss */
.navbar-toggler.custom-button {
  border-color: transparent !important; }

/* line 496, src/assets/scss/layout/_navigation.scss */
.navbar-toggler:focus, .navbar-toggler:active {
  outline: none;
  box-shadow: none;
  border: none; }

/* line 501, src/assets/scss/layout/_navigation.scss */
.navbar-toggler, .navbar-toggler {
  outline: none;
  box-shadow: none;
  border: none; }

/* line 507, src/assets/scss/layout/_navigation.scss */
.animated-icon {
  width: 34px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }

/* line 523, src/assets/scss/layout/_navigation.scss */
.bg-circle {
  background: #44318b;
  padding: 16px 16px;
  border-radius: 50%; }

/* line 529, src/assets/scss/layout/_navigation.scss */
.animated-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

/* line 547, src/assets/scss/layout/_navigation.scss */
.animated-icon span {
  background: #44318b; }

/* line 551, src/assets/scss/layout/_navigation.scss */
.animated-icon.open span {
  background: white; }

/* line 555, src/assets/scss/layout/_navigation.scss */
.animated-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

/* line 563, src/assets/scss/layout/_navigation.scss */
.animated-icon span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

/* line 571, src/assets/scss/layout/_navigation.scss */
.animated-icon span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

/* line 579, src/assets/scss/layout/_navigation.scss */
.animated-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 5px;
  width: 21px; }

/* line 589, src/assets/scss/layout/_navigation.scss */
.animated-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0; }

/* line 594, src/assets/scss/layout/_navigation.scss */
.animated-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  /* top: 2px; */
  /* width: 25px; */
  width: 20px; }

@media (max-width: 992px) {
  /* line 606, src/assets/scss/layout/_navigation.scss */
  .mobileMenu {
    /*transform: translateX(-100%); */
    position: fixed;
    top: 129px;
    bottom: 0;
    margin: auto;
    min-width: 90%;
    left: 0;
    transition: all .25s ease;
    z-index: 15000; }
    /* line 616, src/assets/scss/layout/_navigation.scss */
    .mobileMenu.onRight {
      left: auto;
      right: 0;
      transform: translateX(100%); }
    /* line 621, src/assets/scss/layout/_navigation.scss */
    .mobileMenu.open {
      transform: translateX(0%); }
    /* line 624, src/assets/scss/layout/_navigation.scss */
    .mobileMenu .navbar-nav {
      overflow-y: auto; }
  /* line 628, src/assets/scss/layout/_navigation.scss */
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.2);
    display: none; }
    /* line 637, src/assets/scss/layout/_navigation.scss */
    .overlay.open {
      display: block;
      z-index: 0; } }

/* line 645, src/assets/scss/layout/_navigation.scss */
.nav-item .dropdown .megamenu-li {
  z-index: 1000; }

/* line 649, src/assets/scss/layout/_navigation.scss */
.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
  /* float: none; */
  display: flex !important;
  justify-content: flex-end !important; }

@media screen and (max-width: 992px) {
  /* line 658, src/assets/scss/layout/_navigation.scss */
  .navbar-light .navbar-nav .nav-link {
    font-size: 1.5rem; }
  /* line 664, src/assets/scss/layout/_navigation.scss */
  .megamenu-li {
    border-bottom: solid 1px #607D8B;
    padding-bottom: 1px; }
  /* line 670, src/assets/scss/layout/_navigation.scss */
  .dropdown-toggle::after {
    display: flex;
    margin-left: .255em;
    vertical-align: .255em;
    content: 'v';
    font-weight: 1em;
    align-self: self-start;
    justify-items: self-end;
    justify-self: flex-end;
    justify-content: flex-end;
    align-content: space-evenly;
    margin: 0px 15px;
    /* display: inline-block; */
    margin-left: 0;
    vertical-align: unset;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-top: -35px; } }

/* OTHER STYLES 
body {
  background-color: #3b404e;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
   font: usual, Arial, sans-serif;
   margin: 1em auto;
   max-width: 1200px;
       background-color: #fff;
    color: #333;
   //height: 100vh;
}

body {
    font: usual, Arial, sans-serif;
    margin: 1em auto;
    max-width: 1200px;
    background-color: #fff;
    color: #333;
    height: 100vh;
 }
 

*/
/* line 39, src/assets/scss/pages/_comparison-new.scss */
.comparison-2020 {
  padding: 4em 2em; }

/* line 46, src/assets/scss/pages/_comparison-new.scss */
#comparison-2020 .fa, #comparison-2020 .fab, #comparison-2020 .fal, #comparison-2020 .far, #comparison-2020 .fas {
  background: none !important; }

/* line 52, src/assets/scss/pages/_comparison-new.scss */
#comparison-2020 hr {
  height: 3px;
  display: block;
  margin-top: 12px;
  width: 100%;
  background: #f0b4d4;
  content: ""; }

/* line 62, src/assets/scss/pages/_comparison-new.scss */
#comparison-2020 hr.inverse {
  height: 3px;
  display: block;
  margin-top: 12px;
  width: 100%;
  background: #000;
  content: ""; }

/* line 75, src/assets/scss/pages/_comparison-new.scss */
.header {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  margin-bottom: 0.5em; }
  /* line 81, src/assets/scss/pages/_comparison-new.scss */
  .header h1 {
    color: #44318b;
    font-size: 3.75em;
    text-transform: uppercase; }
    /* line 86, src/assets/scss/pages/_comparison-new.scss */
    .header h1 span {
      color: #4d389e;
      font-size: 22px;
      font-weight: 400;
      margin-left: 15px;
      text-transform: initial;
      display: block; }
      @media (min-width: 768px) {
        /* line 86, src/assets/scss/pages/_comparison-new.scss */
        .header h1 span {
          display: inline-block; } }

/* line 102, src/assets/scss/pages/_comparison-new.scss */
.container-grid {
  padding: 1em 2em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(15px, auto);
  font-size: normal; }
  @media screen and (max-width: 800px) {
    /* line 102, src/assets/scss/pages/_comparison-new.scss */
    .container-grid {
      grid-template-columns: repeat(190px auto);
      padding: 2px 0px 2px;
      font-size: small; } }

/*
 .container :nth-child(5) {
      grid-column: auto / span 4;
 }
 */
/* line 128, src/assets/scss/pages/_comparison-new.scss */
.item {
  float: left;
  color: #fff;
  display: flex;
  align-content: space-between;
  justify-content: space-around;
  font-size: 1.5em;
  padding: 12px 2px;
  flex-direction: row; }
  @media screen and (max-width: 800px) {
    /* line 128, src/assets/scss/pages/_comparison-new.scss */
    .item {
      flex-direction: column;
      padding: 8px 4px; } }

/*
 .item :nth-child(2) {
   background-color: aqua;
   box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
   color: #fff;
   border-radius: 4px;
   border: 6px solid #171717;
 }
 */
/* line 159, src/assets/scss/pages/_comparison-new.scss */
.service-title {
  font-weight: 500;
  color: white;
  background: #da4a97;
  display: flex;
  align-content: space-between;
  justify-content: space-around; }
  /* line 169, src/assets/scss/pages/_comparison-new.scss */
  .service-title--blue {
    background: #8fcea5; }
  /* line 173, src/assets/scss/pages/_comparison-new.scss */
  .service-title--clear {
    background: none;
    color: #262626;
    display: flex;
    justify-content: flex-end; }

/* line 181, src/assets/scss/pages/_comparison-new.scss */
.sub-text {
  letter-spacing: -1.2px;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.25rem; }
  /* line 188, src/assets/scss/pages/_comparison-new.scss */
  .sub-text span {
    display: block;
    font-size: 1em;
    font-weight: 400;
    text-transform: lowercase; }

/* line 196, src/assets/scss/pages/_comparison-new.scss */
.figures-text {
  letter-spacing: -1.2px;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 0.25rem; }
  /* line 203, src/assets/scss/pages/_comparison-new.scss */
  .figures-text span {
    display: block;
    font-size: 0.75em;
    color: #e685b9;
    font-weight: 600;
    text-transform: uppercase; }

/* line 213, src/assets/scss/pages/_comparison-new.scss */
.flex-around {
  display: flex;
  align-content: space-around;
  justify-content: space-around; }

/* line 219, src/assets/scss/pages/_comparison-new.scss */
.two-col {
  columns: 2;
  padding: 12px 24px; }
  @media screen and (max-width: 868px) {
    /* line 219, src/assets/scss/pages/_comparison-new.scss */
    .two-col {
      columns: 1;
      padding: 2px 2px; } }

/* line 229, src/assets/scss/pages/_comparison-new.scss */
p {
  font-size: 18px; }

/* line 233, src/assets/scss/pages/_comparison-new.scss */
.t-purple {
  color: #f0b4d4; }
  /* line 235, src/assets/scss/pages/_comparison-new.scss */
  .t-purple span {
    color: #ffffff; }

/* line 240, src/assets/scss/pages/_comparison-new.scss */
.t-white {
  color: #ffffff; }

/* line 244, src/assets/scss/pages/_comparison-new.scss */
.t-black {
  color: #4d4d4d; }

/* line 248, src/assets/scss/pages/_comparison-new.scss */
.item1,
.item2 {
  color: #44318b;
  background: none;
  font-size: 2em;
  font-weight: 600; }

/* line 256, src/assets/scss/pages/_comparison-new.scss */
.item3 {
  grid-column: auto / span 2;
  color: white;
  background: #44318b;
  display: flex;
  align-content: space-between;
  justify-content: space-around;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px; }

/* line 267, src/assets/scss/pages/_comparison-new.scss */
.item6,
.item7 {
  grid-column: auto / span 2;
  color: #44318b;
  background: #ead3a5;
  display: flex;
  align-content: space-between;
  justify-content: space-around; }

/* line 279, src/assets/scss/pages/_comparison-new.scss */
.item8,
.item11,
.item14,
.item17,
.item20,
.item23 {
  grid-column: auto / span 2; }

/* line 288, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(4) {
  background: #861054;
  color: #ffffff; }

/* line 293, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(5) {
  background: #e0e0e0;
  color: #262626; }

/* line 298, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(9) {
  background: #861054;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 298, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(9) {
      padding-left: 2em; } }

/* line 310, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(10) {
  background: #e0e0e0;
  color: #262626;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 310, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(10) {
      padding-left: 2em; } }
  /* line 322, src/assets/scss/pages/_comparison-new.scss */
  div.container-grid > div:nth-of-type(10) span {
    color: #000; }

/* line 327, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(12) {
  background: #861054;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 327, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(12) {
      padding-left: 2em; } }

/* line 339, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(13) {
  background: #e0e0e0;
  color: #262626;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 339, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(13) {
      padding-left: 2em; } }
  /* line 351, src/assets/scss/pages/_comparison-new.scss */
  div.container-grid > div:nth-of-type(13) span {
    color: #000; }

/* line 356, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(15) {
  background: #861054;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 356, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(15) {
      padding-left: 2em; } }

/* line 368, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(16) {
  background: #e0e0e0;
  color: #262626;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 368, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(16) {
      padding-left: 2em; } }
  /* line 380, src/assets/scss/pages/_comparison-new.scss */
  div.container-grid > div:nth-of-type(16) span {
    color: #000; }

/* line 385, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(18) {
  background: #861054;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 385, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(18) {
      padding-left: 2em; } }

/* line 397, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(19) {
  background: #e0e0e0;
  color: #262626;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 397, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(19) {
      padding-left: 2em; } }
  /* line 409, src/assets/scss/pages/_comparison-new.scss */
  div.container-grid > div:nth-of-type(19) span {
    color: #000; }

/* line 414, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(21) {
  background: #861054;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 414, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(21) {
      padding-left: 2em; } }

/* line 426, src/assets/scss/pages/_comparison-new.scss */
div.container-grid > div:nth-of-type(22) {
  background: #e0e0e0;
  color: #262626;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 868px) {
    /* line 426, src/assets/scss/pages/_comparison-new.scss */
    div.container-grid > div:nth-of-type(22) {
      padding-left: 2em; } }
  /* line 438, src/assets/scss/pages/_comparison-new.scss */
  div.container-grid > div:nth-of-type(22) span {
    color: #000; }

/* line 2, src/assets/scss/pages/_insurance.scss */
.insurance-content-block h2 {
  font-size: 1.5rem;
  margin-bottom: 10px; }

/* line 6, src/assets/scss/pages/_insurance.scss */
.insurance-content-block__block-one {
  width: 80%;
  padding-bottom: 2em; }
  @media (min-width: 576px) {
    /* line 6, src/assets/scss/pages/_insurance.scss */
    .insurance-content-block__block-one {
      padding-bottom: 0; } }

/* line 15, src/assets/scss/pages/_insurance.scss */
.insurance-content-block__block-two {
  width: 80%;
  padding-bottom: 2em; }
  @media (min-width: 576px) {
    /* line 15, src/assets/scss/pages/_insurance.scss */
    .insurance-content-block__block-two {
      padding-bottom: 0; } }
  /* line 24, src/assets/scss/pages/_insurance.scss */
  .insurance-content-block__block-two > div > div {
    margin-left: -30px;
    margin-bottom: -30px; }
  /* line 29, src/assets/scss/pages/_insurance.scss */
  .insurance-content-block__block-two ul {
    list-style: none;
    width: 90%; }
    /* line 33, src/assets/scss/pages/_insurance.scss */
    .insurance-content-block__block-two ul li {
      margin-left: 15px; }
  /* line 38, src/assets/scss/pages/_insurance.scss */
  .insurance-content-block__block-two .fa-check {
    color: rgba(0, 128, 0, 0.33);
    background: none;
    float: left;
    margin-right: 10px; }

/* MISSION Statement BLOCK
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* line 6, src/assets/scss/pages/_about.scss */
.mission-statement-block {
  margin: 2em 0; }
  /* line 9, src/assets/scss/pages/_about.scss */
  .mission-statement-block h2 {
    font-size: 2em; }
  /* line 13, src/assets/scss/pages/_about.scss */
  .mission-statement-block p, .mission-statement-block .lead {
    max-width: 80%;
    font-weight: lighter;
    margin-top: 1em !important;
    margin-bottom: 1rem !important; }

@media (min-width: 48em) {
  /* line 22, src/assets/scss/pages/_about.scss */
  .mission-statement-block {
    margin: 1em 0; } }

/* TIMELINE BLOCK
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* line 31, src/assets/scss/pages/_about.scss */
.timeline-block {
  position: relative;
  margin-top: 2em;
  margin-bottom: 3em; }
  @media (min-width: 576px) {
    /* line 31, src/assets/scss/pages/_about.scss */
    .timeline-block {
      margin-bottom: 0; } }

/* line 45, src/assets/scss/pages/_about.scss */
.timeline-block:before {
  content: "";
  width: 4px;
  height: 90%;
  background: #cecccd;
  position: absolute;
  top: 5px;
  left: 10px;
  transform: translateX(0); }

/* line 58, src/assets/scss/pages/_about.scss */
.timeline-block .timeline {
  /* padding-left: 50px; */
  float: right;
  position: relative; }

/* line 64, src/assets/scss/pages/_about.scss */
.timeline-block .timeline:after,
.timeline-block .timeline:before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%); }

/* line 72, src/assets/scss/pages/_about.scss */
.timeline-block .timeline-two:after,
.timeline-block .timeline-two:before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%); }

/* line 80, src/assets/scss/pages/_about.scss */
.timeline-block .timeline:before {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #891c56;
  border: 7px solid #fff;
  left: -1px; }

/* line 89, src/assets/scss/pages/_about.scss */
.timeline-block .timeline-two:before {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #DC1E8A;
  border: 7px solid #fff;
  left: -1px; }

/*
.timeline-block .timeline:after {
    display: block;
    border-right: 30px solid #44318b;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    left: 24px
}
*/
/* line 109, src/assets/scss/pages/_about.scss */
.timeline-block .timeline-content {
  display: block;
  /* padding: 25px; */
  /* border-radius: 100px; */
  /* position: relative; */ }

/* line 116, src/assets/scss/pages/_about.scss */
.timeline-block .timeline-content:after,
.timeline-block .timeline-content:before {
  content: "";
  display: block;
  width: 100%;
  clear: both; }

/* line 124, src/assets/scss/pages/_about.scss */
.timeline-block .timeline-content:hover {
  text-decoration: none; }

/*
.timeline-block .inner-content {
    width: 75%;
    float: right;
    padding: 11px 12px 2px;
    background: #fff;
    border-radius: 0 100px 100px 0;
    color: #2e3f75;
}
*/
/* line 139, src/assets/scss/pages/_about.scss */
.timeline-block .inner-content {
  width: 75%;
  float: right;
  padding: 11px 12px 2px;
  background: #fff;
  border-radius: 0 100px 100px 0;
  color: #2e3f75;
  clear: right;
  margin: -2px 9px 40px; }

/* line 150, src/assets/scss/pages/_about.scss */
.timeline-block .year-one {
  background: #891c56;
  padding: .45em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 22px 22px 22px 0;
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  top: 16%;
  left: 8%;
  transform: translateY(-50%); }

/* line 167, src/assets/scss/pages/_about.scss */
.timeline-block .year-two {
  background: #DC1E8A;
  padding: .45em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 22px 22px 22px 0;
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  top: 44%;
  left: 8%;
  transform: translateY(-50%); }

/* line 183, src/assets/scss/pages/_about.scss */
.timeline-block .title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 5px; }

/* line 190, src/assets/scss/pages/_about.scss */
.timeline-block .description {
  font-size: 14px;
  margin: 0 0 5px;
  color: #262626;
  padding: 39px; }
  @media (min-width: 576px) {
    /* line 190, src/assets/scss/pages/_about.scss */
    .timeline-block .description {
      padding: 0; } }

@media only screen and (max-width: 1200px) {
  /* line 206, src/assets/scss/pages/_about.scss */
  .timeline-block .inner-content {
    width: 80%; }
  /* line 210, src/assets/scss/pages/_about.scss */
  .timeline-block .year {
    font-size: 45px;
    left: 10px; } }

@media only screen and (max-width: 990px) {
  /* line 218, src/assets/scss/pages/_about.scss */
  .timeline-block .year {
    font-size: 40px;
    left: 30px; }
  /* line 223, src/assets/scss/pages/_about.scss */
  .timeline-block .timeline:after {
    display: none; } }

@media only screen and (min-width: 767px) {
  /* line 232, src/assets/scss/pages/_about.scss */
  .timeline-block .timeline,
  .timeline-block .timeline:nth-child(even),
  .timeline-block .timeline:nth-child(odd) {
    width: 100%;
    float: none;
    margin: 0 0 30px; }
  /* line 240, src/assets/scss/pages/_about.scss */
  .timeline-block .timeline:last-child {
    margin-bottom: 0; }
  /*  
    .timeline-block .timeline:nth-child(2n) {
        padding: 0 0 0 50px
    }


    .timeline-block .inner-content {
        width: 85%
    }
*/ }

@media only screen and (max-width: 479px) {
  /* line 258, src/assets/scss/pages/_about.scss */
  .timeline-block .timeline-content {
    padding: 15px; }
  /* line 262, src/assets/scss/pages/_about.scss */
  .timeline-block .inner-content {
    width: 80%; }
  /* line 266, src/assets/scss/pages/_about.scss */
  .timeline-block .year {
    font-size: 30px; } }

/* line 273, src/assets/scss/pages/_about.scss */
.about-faq h2 {
  font-size: 2em; }

/* line 279, src/assets/scss/pages/_about.scss */
.our-partners-block h2 {
  font-size: 2em;
  color: #262626; }

/* line 2, src/assets/scss/pages/_contact.scss */
.contact-page-block h2 {
  color: #262626;
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 20px; }

/* line 8, src/assets/scss/pages/_contact.scss */
.contact-page-block h3 {
  line-height: 1.5; }

/* line 12, src/assets/scss/pages/_contact.scss */
.contact-page-block h5 {
  color: #44318B;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 1.2em; }

/* line 19, src/assets/scss/pages/_contact.scss */
.contact-page-block h5:first-of-type::before {
  display: block;
  content: "";
  background: #44318B;
  height: 2px;
  margin: 24px 0px;
  opacity: .25; }

/* line 28, src/assets/scss/pages/_contact.scss */
.contact-page-block h5:last-of-type::after {
  display: block;
  content: "";
  background: #44318B;
  height: 2px;
  margin: 24px 0px;
  opacity: .25; }

/* line 40, src/assets/scss/pages/_contact.scss */
.contact-page-form-block h2 {
  color: #262626;
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 20px; }

/* line 48, src/assets/scss/pages/_contact.scss */
.contact-page-form {
  background-color: #efeeee;
  padding: 3rem 3.25rem;
  box-shadow: 1px 3px 3px #cdbcbc; }
  /* line 53, src/assets/scss/pages/_contact.scss */
  .contact-page-form .form-control {
    display: block;
    width: 100%;
    padding: .35rem .95rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    /* border: 1px solid #ffffff; */
    border-radius: 0rem;
    margin-bottom: 25px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

/* line 73, src/assets/scss/pages/_contact.scss */
.contact-page-accordion-block h2 {
  color: #262626;
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 20px; }

/* line 3, src/assets/scss/pages/_home.scss */
.services--block {
  background-color: #fff;
  position: relative;
  z-index: 5;
  border-radius: 6px;
  top: 0;
  border-radius: 12px;
  padding: 16px 0 0;
  box-shadow: 1px 3px 1px #efeeee; }
  @media (min-width: 768px) {
    /* line 3, src/assets/scss/pages/_home.scss */
    .services--block {
      top: -8em;
      border-radius: 12px; } }
  /* line 20, src/assets/scss/pages/_home.scss */
  .services--block__image {
    max-width: 96px;
    height: auto; }
  /* line 26, src/assets/scss/pages/_home.scss */
  .services--block h2 {
    color: #DC1E8A;
    max-width: 95%;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 700; }
  /* line 34, src/assets/scss/pages/_home.scss */
  .services--block h5 {
    color: #262626;
    max-width: 10em;
    margin: 0 auto;
    text-align: center;
    line-height: 1.4;
    margin-top: .5em; }
  /* line 43, src/assets/scss/pages/_home.scss */
  .services--block .border-right {
    border-right: 0px solid #dee2e6 !important; }
    @media (min-width: 768px) {
      /* line 43, src/assets/scss/pages/_home.scss */
      .services--block .border-right {
        border-right: 1px solid #dee2e6 !important; } }

/* line 56, src/assets/scss/pages/_home.scss */
.join-community {
  margin: 3em 3em 4em; }
  @media (min-width: 768px) {
    /* line 56, src/assets/scss/pages/_home.scss */
    .join-community {
      margin: 4em 2em 8em; } }
  /* line 63, src/assets/scss/pages/_home.scss */
  .join-community p {
    max-width: 85%; }
  /* line 67, src/assets/scss/pages/_home.scss */
  .join-community img {
    max-width: 100%; }
  /* line 71, src/assets/scss/pages/_home.scss */
  .join-community .join-img-1 {
    z-index: -1;
    /*position: absolute; */ }
  /* line 77, src/assets/scss/pages/_home.scss */
  .join-community .join-img-2 {
    display: none; }
    @media (min-width: 768px) {
      /* line 77, src/assets/scss/pages/_home.scss */
      .join-community .join-img-2 {
        display: block;
        z-index: -3;
        position: absolute;
        right: -119px;
        top: -49px; } }

/* line 93, src/assets/scss/pages/_home.scss */
.figures {
  position: relative;
  margin-top: 2em;
  padding: 2em 0;
  border-top: 1px solid #f9f9f9; }

/* line 100, src/assets/scss/pages/_home.scss */
.sp-counter {
  border-radius: 10px;
  color: #442e8b;
  background-color: transparent;
  position: relative;
  padding: 10px 20px;
  display: inline-block;
  /*&:after {
      border-radius:20px 20px 0 0;
      content: '';
      display: inline-block;
      position: absolute;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      bottom: 0;
      width: 96%;
      height: 7px;
      background-color: $secondary;
    }*/ }
  /* line 122, src/assets/scss/pages/_home.scss */
  .sp-counter__digit {
    font-size: 40px;
    line-height: 1;
    margin: 0; }
  /* line 127, src/assets/scss/pages/_home.scss */
  .sp-counter__label {
    color: #DC1E8A; }
    /* line 129, src/assets/scss/pages/_home.scss */
    body .sp-counter__label {
      font-size: 1rem;
      font-weight: 700;
      margin-top: 8px; }
    /* line 135, src/assets/scss/pages/_home.scss */
    .sp-counter__label a {
      color: #DC1E8A; }
      /* line 138, src/assets/scss/pages/_home.scss */
      .sp-counter__label a:hover {
        color: #af186e; }

/* line 147, src/assets/scss/pages/_home.scss */
.counters {
  display: flex;
  /*background: rgba(255, 255, 255, 0.75); */
  padding: 25px;
  /*border-radius: 10px; */
  /*box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2); */ }

/* line 157, src/assets/scss/pages/_home.scss */
.counter {
  /* letter-spacing: 0.125rem; */
  color: #410099;
  line-height: 1;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  height: 2.125rem;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
  margin: 0.5em 3.25rem;
  letter-spacing: -1px;
  text-shadow: 1px 1px 1px #b9abb3; }
  /* line 173, src/assets/scss/pages/_home.scss */
  .counter > span {
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 2s ease;
    transform: translateY(0);
    line-height: 1; }
    /* line 181, src/assets/scss/pages/_home.scss */
    .counter > span span {
      flex: 0 0 100%;
      height: 100%; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(1) > span {
    transition-delay: 0s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(2) > span {
    transition-delay: 0.375s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(3) > span {
    transition-delay: 0.75s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(4) > span {
    transition-delay: 1.125s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(5) > span {
    transition-delay: 1.5s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(6) > span {
    transition-delay: 1.875s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(7) > span {
    transition-delay: 2.25s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(8) > span {
    transition-delay: 2.625s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(9) > span {
    transition-delay: 3s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(10) > span {
    transition-delay: 3.375s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(11) > span {
    transition-delay: 3.75s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(12) > span {
    transition-delay: 4.125s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(13) > span {
    transition-delay: 4.5s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(14) > span {
    transition-delay: 4.875s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(15) > span {
    transition-delay: 5.25s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(16) > span {
    transition-delay: 5.625s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(17) > span {
    transition-delay: 6s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(18) > span {
    transition-delay: 6.375s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(19) > span {
    transition-delay: 6.75s; }
  /* line 189, src/assets/scss/pages/_home.scss */
  .counter:nth-child(20) > span {
    transition-delay: 7.125s; }
  @media (min-width: 768px) {
    /* line 157, src/assets/scss/pages/_home.scss */
    .counter {
      margin-bottom: .5em;
      font-size: 2.25em;
      margin: 2rem 6.25rem 1em; } }

/* line 206, src/assets/scss/pages/_home.scss */
.insurance--plans {
  background: url(../../assets/img/Insurance-block-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  background-position: 0% 79%;
  z-index: -1;
  min-height: 68em; }
  @media (min-width: 576px) {
    /* line 206, src/assets/scss/pages/_home.scss */
    .insurance--plans {
      min-height: 30em; } }
  /* line 219, src/assets/scss/pages/_home.scss */
  .insurance--plans__main-heading {
    color: #ffffff;
    font-size: 2em;
    font-weight: 600;
    margin: 2em 0 1.25em;
    text-align: center; }
    @media (min-width: 576px) {
      /* line 219, src/assets/scss/pages/_home.scss */
      .insurance--plans__main-heading {
        text-align: left;
        margin: 0 0 1.25em; } }
  /* line 234, src/assets/scss/pages/_home.scss */
  .insurance--plans__wrapper {
    background-color: #ffffff;
    position: relative;
    z-index: 5;
    border-radius: 6px;
    padding: 55px 19px 55px;
    box-shadow: -3px 3px 10px #414346;
    width: 75%;
    display: block;
    margin: 0 auto; }
    @media (min-width: 576px) {
      /* line 234, src/assets/scss/pages/_home.scss */
      .insurance--plans__wrapper {
        display: flex;
        width: 100%; } }
  /* line 252, src/assets/scss/pages/_home.scss */
  .insurance--plans__wrapper-heading {
    color: #262626;
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.25em;
    margin-bottom: 28px; }
  /* line 260, src/assets/scss/pages/_home.scss */
  .insurance--plans .border-right {
    border-right: 0px solid #dee2e6 !important; }
    @media (min-width: 576px) {
      /* line 260, src/assets/scss/pages/_home.scss */
      .insurance--plans .border-right {
        border-right: 1px solid #dee2e6 !important; } }

/* line 271, src/assets/scss/pages/_home.scss */
.single-testimonial {
  margin-bottom: -2em; }
  /* line 275, src/assets/scss/pages/_home.scss */
  .single-testimonial h2 {
    color: #410099;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important;
    color: #410099; }
  /* line 281, src/assets/scss/pages/_home.scss */
  .single-testimonial__body {
    /* max-width: 85%; */
    font-size: 1.25em;
    line-height: 1.2;
    font-weight: 900;
    color: #000; }
    /* line 288, src/assets/scss/pages/_home.scss */
    .single-testimonial__body span {
      display: block;
      margin-top: 1em;
      color: #410099; }
  /* line 296, src/assets/scss/pages/_home.scss */
  .single-testimonial__box {
    background: #9fddc1;
    min-width: 20em;
    padding: 6em 5em;
    border-radius: 140px 154px 154px 0; }
    @media (min-width: 992px) {
      /* line 296, src/assets/scss/pages/_home.scss */
      .single-testimonial__box {
        margin-left: 10em;
        min-width: 26em; } }

@media only screen and (max-width: 800px) {
  /* line 317, src/assets/scss/pages/_home.scss */
  .single-testimonial__box {
    background: #9fddc1;
    width: 100%;
    padding: 4em;
    border-radius: 0px;
    /* max-width: 0; */
    margin-right: 2em; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 330, src/assets/scss/pages/_home.scss */
  .single-testimonial__box {
    background: #9fddc1;
    width: 100%;
    padding: 4em;
    border-radius: 0;
    margin-left: -2em;
    border-radius: 140px 154px 154px 0; } }

/* line 343, src/assets/scss/pages/_home.scss */
.news-publications-block {
  margin: 3em 0 0;
  min-height: 75vh;
  background-image: url(../../assets/img/news-image.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 98% 100%;
  position: relative;
  bottom: 0;
  right: 0; }
  /* line 354, src/assets/scss/pages/_home.scss */
  .news-publications-block h2 {
    color: #410099;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important; }
  /* line 358, src/assets/scss/pages/_home.scss */
  .news-publications-block h3 {
    color: #DC1E8A;
    margin-top: 15px;
    font-weight: bold; }
  /* line 365, src/assets/scss/pages/_home.scss */
  .news-publications-block hr {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #9fddc1;
    max-width: 75%; }

/* line 374, src/assets/scss/pages/_home.scss */
.foundation-block {
  background: url(../../assets/img/video-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: -ms-flexbox;
  display: flex;
  min-height: 40em;
  width: 100%;
  background-position: left;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2em 1em; }
  /* line 389, src/assets/scss/pages/_home.scss */
  .foundation-block h3 {
    font-size: 1rem;
    color: #8fcea5;
    font-size: 1em;
    margin-bottom: .5em;
    font-weight: 700; }
  /* line 397, src/assets/scss/pages/_home.scss */
  .foundation-block h2 {
    color: #410099;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important;
    color: #ffffff; }
  /* line 402, src/assets/scss/pages/_home.scss */
  .foundation-block ul {
    list-style-type: disc;
    margin-left: 10px; }
  /* line 408, src/assets/scss/pages/_home.scss */
  .foundation-block li {
    color: #fff;
    line-height: 1.6;
    font-weight: 300;
    padding: 8px 0px;
    max-width: 24em; }

/* line 419, src/assets/scss/pages/_home.scss */
.our-partners-block {
  padding: 2em 2em 1em; }
  /* line 422, src/assets/scss/pages/_home.scss */
  .our-partners-block h2 {
    color: #410099;
    font-size: 2rem;
    line-height: 110%;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem !important; }
  /* line 426, src/assets/scss/pages/_home.scss */
  .our-partners-block img {
    width: 184px;
    padding: 1em 1.25em;
    height: auto; }
    /* line 431, src/assets/scss/pages/_home.scss */
    .our-partners-block img:hover {
      filter: opacity(25%); }

/* line 1, src/assets/scss/pages/_timeline.scss */
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative; }

/* line 7, src/assets/scss/pages/_timeline.scss */
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 50%;
  margin-left: -1.5px; }

/* line 18, src/assets/scss/pages/_timeline.scss */
.timeline > li {
  margin-bottom: 20px;
  position: relative; }

/* line 23, src/assets/scss/pages/_timeline.scss */
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table; }

/* line 29, src/assets/scss/pages/_timeline.scss */
.timeline > li:after {
  clear: both; }

/* line 33, src/assets/scss/pages/_timeline.scss */
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table; }

/* line 39, src/assets/scss/pages/_timeline.scss */
.timeline > li:after {
  clear: both; }

/* line 43, src/assets/scss/pages/_timeline.scss */
.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175); }

/* line 54, src/assets/scss/pages/_timeline.scss */
.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " "; }

/* line 66, src/assets/scss/pages/_timeline.scss */
.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " "; }

/* line 78, src/assets/scss/pages/_timeline.scss */
.timeline > li > .timeline-badge {
  color: #fff;
  width: 62px;
  height: 62px;
  line-height: 62px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  background-color: #183768;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%; }

/* line 97, src/assets/scss/pages/_timeline.scss */
.timeline > li.timeline-inverted > .timeline-panel {
  float: right; }

/* line 101, src/assets/scss/pages/_timeline.scss */
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto; }

/* line 108, src/assets/scss/pages/_timeline.scss */
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto; }

/* line 115, src/assets/scss/pages/_timeline.scss */
.timeline-badge.primary {
  background-color: #2e6da4 !important; }

/* line 119, src/assets/scss/pages/_timeline.scss */
.timeline-badge.success {
  background-color: #3f903f !important; }

/* line 123, src/assets/scss/pages/_timeline.scss */
.timeline-badge.warning {
  background-color: #f0ad4e !important; }

/* line 127, src/assets/scss/pages/_timeline.scss */
.timeline-badge.danger {
  background-color: #d9534f !important; }

/* line 131, src/assets/scss/pages/_timeline.scss */
.timeline-badge.info {
  background-color: #5bc0de !important; }

/* line 135, src/assets/scss/pages/_timeline.scss */
.timeline-title {
  margin-top: 0;
  color: #003768;
  font-weight: 700; }

/* line 141, src/assets/scss/pages/_timeline.scss */
.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0; }

/* line 146, src/assets/scss/pages/_timeline.scss */
.timeline-body > p + p {
  margin-top: 5px; }

@media (max-width: 767px) {
  /* line 151, src/assets/scss/pages/_timeline.scss */
  ul.timeline:before {
    left: 40px; }
  /* line 155, src/assets/scss/pages/_timeline.scss */
  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px); }
  /* line 161, src/assets/scss/pages/_timeline.scss */
  ul.timeline > li > .timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px; }
  /* line 167, src/assets/scss/pages/_timeline.scss */
  ul.timeline > li > .timeline-panel {
    float: right; }
  /* line 171, src/assets/scss/pages/_timeline.scss */
  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto; }
  /* line 178, src/assets/scss/pages/_timeline.scss */
  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto; } }

/* line 186, src/assets/scss/pages/_timeline.scss */
.line {
  background-color: #ffffff;
  color: #ffffff;
  align: center;
  height: 2px;
  margin: 0 auto; }

/* line 194, src/assets/scss/pages/_timeline.scss */
.polaroid-images {
  background: white;
  display: inline;
  float: right;
  margin: 0 15px 30px;
  padding: 10px 10px 25px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all .15s linear;
  -moz-transition: all .15s linear;
  transition: all .15s linear;
  z-index: 0;
  position: relative; }

/* line 213, src/assets/scss/pages/_timeline.scss */
.polaroid-images img {
  display: block;
  width: inherit; }

/* line 218, src/assets/scss/pages/_timeline.scss */
.polaroid-images {
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  transform: rotate(4deg); }

/* line 224, src/assets/scss/pages/_timeline.scss */
.polaroid-images::hover {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
  z-index: 10;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7); }

/* line 5, src/assets/scss/pages/_rpw.scss */
#history .headline {
  color: #d14348;
  font-size: 2.5rem; }

/* line 9, src/assets/scss/pages/_rpw.scss */
#history em {
  color: #e37808; }

/* line 13, src/assets/scss/pages/_rpw.scss */
#history {
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 30% auto;
  min-height: 25vh;
  background-color: #cee8c4;
  padding: 5rem 0;
  border-bottom: solid 10px #00aab0; }

/* line 23, src/assets/scss/pages/_rpw.scss */
.text-register {
  color: #6f8866;
  font-weight: 700; }

/* line 28, src/assets/scss/pages/_rpw.scss */
#history .text-content {
  width: 70%; }

/* line 30, src/assets/scss/pages/_rpw.scss */
.return {
  color: #f26925;
  margin: 0 auto;
  float: left;
  font-size: 1em; }

/* line 37, src/assets/scss/pages/_rpw.scss */
.blog-bottom-border {
  border-bottom: 1px solid #ececed;
  margin: 0 0 10px 0; }

/* line 42, src/assets/scss/pages/_rpw.scss */
#signup form {
  /*background-color: #f3d350;*/
  background-color: #cee8c4;
  padding: 2rem 1.25rem;
  box-shadow: 1px 3px 3px #cdbcbc; }

/* line 49, src/assets/scss/pages/_rpw.scss */
#signup form .btn-primary {
  color: #fff;
  background-color: #092f55;
  border-color: #191c1e; }

/* line 55, src/assets/scss/pages/_rpw.scss */
#signup form .btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc; }

/* line 62, src/assets/scss/pages/_rpw.scss */
.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
  display: none; }

/* line 69, src/assets/scss/pages/_rpw.scss */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s; }

/* line 80, src/assets/scss/pages/_rpw.scss */
.closebtn:hover {
  color: black; }

/* line 84, src/assets/scss/pages/_rpw.scss */
.super-header {
  background-color: #badeac;
  padding: 1rem 0; }

/* line 90, src/assets/scss/pages/_rpw.scss */
.super-header a {
  color: #092f56;
  font-weight: 800;
  text-transform: uppercase; }

/* line 1, src/assets/scss/pages/_error.scss */
.error-page {
  margin: 6em 0 4em;
  min-height: 75vh; }
  /* line 5, src/assets/scss/pages/_error.scss */
  .error-page__error-text {
    color: #410099;
    font-size: 2rem;
    margin-bottom: 1rem; }
  /* line 11, src/assets/scss/pages/_error.scss */
  .error-page__error-copy-text {
    color: #262626;
    font-size: 1.5em;
    max-width: 75%; }
  /* line 17, src/assets/scss/pages/_error.scss */
  .error-page__display-text {
    color: #262626;
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 1.2; }
  /* line 24, src/assets/scss/pages/_error.scss */
  .error-page__help-header-text {
    color: #410099;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 600; }
  /* line 31, src/assets/scss/pages/_error.scss */
  .error-page__help-copy-text {
    color: #262626;
    font-size: 1.25em;
    font-weight: 600;
    max-width: 75%; }
  /* line 38, src/assets/scss/pages/_error.scss */
  .error-page__help-links {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    color: #e93a97; }
  /* line 48, src/assets/scss/pages/_error.scss */
  .error-page__help-link-title {
    font-size: .75em;
    text-align: left;
    letter-spacing: .2px;
    margin: 8px 0;
    font-weight: 800;
    margin: 1em 0 2em; }
    @media (min-width: 576px) {
      /* line 48, src/assets/scss/pages/_error.scss */
      .error-page__help-link-title {
        font-size: 1em; } }
  /* line 61, src/assets/scss/pages/_error.scss */
  .error-page .form-control {
    background: #fff 0 0 no-repeat padding-box;
    border: 1px solid #442e8b;
    opacity: 1;
    width: auto;
    border-radius: 25px; }
    @media (min-width: 992px) {
      /* line 61, src/assets/scss/pages/_error.scss */
      .error-page .form-control {
        width: 335px; } }

/* line 2, src/assets/scss/pages/_default-page.scss */
.default-icon-cards h5 {
  max-width: 72%;
  margin: auto;
  margin-bottom: 3em; }
  @media (min-width: 768px) {
    /* line 2, src/assets/scss/pages/_default-page.scss */
    .default-icon-cards h5 {
      margin-bottom: 0; } }

/* line 1, src/assets/scss/pages/_search-results-page.scss */
.search-results-page {
  margin: 3em 1em 2em; }
  /* line 4, src/assets/scss/pages/_search-results-page.scss */
  .search-results-page__block {
    margin: 1em 0; }
    /* line 8, src/assets/scss/pages/_search-results-page.scss */
    .search-results-page__block::after {
      content: "";
      display: block;
      background-color: #efeeee;
      height: 1px; }
  /* line 16, src/assets/scss/pages/_search-results-page.scss */
  .search-results-page__search-links {
    list-style-type: none;
    margin: 1em 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    color: #e93a97; }
  /* line 26, src/assets/scss/pages/_search-results-page.scss */
  .search-results-page__search-description {
    line-height: 1.5;
    font-weight: 400; }
  /* line 32, src/assets/scss/pages/_search-results-page.scss */
  .search-results-page .page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #262626;
    background-color: #efeeee;
    border: 1px solid #dee2e6;
    margin: 2em 0; }
  /* line 44, src/assets/scss/pages/_search-results-page.scss */
  .search-results-page .active {
    background-color: #442e8b;
    color: #ffffff; }

/* line 1, src/assets/scss/pages/_districts.scss */
.district-masthead {
  min-height: 592px;
  position: relative;
  background-image: url(../../assets/img/district-microsite-header.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: inherit; }

/* line 13, src/assets/scss/pages/_districts.scss */
.rto-social-section {
  margin: 3em 0 0;
  padding: 5em 0;
  min-height: 40em;
  background-repeat: no-repeat;
  position: relative;
  bottom: 0;
  right: 0;
  background: url(../../assets/img/twitter-feed-banner.png);
  background-size: cover;
  background-position: right 7%;
  min-height: 83vh; }
  /* line 15, src/assets/scss/pages/_districts.scss */
  .rto-social-section__main-heading {
    color: #ffffff;
    font-size: 2em;
    margin-bottom: .5em;
    font-weight: 600; }
  /* line 22, src/assets/scss/pages/_districts.scss */
  .rto-social-section__twitter-heading {
    color: #ffffff;
    font-size: 1em; }
