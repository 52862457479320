.more-resources-block {
    background: url(../../img/resources-block-bg.png);
   background-repeat: no-repeat;
   background-size: cover;
   margin: 0 auto;
   background-position: 0% 79%;
   z-index: 1;

   @include sm {
   
    } 

   &__main-heading {
    color:$white;
    font-size: 2em;
    font-weight: 600;
    margin: 2em 0 1.25em;
    text-align: center;

    @include sm {
   text-align: left;
   margin: 0 0 1.25em;
   } 


}

   &__wrapper {
    background-color: $white;
    position: relative;
    z-index: 5;
    border-radius: 6px;
    padding: 55px 19px 55px;
    box-shadow: -3px 3px 10px #414346;
    width: 75%;
    display: block;
    margin: 0 auto;

    @include sm {
        display: flex;
        width: 100%;
    
    } 
   }

   &__wrapper-sub-heading{
       color: $black;
       margin-top: 10px;
       font-weight: 400;
       font-size: 1em;
       margin-bottom: 28px;
   }

   .border-right {
    border-right: 0px solid #dee2e6!important;

    @include sm {
        border-right: 1px solid #dee2e6!important;
    
    } 
}

}
