.sub-page-content-block {

    &__title {
        color: $black;
        font-size: 1.75em;
        text-align: center;
        margin-bottom: 1em; 

        @include sm {
        text-align: left;
        } 

    }


    &__sub-title {
        color: $black;
        font-size: 1.25em;
        text-align: center;
        margin: 1em 0;
        padding: 0em 1em;

        @include sm {
        text-align: left;
        padding: 0;
        } 

    }
    
    
    ul {
        padding: 1em 3em;

        @include sm {
        padding: 0;
            } 

    }


    ul li {
        list-style-type: disc;
    }


}