.tools-resources-block { 

    background: $grey;
    padding: 100px;

    &__main-heading {
     color: $black;
     font-size: 2.5em;
     font-weight: 600;
     margin: 0em 0 1.25em;
     text-align: center;
 
     @include sm {
    text-align: left;
 } 

 }


 &__box {
     background: $color-mint;
     border-radius: 18px;
     margin-bottom: 25px;
     padding: 25px 53px;
     height: 12em;
     width: auto;
     overflow: hidden;
     text-align: center;

     &:hover {
     background: darken($color-mint, 20%);
     transform: scale(1.1);  
     }

     &:hover > h5 > a {
      color: $white;
      font-weight: 800;
      font-size: 1.25em;
      text-decoration: none;     
      }

      @include md {
        width: 12em;
     } 

     @include lg {
        width: 19em;
     } 

    } 

     &__box--heading {
    margin-top: 10px;
    margin-bottom: 28px;
    max-width: 10em;

     }


     &_box--heading a {
      color: $color-primary-dark;
      font-weight: 800;
      font-size: 1.5em;

   } 


}