


/*

.u-center-text { text-align: center !important; }

.u-margin-bottom-small { margin-bottom: 1.5rem !important; }
.u-margin-bottom-medium {
    margin-bottom: 4rem !important;

    @include respond(tab-port) {
        margin-bottom: 3rem !important;
    }
}

.u-margin-bottom-big { 
    margin-bottom: 8rem !important;

    @include respond(tab-port) {
        margin-bottom: 5rem !important;
    }
}

*/

.u-margin-top-big { margin-top: 8rem !important; }
.u-margin-top-huge { margin-top: 10rem !important; } 


.rto-headline {
    font-weight: 300;
    font-size: 1.9rem;
    color: $primary-color;
  
    @media (min-width: 530px) {
      font-size: 2.875rem;
    }
  
    &--centered {
      text-align: center;
    }
  
    &--orange {
      color: $orange;
    }
  
    &--small {
      font-size: 1.2rem;
  
      @media (min-width: 530px) {
        font-size: 1.875rem;
      }
    }
  
    &--narrow {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  
    &--light {
      font-weight: 100;
    }
  
    strong {
      font-weight: 400;
    }
  
    &--b-margin-small {
      margin-bottom: .5em;
    }
  
    &--b-margin-large {
      margin-bottom: 1.6em;
    }
  
    &--no-t-margin {
      margin-top: 0;
    }
}

/*
<hr> page divider rules
*/

.purple-divider {
  border-top: 1px solid $color-burgundy;
}

.grey-divider {
  border-top: 1px solid $color-grey-dark-2;
}

.red-divider {
  border-top: 1px solid $color-red;
}


.pink-divider {
  border-top: 1px solid $secondary-color;
}


.generic-page-bullet-points ul {
  list-style-type: disc;
  line-height: 1.8;

  li {
    margin-left: 1em;
    font-weight: 600;
  }

}

/*
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  //border-top: 3px solid rgb(159, 221, 193);
  max-width: 100%;
}
*/

/* 
District badges for Event and news Items 
*/

.badge-primary {
  @include category-badge ($primary-color, $color, $badge-mb);
}

.badge-secondary {
  @include category-badge ($secondary-color, $color, $badge-mb);
}

.badge-tertiary {
  @include category-badge ($color-red, $color, $badge-mb); 
}

.badge-foundation {
  @include category-badge ($primary-foundation-color, $color, $badge-mb);
}


a.badge-primary.focus, a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
}

a.badge-primary:focus, a.badge-primary:hover {
  color: #fff;
  background-color: darken($primary-color, 10%);
}

a.badge-secondary.focus, a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem hsla(208,7%,46%,.5);
}

a.badge-secondary:focus, a.badge-secondary:hover {
  color: #fff;
  background-color: darken($secondary-color, 10%);
}

a.badge-tertiary.focus, a.badge-tertiary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem hsla(208,7%,46%,.5);
}

a.badge-tertiary:focus, a.badge-tertiary:hover {
  color: #fff;
  background-color: darken($color-red, 10%);
}


a.badge-foundation.focus, a.badge-foundation.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem hsla(208,7%,46%,.5);
}

a.badge-foundation:focus, a.badge-foundation:hover {
  color: #fff;
  background-color: darken($primary-foundation-color, 10%);
}


.primary-color-blockquote {
  margin-bottom: 1rem;
  font-size: 1em;
  background: $primary-color;
  color: $white;
  padding: 1em 3em;

  @include sm {
    font-size: 1.25em;
    } 
   

}


.primary-color-blockquote-caption {
  font-size: 1.25rem;
  background: #442e8b;
  color: #fff;
  padding: 1em;
}


.blue-highlight-text {
  color: $primary-color;
  font-weight: 600;
}

.pink-highlight-text {
  color: $color-primary-light;
  font-weight: 600;
}

.purple-highlight-text {
  color: $color-burgundy;
  font-weight: 600;
}

.mint-highlight-text {
  color: $color-mint;
  font-weight: 600;
}


.red-highlight-text {
  color: $color-red;
  font-weight: 600;
}


.bulleted-list {
  list-style: disc;
  margin-bottom: 1em;
}


/* select styling */

.selectdiv {
  position: relative;
  /*Don't really need this just for demo styling*/
  
  float: left;
  min-width: 300px;
  //margin: 50px 33%;
}

.selectdiv:after {
    content: '\f078';
    font: normal normal normal 21px/1 FontAwesome;
    color: #fbfcfd;
    right: 50px;
    top: 6px;
    height: 48px;
    padding: 14px 11px 0px 11px;
    border-left: 1px solid $color-grey-dark;
    position: absolute;
    pointer-events: none;
    background: $color-primary-dark;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}


/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
display: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  min-width: 250px;
  height: 50px;
  float: right;
  margin: 5px 0px;
  padding: 0px 24px;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid $color-grey-dark;
  -ms-word-break: normal;
  word-break: normal;
}