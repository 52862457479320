// Animations


/**
 * animate logo home page
 */
 @keyframes zoomIn {
  from {
     transform: scale3d(0.3, 0.3, 0.3);
     opacity: 0;
  }
  50% {
     transform: none;
  }
}
.animation-zoomIn {
  animation-name: zoomIn;
}


//Join our community fade in images
.fade-in-image {
    animation: fadeIn ease 10s;
    -webkit-animation: fadeIn ease 10s;
    -moz-animation: fadeIn ease 10s;
    -o-animation: fadeIn ease 10s;
    -ms-animation: fadeIn ease 10s;
  }

// Rotate an element 360deg
.rotate-me {
	animation: rotating 1s linear infinite;
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}


@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}



  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
}