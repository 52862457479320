// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
 .clearfix::after {
    clear: both;
    content: '';
    display: table;
  }

 
   /**
   * Hide text while making it readable for screen readers
   * 1. Needed in WebKit-based browsers because of an implementation bug;
   *    See: https://code.google.com/p/chromium/issues/detail?id=457146
   */
  .hide-text {
    overflow: hidden;
    padding: 0; /* 1 */
    text-indent: 101%;
    white-space: nowrap;
  }
  
  /**
   * Hide element while making it readable for screen readers
   * Shamelessly borrowed from HTML5Boilerplate:
   * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
   */
  .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }



  #filtersubmit-secondary {
    position: relative;
    z-index: 1;
    left: -35px;
    top: 1px;
    color: #442e8b;
    cursor: pointer;
    width: 0;
    background: transparent;
    padding: inherit;
}


.eq-height {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  flex-direction: column;

}