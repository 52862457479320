
footer {
    position: relative;
    background: #ebebeb;
    padding: 1em 0em;
  footer a {
    color: #212529;
    font-weight: 800;
  }

  ul {
    list-style: none;
  
    li {
      margin-bottom: 5px;
    }
  }

  h3 {
    font-size: 1.75em;
    margin-bottom: 1em;
    font-weight: 700;
  }

  .small, small {
    color: darken($primary-color, 30%);
    font-size: .75rem;
    font-weight: 800;
    font-family: inherit;;
  }
} 
  
  .social-media-footer {
    display: -ms-flexbox;
    /* display: flex; */
    -ms-flex-flow: row;
    /* flex-flow: row; */
    -ms-flex-pack: center;
    /* justify-content: center; 
    border-bottom: 1px solid #717375; */
    margin: 1em 0;
    margin-left: 0px;

    @include sm {
      margin-left: -15px;
   } 

  }
  
  .social-media-footer a {
    //padding-left: 1em;
    color: #717375;
  }
  
  .footer-copyright {
    overflow: hidden;
    background: #002b3b;
    font-size: .9rem;
    color: rgba(255, 255, 255, 0.6);
    position: relative;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    z-index: 3;
  }
  
  .footer-copyright a {
    color: #ffffff;
  }
  
  
  .no-margin {
      margin: 0 !important;
  }
  
  .footer .social-buttons a {
      margin-right: 1rem;
      color: inherit;
  }
  
  .border-bottom-line {
      border-bottom: 1px solid #999898;
  }
  
  .under-footer ul li {
      float: left;
      list-style: none;
      padding: 5px;
  }


  .fa, .fab, .fal, .far, .fas {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      color: $white;
      background: $color-burgundy;
      border-radius: 18px 18px 18px 0;
      padding: 10px 16px;
      min-width: 35px;
}


.fa-facebook-f:hover {
  color: #f9f6f7;
  background: darken($color-burgundy, 15%);
}
 
.fa-twitter:hover {
  color: #f9f6f7;
  background: darken($color-burgundy, 15%);
}

.fa-youtube:hover {
  color: #f9f6f7;
  background: darken($color-burgundy, 15%);
}

.fa-linkedin-in:hover {
  color: #f9f6f7;
  background: darken($color-burgundy, 15%);
}

  /*
  .section a, a:hover {
      color: #11c7e5;
       font-weight: bolder;
      margin-left: 5px;
      text-decoration: none;
      background-color: transparent;
      -webkit-text-decoration-skip: objects;
  }
  */
  .copyright {
      color: #b9b9ba;
      float: right;
  }
  
  @media only screen and (max-width: 767px) {
  .copyright {
      color: #b9b9ba;
      float: left;
     }
  }


  .sub-footer {
    background-color: #ffffff!important;
    color: #000;

    p {
     font-size: 1.1em;   
    }

  }