.insurance-content-block {
    h2 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    } 
    &__block-one {
        width: 80%; 
        padding-bottom: 2em;

        @include sm {
            padding-bottom: 0;
             } 
    }

    &__block-two {
        width: 80%; 
        padding-bottom: 2em;

        @include sm {
           padding-bottom: 0;
            } 

    
    &>div>div {
        margin-left: -30px;
        margin-bottom: -30px;
    }    

    ul {
       list-style: none;
       width: 90%;
       
        li {
        margin-left: 15px;
        }
    }

    .fa-check {
        color: rgba(0, 128, 0, 0.33);
        background: none;
        float: left;
        margin-right: 10px;
    }

    }

}