
.need-help-block{
    background:  #43318b;
    height: 30em;
    margin-top: 2em;
    width: 100%;
    min-height: 325px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
       margin-top: 2em;
       width: 100%;
       min-height: 221px;
       background-repeat: no-repeat;
       background-size: cover;
       display: flex;
       justify-content: center;
       align-items: center;


       @include md {
         background: radial-gradient(65% 387% at 8% 11%, #DC1E8A 0, #DC1E8A 50%, #DC1E8A calc(19% + 1px), #DC1E8A 23%,#43318b calc(50% + 1px),#43318b 39%,#43318b calc(50% + 1px),#43318b 100%);
         height: auto;
        } 



     &__title {
        flex-grow: 3;
        text-align: center;

        h2, p {
         color: $white;   
        }

        h2 {
          font-size: 2.25em;
        }

        p{
            font-size: 1.25em;
            margin-top: 5px;
        }


        @include sm {
        text-align: left;
        } 

     }

     &__contact1 {
        flex-grow: 1;
        text-align: center;
        margin: 1em 0em 0em;

        @include sm {
            text-align: left;
            max-width: 307px;
            margin-right: 30px;
            margin: 0;
            } 

     }

     &__contact2 {
        flex-grow: 1;
        text-align: center;
        margin: 1em 0em 0em;


        @include sm {
            text-align: left;
            max-width: 307px;
            margin-right: 30px;
            margin: 0;
            } 

     }


   }
   

